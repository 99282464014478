<template>
  <div class="py-6">
    <div class="container-fluid mb-6">
      <model-form
        :model="drug"
        i18nScope="admin.drugs.form"
        :validate="services.drug.isValid"
        :on-submit="persist"
        v-on="$listeners"
      >
        <text-input attributeName="name" :max-characters="100" />

        <div class="row">
          <div class="col">
            <text-input attributeName="shortLabel" />
          </div>
          <div class="col">
            <text-input attributeName="mobileLabel" />
          </div>
        </div>

        <hr class="mb-6" />

        <dosages-input attributeName="dosages" />
      </model-form>
    </div>
  </div>
</template>

<script>
import DosagesInput from './dosages-input';

export default {
  name: 'DrugForm',
  components: { DosagesInput },
  props: {
    drug: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
