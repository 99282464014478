<template>
  <form id="chat-id-1-form" @submit.stop.prevent="postText">
    <div class="mb-2 d-flex flex-row align-items-center">
      <!-- Upload button -->
      <div>
        <input
          type="file"
          ref="file"
          accept="image/*"
          @change="postImage"
          class="d-none"
        />
        <button
          class="btn btn-md btn-ico"
          type="button"
          @click="openFileDialog"
        >
          <font-awesome-icon :icon="['fal', 'paperclip']" :class="cssText" />
        </button>
      </div>

      <!-- Emoji Picker -->
      <dropdown
        :icon="['fal', 'smile']"
        :dropup="true"
        :onlyIcon="true"
        :border="false"
        customClass="dropdown--emoji dropdown--emoji--borderless"
        ref="emojiDropdown"
      >
        <VEmojiPicker
          v-if="mountEmojiPicker"
          :labelSearch="$i18n.t(`inbox.room.form.emoji.search`)"
          @select="onSelectEmoji"
        />
      </dropdown>

      <!-- Saved replies -->
      <dropdown
        :icon="['fal', 'rocket']"
        :dropup="true"
        :onlyIcon="true"
        :border="false"
        v-slot="scope"
        v-if="room.kid"
      >
        <SavedReplies
          :kid="room && room.kid"
          :user="room && room.user"
          @edit="onEditSavedReply"
          @sendText="onSendSavedReplyText"
          @sendImage="onSendSavedReplyImage"
          @closeDropdown="scope.closeDropdown"
        />
      </dropdown>

      <!-- Search among saved replies -->
      <dropdown
        :icon="['fal', 'search']"
        :dropup="true"
        :onlyIcon="true"
        :border="false"
        v-slot="scope"
        v-if="room.kid"
      >
        <SavedRepliesSearch
          :kid="room && room.kid"
          :user="room && room.user"
          @edit="onEditSavedReply"
          @sendText="onSendSavedReplyText"
          @sendImage="onSendSavedReplyImage"
          @closeDropdown="scope.closeDropdown"
        />
      </dropdown>

      <div class="ml-auto">
        <!-- Prescription -->
        <button
          class="btn btn-ico btn-link btn-minimal btn-sm"
          type="button"
          @click="$emit('open-prescription-board')"
          v-if="$policy('prescriptionBoard', { room }, 'open')"
        >
          <font-awesome-icon
            :icon="['fal', 'clipboard-prescription']"
            :class="cssText"
          />
        </button>

        <!-- Medical Report -->
        <button
          class="btn btn-ico btn-link btn-minimal btn-sm"
          type="button"
          @click="$emit('open-medical-report')"
          v-if="$policy('medicalReport', { room }, 'open')"
        >
          <font-awesome-icon
            :icon="['fal', 'notes-medical']"
            :class="cssText"
          />
        </button>
      </div>
    </div>
    <div class="input-group">
      <!-- Textarea -->
      <textarea-autosize
        class="form-control bg-light"
        :placeholder="$i18n.t('inbox.room.form.placeholder')"
        v-model="message"
        :min-height="minHeight"
        :max-height="350"
        :important="true"
        rows="1"
        style="overflow-wrap: break-word;"
        @keydown.enter.native.ctrl.exact="postText"
        @focus.native="enlargeTextarea"
        ref="message"
      />

      <!-- Submit button -->
      <div class="input-group-append bg-light d-flex align-items-start">
        <button
          class="btn btn-primary btn-important-border-radius btn-sm my-4 mx-4"
          type="submit"
          :disabled="!isValid"
        >
          <span class="mr-3">{{ $t('inbox.room.form.button.default') }}</span>
          <font-awesome-icon
            :icon="['fal', 'paper-plane']"
            class="text-white"
          />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import VEmojiPicker from 'v-emoji-picker';
import SavedReplies from '@/components/messages/saved-replies';
import SavedRepliesSearch from '@/components/messages/saved-replies/search';
import InsertTextMixin from '@/mixins/insert-text';

export default {
  name: 'ChatForm',
  components: { VEmojiPicker, SavedReplies, SavedRepliesSearch },
  mixins: [InsertTextMixin],
  props: {
    room: { type: Object },
  },
  data() {
    return {
      message: undefined,
      isValid: false,
      minHeight: 56,
      mountEmojiPicker: false,
    };
  },
  mounted() {
    this.typing();
    setTimeout(() => {
      this.mountEmojiPicker = true;
    }, 2000);
  },
  methods: {
    postText() {
      if (this.isValid) {
        this.services.message.postText(
          this.currentAgent,
          this.room,
          this.message
        );
        this.message = undefined;
        this.focus();
      }
    },
    postImageUrl(imageUrl) {
      this.services.message.postImageUrl(
        this.currentAgent,
        this.room,
        imageUrl
      );
      this.focus();
    },
    postImage() {
      const file = this.$refs.file.files[0];
      if (!file) return; // it might happen that no file has been selected
      const fileSize = file.size / (1024 * 1024);
      if (fileSize > 1) alert(this.$t('inbox.room.form.imageTooBig'));
      else {
        this.services.message.postImage(this.currentAgent, this.room, file);
        this.focus();
      }
    },
    openFileDialog() {
      this.$refs['file'].click();
    },
    onSelectEmoji(emoji) {
      this.insertText(
        this.$refs.message.$el,
        emoji.data,
        newMessage => (this.message = newMessage)
      );
      this.$refs.emojiDropdown.closeDropdown();
    },
    onEditSavedReply(text, savedReply) {
      this.completeMedicalReportFromSavedReply(savedReply);
      this.message += text;
      this.focus();
    },
    onSendSavedReplyText(text, savedReply) {
      this.completeMedicalReportFromSavedReply(savedReply);
      this.message = text;
      this.$nextTick(this.postText);
    },
    onSendSavedReplyImage(imageUrl, savedReply) {
      this.completeMedicalReportFromSavedReply(savedReply);
      this.$nextTick(() => this.postImageUrl(imageUrl));
    },
    enlargeTextarea() {
      this.minHeight = 190;
    },
    typing() {
      this.$refs.message.$on('input', value => {
        if (value !== '' && !this.room.isAgentTyping) {
          this.services.room.agentStartsTyping(this.room.id);
        } else if (value === '' && this.room.isAgentTyping) {
          this.services.room.agentEndsTyping(this.room.id);
        }
      });
    },
    completeMedicalReportFromSavedReply(savedReply) {
      this.services.medicalReport.addLibraryItemsFromSavedReply(
        this.room,
        savedReply,
        this.currentAgent,
        this.currentAgentGroupIds
      );
    },
    focus() {
      // set the focus to the textarea
      this.$refs.message.focus();
    },
  },
  watch: {
    room: {
      immediate: true,
      handler(newRoom, oldRoom) {
        // NOTE: this handler is only required when loading a new room. We don't need it when the isAgentTyping has been modified.
        if (oldRoom === undefined || newRoom.id !== oldRoom?.id) {
          var draftMessage = this.services.message.loadTextFromDraft(newRoom);
          if (draftMessage?.length > 0) {
            this.message = draftMessage;
            this.enlargeTextarea();
          }
        }
      },
    },
    message: {
      immediate: true,
      handler(newMessage) {
        this.isValid = newMessage && newMessage.trim().length > 0;

        // save the new message as draft
        this.services.message.saveTextAsDraft(this.room, newMessage);
      },
    },
  },
};
</script>
