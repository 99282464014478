<template>
  <dropdown :toggle="true" size="md" ref="dropdown">
    <template v-slot:button>
      <span class="d-flex align-items-center">
        <span class="d-flex align-items-center" :class="cssText">
          <font-awesome-icon :icon="currentStatus.icon" class="mr-3" />
          <b>{{ currentStatus.label }}</b>
          <span v-if="counter" class="ml-4" :class="cssText50">
            <b>{{ counter }}</b>
          </span>
        </span>
        <font-awesome-icon :icon="['fas', 'caret-down']" class="ml-5" />
      </span>
    </template>
    <router-link
      v-for="status in statuses"
      :key="status.id"
      :to="routeSettings(status.id)"
      class="dropdown-item d-flex align-items-center"
      :class="{ 'text-primary': isActive(status) }"
      v-on:click.native="closeDropdown"
    >
      <span class="d-flex align-items-center">
        <font-awesome-icon :icon="status.icon" class="mr-3" />
        {{ status.label }}
      </span>
      <span class="ml-auto pl-4" v-if="isActive(status)">
        <font-awesome-icon icon="check" />
      </span>
    </router-link>
  </dropdown>
</template>

<script>
export default {
  name: 'SnoozedStatusPicker',
  props: ['currentStatusId', 'counter'],
  computed: {
    currentStatus() {
      return this.statuses.find(status => status.id === this.currentStatusId);
    },
    statuses() {
      return [
        {
          id: 'awake',
          label: this.$i18n.t('inbox.snoozedStatuses.awake'),
          icon: 'inbox',
        },
        {
          id: 'snoozing',
          label: this.$i18n.t('inbox.snoozedStatuses.snoozing'),
          icon: ['far', 'clock'],
        },
      ];
    },
  },
  methods: {
    isActive(status) {
      return status.id === this.currentStatusId;
    },
    routeSettings(statusId) {
      return {
        name: 'inbox',
        params: { statusId },
      };
    },
    closeDropdown() {
      this.$refs.dropdown?.closeDropdown();
    },
  },
};
</script>
