import { firestoreAction } from 'vuexfire';
import services from '@/services';
import i18n from '@/plugins/i18n';
import { findAppConfig, findDynAppConfig, serialize } from '@/shared/db';
import * as types from './mutation-types';

export default {
  localSignInAction: firestoreAction(async ({ commit, dispatch }) => {
    const data = await services.authentication.localSignIn();
    if (data?.jwt) {
      commit(types.SIGN_IN, data);
      dispatch('bindAgentAction');
      dispatch('bindAgentGroupsAction');
    } else {
      commit(types.SIGN_OUT);
    }
  }),
  displayMessageAction({ commit }, message) {
    commit(types.DISPLAY_MESSAGE, message);
  },
  setSystemErrorAction({ commit }) {
    commit(types.SET_SYSTEM_ERROR);
  },
  signInAction: firestoreAction(async ({ commit, dispatch }, credentials) => {
    const data = await services.authentication.signIn(credentials);
    if (data?.jwt) {
      await services.agentTracking.track('SIGN_IN');
      commit(types.SIGN_IN, data);
      dispatch('bindAgentAction');
      dispatch('bindAgentGroupsAction');
    } else {
      commit(types.DISPLAY_ERROR, i18n.t('alerts.wrongCredentials'));
      commit(types.SIGN_OUT);
    }
  }),
  signOutAction: firestoreAction(async ({ unbindFirestoreRef, commit }) => {
    await services.agentTracking.track('SIGN_OUT');
    await services.authentication.signOut();
    commit(types.SIGN_OUT);
    commit(types.DISPLAY_WARNING, i18n.t('alerts.signedOut'));
    unbindFirestoreRef('agent');
    unbindFirestoreRef('groups');
  }),
  toggleSoundNotificationsAction({ commit }, enabled) {
    localStorage.setItem('soundNotifications', enabled);
    commit(types.TOGGLE_SOUND_NOTIFICATIONS, enabled);
  },
  bindAgentAction: firestoreAction(({ bindFirestoreRef, state }) => {
    // bind the authenticated agent, thanks to this,
    // changing the profile pic by an admin will update automatically the agent UI
    return bindFirestoreRef('agent', services.agent.find(state.agent.id), {
      serialize,
    });
  }),
  bindAgentGroupsAction: firestoreAction(({ bindFirestoreRef }) => {
    // Bind the agent groups
    // If an agent has joined or has been removed from a group, the app should be warned
    // because some links / actions depend on the current agent groups
    return bindFirestoreRef('groups', services.agentGroup.findAll(), {
      serialize,
    });
  }),
  bindAppConfigAction: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('config', findAppConfig());
  }),
  bindAppDynConfigAction: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef('dynConfig', findDynAppConfig());
  }),
  // Molecules
  async listMolecules({ commit }) {
    const list = await services.molecule.findAll(true);
    commit(types.SET_MOLECULES, list);
  },
  createMolecule({ dispatch }, molecule) {
    return services.molecule.create(molecule).then(molecule => {
      dispatch('listMolecules');
      return molecule;
    });
  },
  updateMolecule({ dispatch }, molecule) {
    return services.molecule.update(molecule).then(() => {
      dispatch('listMolecules');
    });
  },
  // BrandedDrugs
  async listBrandedDrugs({ commit }) {
    let list = await services.brandedDrug.findAll(undefined, true);
    list = list.map(brandedDrug => services.brandedDrug.sortDrugs(brandedDrug));
    commit(types.SET_BRANDED_DRUGS, list);
  },
  createBrandedDrug({ dispatch }, brandedDrug) {
    return services.brandedDrug.create(brandedDrug).then(brandedDrug => {
      dispatch('listBrandedDrugs');
      return brandedDrug;
    });
  },
  updateBrandedDrug({ dispatch }, brandedDrug) {
    return services.brandedDrug.update(brandedDrug).then(() => {
      dispatch('listBrandedDrugs');
    });
  },
  // Drugs
  createDrug({ dispatch }, { brandedDrugId, drug }) {
    return services.drug.create(brandedDrugId, drug).then(drug => {
      dispatch('listBrandedDrugs');
      return drug;
    });
  },
  updateDrug({ dispatch }, { brandedDrugId, drug }) {
    return services.drug.update(brandedDrugId, drug).then(drug => {
      dispatch('listBrandedDrugs');
      return drug;
    });
  },
};
