<template>
  <step-layout
    :title="$t(`${i18nScope}.title`)"
    :kid="kid"
    :prescriptionItem="prescriptionItem"
    v-on="$listeners"
  >
    <template v-slot:switch>
      <custom-switch v-model="isCustom" class="mt-1 ml-8" />
    </template>

    <transition name="chat-footer" mode="out-in">
      <div key="custom" class="pt-8 px-3" v-if="isCustom">
        <custom-duration-form v-on="$listeners" />
      </div>

      <div key="list" class="pt-6 px-3" v-else>
        <step-list
          :title="$t(`${i18nScope}.duration`)"
          :list="durations"
          :showChevron="false"
          :isCurrent="item => item.value === prescriptionItem.duration"
          @select="item => $emit('change', { duration: item.value })"
          v-slot="{ item: { label } }"
        >
          {{ label }}
        </step-list>

        <step-list
          :title="$t(`${i18nScope}.packaging`)"
          :list="packagingItems"
          :showChevron="false"
          :isCurrent="item => item.value === prescriptionItem.duration"
          @select="item => $emit('change', { duration: item.value })"
          class="mt-6"
          v-slot="{ item: { label } }"
        >
          {{ label }}
        </step-list>

        <div class="mt-3 text-right">
          <button
            class="btn btn-primary"
            @click="$emit('accept')"
            :disabled="!isValid"
          >
            {{ $t(`${i18nScope}.custom.submit`) }}
          </button>
        </div>
      </div>
    </transition>
  </step-layout>
</template>

<script>
import StepLayout from './step-layout';
import StepList from './list';
import CustomDurationForm from './custom-duration-form';

export default {
  name: 'DosagePickerStep3',
  components: { StepLayout, StepList, CustomDurationForm },
  props: {
    kid: { type: Object, required: true, default: null },
    prescriptionItem: { type: Object, required: true },
  },
  data() {
    return {
      isCustom: false,
      durations: [],
      packagingItems: [],
      customDuration: '',
    };
  },
  mounted() {
    const service = this.services.prescription;
    const br = [null];
    const { days, weeks, months } = service.getDurations(this.userLocale);
    this.durations = [].concat(days, br, weeks, br, months);
    const { shots, boxes, vials, bottles, tubes } = service.getPackaging(
      this.userLocale
    );
    const bulkItems1 = [].concat(shots, br, boxes, br, vials, br);
    const bulkItems2 = [].concat(bottles, br, tubes);
    this.packagingItems = [].concat(bulkItems1, bulkItems2); // linters are dumb
  },
  computed: {
    isValid() {
      return this.prescriptionItem.duration;
    },
    i18nScope() {
      return 'inbox.room.prescriptionBoard.dosagePicker.step3';
    },
  },
};
</script>
