<template>
  <draggable
    tag="div"
    class="list-group list-group-flush"
    :list="list"
    handle=".handle"
    @change="onChange"
  >
    <div
      v-for="(agentGroup, index) in list"
      :key="agentGroup.id"
      class="list-group-item cursor-pointer"
      :class="{
        'border-top': index === 0,
        'border-left-0 border-right-0 primary-border-right':
          agentGroup.id === agentGroupId,
      }"
    >
      <group :agentGroup="agentGroup" />
    </div>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Group from './group';

export default {
  name: 'AgentGroupList',
  components: { Draggable, Group },
  props: {
    list: { type: Array, default: null },
    agentGroupId: { type: String, default: null },
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
  },
};
</script>
