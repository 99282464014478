<template>
  <div>
    <div class="message-content bg-light mx-0" style="max-width: 100%">
      <div
        class="overflow-auto"
        style="max-height: 10rem"
        v-html="formattedText"
        v-if="!item.isImage"
      />

      <img
        :src="item.image"
        v-if="item.isImage"
        class="img-fluid"
        style="max-height: 10rem"
      />
    </div>

    <div class="mt-6 text-right">
      <button
        class="btn btn-sm btn-primary"
        v-on:click.stop.prevent="$emit('edit')"
        v-if="!item.isImage"
      >
        {{ $t('inbox.room.savedReplies.buttons.edit') }}
      </button>
      <button
        class="btn btn-sm btn-primary ml-6"
        v-on:click.stop.prevent="$emit('send')"
      >
        <span class="mr-3">{{
          $t('inbox.room.savedReplies.buttons.send')
        }}</span>
        <font-awesome-icon :icon="['fal', 'paper-plane']" class="text-white" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SavedReplyListItem',
  props: {
    item: { type: Object },
  },
  computed: {
    formattedText() {
      return this.formatMobileMessageToHtml(this.item.content);
    },
  },
};
</script>
