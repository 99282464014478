<template>
  <div>
    <div class="sidebar-header">
      <div class="container">
        <div class="row align-items-center">
          <kid-info-title
            :kid="kid"
            :withLastNameFirstLetter="true"
            v-if="kid"
          />
          <div class="ml-auto">
            <a
              class="btn btn-sm btn-outline-secondary h-100"
              :class="cssText"
              @click="$emit('close-sidebar')"
            >
              <font-awesome-icon :icon="['far', 'angle-double-right']" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { kidAgeFromBirthday } from '@/shared/utils';
import KidInfoTitle from '@/components/rooms/shared/kid-info-title';

export default {
  name: 'RoomSidebarHeader',
  components: { KidInfoTitle },
  props: {
    room: { type: Object },
  },
  data() {
    return { originalKid: null };
  },
  computed: {
    kid() {
      // NOTE: try to rely on the last version of the kid document
      return { ...this.originalKid, ...this.room.kid };
    },
    kidAge() {
      return kidAgeFromBirthday(this.kid.birthDate, this.kid.gender);
    },
  },
  watch: {
    room: {
      immediate: true,
      async handler() {
        this.originalKid = await this.services.kid.loadByExternalId(
          this.room.user.id,
          this.room.kid.id
        );
      },
    },
  },
};
</script>
