<template>
  <draggable
    tag="div"
    class="list-group"
    :list="list"
    handle=".handle"
    @change="onChange"
  >
    <div v-for="savedReply in list" :key="savedReply.id">
      <div
        class="list-group-item cursor-pointer"
        :class="{
          'border-left-0 border-right-0 primary-border-right':
            savedReply.id === savedReplyId,
        }"
        :style="{ paddingLeft: `${(savedReply.depth + 1) * 20}px` }"
      >
        <item :savedReply="savedReply" />
      </div>

      <saved-reply-tree
        :list="savedReply.children"
        :savedReplyId="savedReplyId"
        v-if="!savedReply.leaf"
        @change="onChange"
      />
    </div>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Item from './item';

export default {
  name: 'SavedReplyTree',
  components: { Draggable, Item },
  props: {
    value: { type: Array, default: null },
    list: { type: Array, default: null },
    savedReplyId: { type: String, default: null },
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
  },
};
</script>
