import firebase from 'firebase/app';
import { getDB } from '@/shared/db';
import { track as trackAgent } from './agent-tracking';

export const findAll = userId => {
  return getDB()
    .collection('users')
    .doc(userId)
    .collection('kids');
};

export const find = (userId, id) => {
  return findAll(userId).doc(id);
};

export const findByExternalId = (userId, externalId) => {
  return findAll(userId)
    .where('externalId', '==', externalId)
    .get();
};

export const loadByExternalId = (userId, externalId) => {
  return findByExternalId(userId, externalId)
    .then(kidSnapshot => {
      var docs = [];
      kidSnapshot.forEach(doc => docs.push(doc.data()));
      return docs;
    })
    .then(kids => kids[0]);
};

export const create = (userId, kid) => {
  return findAll(userId).add(kid);
};

export const findKidIdByExternalIdOrCreate = async (userId, kid) => {
  const kidSnapshot = await findByExternalId(userId, kid.externalId);

  if (!kidSnapshot.empty) {
    return kidSnapshot.docs[0].id;
  } else {
    const docRef = await create(userId, kid);
    return docRef.id;
  }
};

export const addAntecedent = (userId, kidId, antecedent) => {
  trackAgent('ADD', 'ANTECEDENT', userId);
  return find(userId, kidId).update({
    antecedents: firebase.firestore.FieldValue.arrayUnion(antecedent),
  });
};

export const removeAntecedent = (userId, kidId, antecedent) => {
  trackAgent('REMOVE', 'ANTECEDENT', userId);
  return find(userId, kidId).update({
    antecedents: firebase.firestore.FieldValue.arrayRemove(antecedent),
  });
};
