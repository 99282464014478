<template>
  <div class="d-flex align-self-center h-100">
    <agent-picker
      :groupName="groupNames.CONVERSATIONS_ASSIGNEES"
      :agent="agent"
      :allowUnselecting="true"
      :hintLabel="$t('inbox.agents.hint')"
      :unselectedLabel="$t('inbox.agents.unassigned')"
      @select-agent="assignAgent"
    />
  </div>
</template>

<script>
export default {
  name: 'RoomAgents',
  props: {
    room: {
      type: Object,
      default: null,
    },
  },
  computed: {
    agent() {
      return this.room.agent;
    },
  },
  methods: {
    assignAgent(agent) {
      this.services.room.assignAgent(this.room, agent, this.currentAgent);
    },
  },
};
</script>
