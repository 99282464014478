import { RD_LETTER_ADVANCED_MODE_AGENTS } from '@/services/agent-group';

export default {
  show() {
    return this.isBetaTester();
  },
  editInAdvancedMode() {
    return this.isMemberOf(RD_LETTER_ADVANCED_MODE_AGENTS);
  },
};
