import { SYSTEM_ADMINISTRATORS } from '@/services/agent-group';

export default {
  list() {
    return this.isAdmin();
  },
  create() {
    return this.isAdmin();
  },
  show(agent) {
    return (
      this.isAdmin() ||
      (!this.isAdmin() && !this.isSimpleMemberOf(SYSTEM_ADMINISTRATORS, agent))
    );
  },
  edit(agent) {
    return agent && this.isAdmin();
  },
  showWithoutCurrentAgent(agent) {
    return agent.id !== this.agentId();
  },
};
