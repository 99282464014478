<template>
  <div>
    <div :class="{ 'd-none': !hasPrescriptions }">
      <h5 class="h6 text-gray-700 font-weight-normal mb-4">
        {{ title }}
      </h5>
      <list-input-item
        v-for="(item, index) in value"
        :item="item"
        :index="index"
        :key="`prescription-item-${index}`"
        @toggle-replaceable="toggleReplaceable"
        @destroy="destroy"
      />
    </div>
    <dosage-picker :kid="kid" @add="add" />
  </div>
</template>

<script>
import ListInputItem from './list-input-item';
import DosagePicker from '../dosage-picker';

export default {
  name: 'PrescriptionBoardList',
  components: { ListInputItem, DosagePicker },
  props: {
    title: { type: String, default: 'My List' },
    kid: { type: Object, required: true },
    value: { type: Array, default: () => [] },
  },
  computed: {
    hasPrescriptions() {
      return this.value.length > 0;
    },
  },
  methods: {
    add(prescription) {
      const newList = [].concat(this.value, [
        { ...prescription, replaceable: true },
      ]);
      this.$emit('input', newList);
    },
    toggleReplaceable(index) {
      const newPrescription = { ...this.value[index] };
      newPrescription.replaceable = !newPrescription.replaceable;
      var newList = [].concat(this.value);
      newList[index] = newPrescription;
      this.$emit('input', newList);
    },
    destroy(index) {
      const newList = [].concat(this.value);
      newList.splice(index, 1);
      this.$emit('input', newList);
    },
  },
};
</script>
