<template>
  <router-link
    :to="{ name: 'agent', params: { agentId: agent.id } }"
    tag="div"
    class="media align-items-center"
  >
    <avatar :user="agent" :online="isOnline" color="secondary" size="md" />
    <div class="media-body ml-5">
      <h6
        class="mb-0"
        :class="{
          'text-primary': active,
          'text-dark': !active,
        }"
      >
        {{ agent | agentFullName }}
        <span class="badge badge-warning ml-2" v-if="agent.isDisabled">
          {{ $t('agents.isDisabledTag') }}
        </span>
      </h6>
      <small class="text-muted">
        {{ agent | agentTitle }}
      </small>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'AgentListItem',
  props: {
    agent: {
      type: Object,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOnline() {
      return this.agent.online ? true : undefined;
    },
  },
};
</script>

<style scoped>
.media {
  cursor: pointer;
}
</style>
