import { vuexfireMutations } from 'vuexfire';
import * as types from './mutation-types';

export default {
  [types.SIGN_IN](state, { jwt, agent, firebaseJwt }) {
    state.appLoading = false;
    state.authenticated = true;
    state.agent = { ...agent, id: agent.id };
    state.jwt = jwt;
    state.firebaseJwt = firebaseJwt;
    state.message = undefined;
  },
  [types.SIGN_OUT](state) {
    state.authenticated = false;
    state.jwt = undefined;
    state.firebaseJwt = undefined;
  },
  [types.DISPLAY_MESSAGE](state, message) {
    state.message = message;
  },
  [types.DISPLAY_ERROR](state, text) {
    state.message = { type: 'danger', text };
  },
  [types.DISPLAY_WARNING](state, text) {
    state.message = { type: 'warning', text };
  },
  [types.SET_SYSTEM_ERROR](state) {
    state.systemError = true;
  },
  [types.TOGGLE_SOUND_NOTIFICATIONS](state, enabled) {
    state.soundNotifications =
      enabled === undefined ? !state.soundNotifications : enabled;
  },
  [types.SET_MOLECULES](state, list) {
    state.molecules = [].concat(list);
  },
  [types.SET_BRANDED_DRUGS](state, list) {
    state.brandedDrugs = [].concat(list);
  },
  ...vuexfireMutations,
};
