<template>
  <tabs navClass="rounded-0">
    <tab :name="$t('admin.savedReplies.form.languages.fr')" :selected="true">
      <div class="form-group mt-5">
        <label for="saved-reply-fr-title">{{
          $t('admin.savedReplies.form.title')
        }}</label>
        <input
          type="text"
          class="form-control"
          id="saved-reply-fr-title"
          v-model="frTitleInput"
        />
      </div>

      <div v-if="savedReply.leaf">
        <div v-if="savedReply.isImage">
          <div class="form-group">
            <label for="saved-reply-image-fr">{{
              $t('admin.savedReplies.form.image')
            }}</label>
            <input
              type="text"
              class="form-control"
              id="saved-reply-image-fr"
              v-model="frImageInput"
            />
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label for="saved-reply-content-fr">{{
              $t('admin.savedReplies.form.content')
            }}</label>
            <rich-text-input v-model="frContentInput" language="fr" />
          </div>
        </div>
      </div>
    </tab>
    <tab :name="$t('admin.savedReplies.form.languages.en')">
      <div class="form-group mt-5">
        <label for="saved-reply-en-title">{{
          $t('admin.savedReplies.form.title')
        }}</label>
        <input
          type="text"
          class="form-control"
          id="saved-reply-en-title"
          v-model="enTitleInput"
        />
      </div>

      <div v-if="savedReply.leaf">
        <div v-if="savedReply.isImage">
          <div class="form-group">
            <label for="saved-reply-image-en">{{
              $t('admin.savedReplies.form.image')
            }}</label>
            <input
              type="text"
              class="form-control"
              id="saved-reply-image-en"
              v-model="enImageInput"
            />
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label for="saved-reply-content-en">{{
              $t('admin.savedReplies.form.content')
            }}</label>
            <rich-text-input v-model="enContentInput" language="en" />
          </div>
        </div>
      </div>
    </tab>
  </tabs>
</template>

<script>
export default {
  name: 'SavedReplyContentForm',
  props: {
    savedReply: { type: Object },
  },
  computed: {
    frTitleInput: {
      get() {
        return this.savedReply.title.fr;
      },
      set(value) {
        this.$emit('change', { title: { fr: value } });
      },
    },
    enTitleInput: {
      get() {
        return this.savedReply.title.en;
      },
      set(value) {
        this.$emit('change', { title: { en: value } });
      },
    },
    frContentInput: {
      get() {
        return this.savedReply.content.fr;
      },
      set(value) {
        this.$emit('change', { content: { fr: value } });
      },
    },
    enContentInput: {
      get() {
        return this.savedReply.content.en;
      },
      set(value) {
        this.$emit('change', { content: { en: value } });
      },
    },
    isImageInput: {
      get() {
        return this.savedReply.isImage;
      },
      set(value) {
        this.$emit('change', { isImage: value });
      },
    },
    frImageInput: {
      get() {
        return this.savedReply.image.fr;
      },
      set(value) {
        this.$emit('change', { image: { fr: value } });
      },
    },
    enImageInput: {
      get() {
        return this.savedReply.image.en;
      },
      set(value) {
        this.$emit('change', { image: { en: value } });
      },
    },
  },
};
</script>
