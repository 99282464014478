<template>
  <div class="chat-status pb-1">
    <div class="container-fluid" :class="cssText">
      <div class="row">
        <div class="col text-left">
          <p class="text-tertiary">
            <small
              v-html="
                $t('inbox.room.userIsTyping', { firstName: userFirstName })
              "
              v-if="room && isUserTyping"
            />
            <span class="invisible">-</span>
          </p>
        </div>
        <div class="col text-right">
          <font-awesome-icon
            icon="check"
            :style="{ color: '#62BE4F' }"
            v-if="room && room.hasUserRead"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomTypingStatus',
  props: {
    room: {
      type: Object,
      default: null,
    },
    idleTime: {
      type: Number,
      default: 20000,
    },
  },
  data() {
    return {
      agentTimer: 0,
    };
  },
  mounted() {
    // Detect tab changes
    window.addEventListener('focus', this.startTyping);
    window.addEventListener('blur', this.endTyping);
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.startTyping);
    window.removeEventListener('blur', this.endTyping);
  },
  /**
   * Do something before entirely leaving this route (change of pages, not rooms).
   * This hook does not handle room change (it is `$route` watcher's job).
   */
  beforeRouteLeave(to, from, next) {
    this.endTyping(from.params.id);
    next();
  },
  computed: {
    userFirstName() {
      return this.room.user.firstName;
    },
    isUserTyping() {
      return this.room.isUserTyping;
    },
  },
  methods: {
    startTyping() {
      this.services.room.agentStartsTyping(this.$route.params.id);
    },
    endTyping() {
      this.endTypingForRoom(this.room?.id);
    },
    endTypingForRoom(roomId) {
      if (!roomId) return;
      clearInterval(this.agentTimer);
      this.services.room.agentEndsTyping(roomId);
    },
  },
  watch: {
    // Detect when the agent goes idle
    'room.isAgentTyping'(isAgentTyping) {
      const roomId = this.$route.params.id;

      if (!roomId) return;

      clearInterval(this.agentTimer);

      if (isAgentTyping) {
        this.agentTimer = setInterval(() => {
          this.services.room.agentEndsTyping(roomId);
        }, this.idleTime);
      }
    },
    /**
     * Detect room change. For some reason `$route` watcher is called only when a room changes,
     * and not the entire page.
     * That's why we also use `beforeRouteLeave` which covers this gap.
     */
    $route(to, from) {
      this.endTypingForRoom(from.params.id);
    },
  },
};
</script>
