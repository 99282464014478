<template>
  <div class="d-flex align-items-center mb-4">
    <div
      class="border rounded border-color-gray-500 font-weight-bold text-dark px-4 py-3"
    >
      {{ brandedDrug.molecules[0].name }} ({{ brandedDrug.name }})
    </div>
    <div
      class="border rounded border-color-gray-500 text-dark px-4 py-3 ml-4"
      v-if="drug"
    >
      {{ drug.shortLabel }}
    </div>
    <div
      class="border rounded border-color-gray-500 text-dark px-4 py-3 ml-4 flex-fill"
      v-if="drug && dosage"
    >
      {{ dosage.shortLabel }} {{ duration }}
    </div>
    <div
      class="border rounded border-color-gray-500 text-dark ml-4 flex-fill"
      v-if="!drug && dosage"
    >
      {{ dosage.shortLabel }} {{ duration }}
    </div>
    <div class="ml-4">
      <button
        class="btn btn-md text-light border-color-gray-500 cursor-default"
        :class="{ 'text-dark': replaceable }"
        v-tooltip="replaceableTooltip"
      >
        <font-awesome-icon :icon="['fal', 'clone']" flip="vertical" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrescriptionBoardListItem',
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    brandedDrug() {
      return this.item.brandedDrug;
    },
    drug() {
      // special case: the agent entered an empty custom drug
      if (this.item.drug || !this.brandedDrug.drugs)
        return { shortLabel: this.item.drug };
      else return this.brandedDrug.drugs[0];
    },
    dosage() {
      if (this.item.dosage) return { shortLabel: this.item.dosage };
      else return this.drug.dosages[0];
    },
    duration() {
      return this.item.duration;
    },
    replaceable() {
      return this.item.replaceable;
    },
    replaceableTooltip() {
      return this.$t(
        `${this.i18nScope}.replaceable.${this.replaceable ? 'on' : 'off'}`
      );
    },
    i18nScope() {
      return 'inbox.room.prescriptionBoard.show.listItem';
    },
  },
};
</script>
