<template>
  <library-item-input
    topicName="antecedents"
    :kid="kid"
    :list="list"
    editable
    @add="add"
    @remove="remove"
  >
    <template v-slot:title>
      <h5
        class="font-weight-bold mb-5 d-flex align-items-center"
        :class="cssText"
      >
        <font-awesome-icon icon="star-of-life" />
        <span class="ml-3">{{
          $tc('inbox.room.medicalReport.antecedents.title', list.length)
        }}</span>
      </h5>
    </template>
  </library-item-input>
</template>

<script>
import LibraryItemInput from '@/components/medical-report/library-item-input';

export default {
  name: 'RoomSidebarAntecedents',
  components: { LibraryItemInput },
  props: {
    room: { type: Object },
  },
  data() {
    return {
      kid: null,
      kidId: null,
    };
  },
  computed: {
    list() {
      return (this.kid?.antecedents || []).sort((a, b) =>
        this.antecedentTitle(a).localeCompare(this.antecedentTitle(b))
      );
    },
  },
  methods: {
    antecedentTitle(antecedent) {
      return antecedent.title[this.uiLocale];
    },
    add(topicName, antecedent) {
      this.services.kid.addAntecedent(
        this.room.user.id,
        this.kid.id,
        antecedent
      );
    },
    remove(topicName, antecedent) {
      this.services.kid.removeAntecedent(
        this.room.user.id,
        this.kid.id,
        antecedent
      );
    },
  },
  watch: {
    room: {
      immediate: true,
      async handler(room) {
        const { id, ...attributes } = room.kid;
        this.kidId = await this.services.kid.findKidIdByExternalIdOrCreate(
          room.user.id,
          {
            externalId: id,
            ...attributes,
          }
        );
      },
    },
    kidId() {
      if (!this.kidId) return;
      this.$bind('kid', this.services.kid.find(this.room.user.id, this.kidId));
    },
  },
};
</script>
