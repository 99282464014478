<template>
  <div class="row">
    <div class="col">
      <div class="form-group mb-1">
        <div class="d-flex justify-content-between">
          <label for="saved-reply-is-category">{{
            $t('admin.savedReplies.form.isCategory')
          }}</label>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="saved-reply-is-category"
              v-model="isCategoryInput"
            />
            <label
              class="custom-control-label"
              for="saved-reply-is-category"
            ></label>
          </div>
        </div>
      </div>
      <div class="form-group mb-1" v-if="savedReply.leaf">
        <div class="d-flex justify-content-between">
          <label for="saved-reply-is-image">{{
            $t('admin.savedReplies.form.isImage')
          }}</label>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="saved-reply-is-image"
              v-model="isImageInput"
            />
            <label
              class="custom-control-label"
              for="saved-reply-is-image"
            ></label>
          </div>
        </div>
      </div>
      <div class="form-group mb-1">
        <div class="d-flex justify-content-between">
          <label for="saved-reply-published">{{
            $t('admin.savedReplies.form.published')
          }}</label>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="saved-reply-published"
              v-model="publishedInput"
            />
            <label
              class="custom-control-label"
              for="saved-reply-published"
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="saved-reply-parent">{{
          $t('admin.savedReplies.form.parent')
        }}</label>
        <select
          class="form-control"
          id="saved-reply-parent"
          v-model="parentInput"
        >
          <option value="">{{ $t('admin.savedReplies.form.noParent') }}</option>
          <option
            v-for="parent in parentsTree"
            :key="parent.id"
            :value="parent.id"
          >
            {{ parentOptionName(parent) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SavedReplyPropertiesForm',
  props: {
    savedReply: { type: Object },
  },
  data() {
    return {
      parents: [],
      parentsTree: [],
    };
  },
  mounted() {
    this.$bind('parents', this.services.savedReply.findAllRoots());
  },
  computed: {
    isCategoryInput: {
      get() {
        return !this.savedReply.leaf;
      },
      set(value) {
        this.$emit('change', { leaf: !value });
      },
    },
    publishedInput: {
      get() {
        return this.savedReply.published;
      },
      set(value) {
        this.$emit('change', { published: value });
      },
    },
    parentInput: {
      get() {
        return this.savedReply.parentId;
      },
      set(value) {
        this.$emit('change', { parentId: value });
      },
    },
    isImageInput: {
      get() {
        return this.savedReply.isImage;
      },
      set(value) {
        this.$emit('change', { isImage: value });
      },
    },
  },
  methods: {
    parentOptionName(parent) {
      var prefix = '';
      for (var i = 0; i < parent.depth; i++) {
        prefix = `--${prefix}`;
      }
      return `${prefix} ${parent.title.fr}`;
    },
  },
  watch: {
    parents() {
      this.parentsTree = this.services.savedReply.buildFlattenTree(
        this.parents,
        this.savedReply.id
      );
    },
  },
};
</script>
