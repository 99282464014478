import { SYSTEM_ADMINISTRATORS } from '@/services/agent-group';
import { isBlank, isBeforeDate } from '@/shared/utils';

export default {
  // Actions
  newConversation() {
    return this.isAdmin();
  },
  show(room) {
    return (
      !this.isDeleted(room) &&
      !(this.isAgent() && this.isAdminConversation(room))
    );
  },
  typeMessage(room) {
    return this.isNotClosed(room) && this.isFullyAssignedToAgent(room);
  },
  postAsBiloba(room) {
    return !!room;
  },
  acceptOrRefuse(room) {
    return (
      this.isNotClosed(room) &&
      this.isAssignedToAgent(room) &&
      room.agent?.assignmentAccepted !== true
    );
  },
  cancelPendingAssignment(room) {
    return (
      this.isWaitingForAssignment(room) &&
      (this.isAdmin() || this.isAssignedByUser(room))
    );
  },
  assignToAgent(room) {
    return this.isNotClosed(room) && this.isNotAssigned(room);
  },
  assignToAnyone(room) {
    return (
      this.isNotClosed(room) &&
      (this.isNotAssigned(room) ||
        this.isAdmin() ||
        this.isAssignedToAgent(room))
    );
  },
  reOpen(room) {
    return this.isClosed(room);
  },
  close(room) {
    return (
      this.isNotClosed(room) && (this.isAdmin() || this.isAssignedToAgent(room))
    );
  },
  snooze(room) {
    return this.isOpen(room);
  },
  reSnooze(room) {
    return (
      this.isOpen(room) &&
      !isBlank(room.status.snoozedUntil) &&
      !isBeforeDate(room.status.snoozedUntil)
    );
  },
  toggleMarkAsFavorite() {
    return true;
  },
  destroy() {
    return this.isAdmin();
  },
  // Helpers
  isFullyAssignedToAgent(room) {
    return (
      this.isAssignedToAgent(room) && room.agent?.assignmentAccepted === true
    );
  },
  isWaitingForAssignment(room) {
    return (
      this.isOpen(room) &&
      !!room.agent &&
      room.agent.assignmentAccepted !== true
    );
  },
  isAssignedToAgent(room) {
    return room.agent?.id === this.user.id;
  },
  isAssignedByUser(room) {
    return room.assignedBy?.id === this.user.id;
  },
  isNotAssigned(room) {
    return room.agent === null || room.agent === undefined;
  },
  isClosed(room) {
    return room.status.type === 'closed';
  },
  isNotClosed(room) {
    return room.status.type !== 'closed';
  },
  isOpen(room) {
    return room.status.type === 'open';
  },
  isNotOpen(room) {
    return room.status.type !== 'open';
  },
  isAdminConversation(room) {
    return (
      !room.assignedBy &&
      this.isSimpleMemberOf(SYSTEM_ADMINISTRATORS, room.agent)
    );
  },
  isDeleted(room) {
    return room.isDeleted === true;
  },
};
