<template>
  <div class="sidebar-header chat-header border-bottom">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <div class="d-flex align-items-center" v-if="user && kid">
            <h4 class="mb-0">
              <router-link
                :to="{ name: 'user', params: { userId: prescription.userId } }"
              >
                <u :class="cssText">{{ user.firstName }}</u>
              </router-link>
            </h4>
            <font-awesome-icon
              icon="chevron-right"
              class="mx-8"
              :class="cssText"
            />
            <kid-info-title
              :kid="kid"
              :relativeDate="prescription.createdAt"
              :forceInThePast="true"
            />
          </div>
          <ul class="nav ml-auto">
            <li class="nav-item list-inline-item" v-if="prescription">
              <router-link
                :to="{
                  name: 'shortcutRoom',
                  params: { id: prescription.roomId },
                }"
                class="btn btn-outline-secondary btn-md text-dark"
              >
                {{ $t('prescriptions.show.openRoom') }}
              </router-link>
            </li>
            <li class="nav-item list-inline-item">
              <context-info :prescription="prescription" v-if="prescription" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KidInfoTitle from '@/components/rooms/shared/kid-info-title';
import ContextInfo from './info';

export default {
  name: 'PrescriptionDetailHeader',
  components: { KidInfoTitle, ContextInfo },
  props: {
    prescription: { type: Object, required: false, default: null },
  },
  data() {
    return { user: null, kid: null };
  },
  watch: {
    prescription: {
      immediate: true,
      async handler() {
        if (!this.prescription) return;
        const { userId, kidId } = this.prescription;
        this.user = await this.services.user.load(userId);
        this.kid = await this.services.kid.loadByExternalId(userId, kidId);
      },
    },
  },
};
</script>
