<template>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label :for="name" v-if="!withoutLabel">{{ label }}</label>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          :id="name"
          :name="name"
          v-model="value"
        />
        <label class="custom-control-label" :for="name"></label>
      </div>
    </div>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'CheckboxInput',
  mixins: [ModelInputMixin],
};
</script>
