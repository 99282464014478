<template>
  <form @submit.stop.prevent="search">
    <div
      class="input-group form-group form-group-clear form-group--refined mb-0"
    >
      <div class="input-group-prepend">
        <button class="btn btn-ico btn-minimal" type="submit">
          <font-awesome-icon :icon="['fal', 'search']" :class="cssText" />
        </button>
      </div>

      <input
        type="text"
        v-model="query"
        class="form-control border-0"
        :placeholder="$t('inbox.room.savedReplies.search.placeholder')"
        :aria-label="$t('inbox.room.savedReplies.search.placeholder')"
      />
      <span class="form-control-clear" v-if="query">
        <button
          class="btn btn-link btn-ico"
          @click.stop.prevent="reset"
          type="button"
        >
          <font-awesome-icon :icon="['fal', 'times']" :class="cssText" />
        </button>
      </span>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    placeholder: { type: String },
  },
  data() {
    return {
      query: null,
    };
  },
  methods: {
    search() {
      const cleanQuery =
        this.query && this.query.trim().length > 0 ? this.query.trim() : null;
      console.log(`searching for ${cleanQuery}`);
      this.$emit('search', cleanQuery);
    },
    reset() {
      this.query = null;
      this.$emit('search', null);
    },
  },
};
</script>
