<template>
  <div class="px-5">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <date-picker
          v-bind:value="value.startDate"
          v-on:input="selectStartDate($event)"
        />
      </div>
      <span>
        <font-awesome-icon
          :icon="['fal', 'long-arrow-right']"
          class="icon-md"
        />
      </span>
      <div>
        <date-picker
          v-bind:value="value.endDate"
          v-on:input="selectEndDate($event)"
        />
      </div>
    </div>

    <div class="mt-4">
      <agent-picker
        :groupName="groupNames.PRESCRIPTION_INITIATORS"
        :unassignedIcon="['fad', 'user-tag']"
        :agent="value.agent"
        :allowUnselecting="true"
        :hintLabel="$t('prescriptions.filter.agentPicker.hint')"
        :unselectedLabel="$t('prescriptions.filter.agentPicker.unselected')"
        :fullWidth="true"
        :skipDisabled="false"
        :strictlyInGroup="true"
        @select-agent="selectAgent"
      />
    </div>

    <div class="mt-4">
      <agent-picker
        :groupName="groupNames.PRESCRIPTION_REFERRERS"
        :unassignedIcon="['fad', 'user-md']"
        :agent="value.doctor"
        :allowUnselecting="true"
        :hintLabel="$t('prescriptions.filter.doctorPicker.hint')"
        :unselectedLabel="$t('prescriptions.filter.doctorPicker.unselected')"
        :fullWidth="true"
        :skipDisabled="false"
        :strictlyInGroup="true"
        @select-agent="selectDoctor"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrescriptionListFilter',
  props: {
    value: { type: Object, required: true },
  },
  methods: {
    selectDoctor(doctor) {
      this.$emit('input', { ...this.value, doctor });
    },
    selectAgent(agent) {
      this.$emit('input', { ...this.value, agent });
    },
    selectStartDate(startDate) {
      this.selectDates(startDate, this.value.endDate);
    },
    selectEndDate(endDate) {
      this.selectDates(this.value.startDate, endDate);
    },
    selectDates(startDate, endDate) {
      if (endDate < startDate) {
        const tmpDate = startDate;
        startDate = endDate;
        endDate = tmpDate;
      }
      this.$emit('input', { ...this.value, startDate, endDate });
    },
  },
};
</script>
