<template>
  <div
    class="tab-pane fade h-100 show active"
    id="tab-content-dialogs"
    role="tabpanel"
  >
    <div class="d-flex flex-column h-100">
      <div class="hide-scrollbar h-100">
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold d-flex align-items-center">
            <font-awesome-icon :icon="icon(inbox)" class="mr-5" />
            <span>{{ inbox.label }}</span>
          </h2>
        </div>

        <div class="container-fluid">
          <div class="mb-6">
            <status-picker
              :currentStatusId="statusId"
              :inboxName="inboxName"
              :counter="counter"
              v-if="!isSnoozedInbox && inbox.hasClosedPicker"
            />

            <snoozed-status-picker
              :currentStatusId="statusId"
              :counter="counter"
              v-if="isSnoozedInbox"
            />
          </div>
        </div>

        <loader style="min-height: 50vh" :isLoading="isLoading">
          <div class="container-fluid min-height" v-if="isEmpty">
            <div
              class="d-flex flex-column align-items-center justify-content-center min-height"
            >
              <font-awesome-icon icon="inbox" class="text-light" size="5x" />
              <p class="h5 mt-3 text-gray-700">
                {{ $t('inbox.empty') }}
              </p>
            </div>
          </div>

          <div class="container-fluid px-0">
            <!-- Chats -->
            <nav class="nav d-block list-discussions-js mb-n6">
              <!-- Chat link -->
              <router-link
                :to="{ name: 'roomDetail', params: { id: room.id } }"
                class="text-reset nav-link p-0 room"
                v-for="(room, index) in rooms"
                :key="room.id"
                :tabindex="index + 1"
              >
                <RoomItem
                  :room="room"
                  :markIfMine="inboxName === 'assigned'"
                  :active="roomId === room.id"
                  :isLast="index === rooms.length - 1"
                />
              </router-link>
            </nav>
            <!-- Chats -->
          </div>
        </loader>
      </div>
    </div>
  </div>
</template>

<script>
import { findInbox } from '@/shared/inboxes';
import RoomItem from './list-item';
import StatusPicker from './status-picker';
import SnoozedStatusPicker from './snoozed/status-picker';

export default {
  name: 'Rooms',
  components: { RoomItem, StatusPicker, SnoozedStatusPicker },
  props: ['inboxName', 'statusId'],
  data() {
    return {
      allRooms: [],
      isLoading: true,
    };
  },
  computed: {
    inbox() {
      return findInbox(this.inboxName);
    },
    roomId() {
      return this.$route.params.id;
    },
    rooms() {
      return this.services.room.filter(this.allRooms);
    },
    counter() {
      return (this.statusId === 'open' || this.statusId === 'awake') &&
        this.rooms.length > 0
        ? this.rooms.length
        : null;
    },
    isEmpty() {
      return this.rooms.length === 0;
    },
    isSnoozedInbox() {
      return this.inboxName === 'snoozed';
    },
  },
  methods: {
    icon(inbox) {
      return inbox.icon;
    },
    loadRooms() {
      this.$bind(
        'allRooms',
        this.services.room.findAll(
          this.inboxName,
          this.statusId,
          this.currentAgent
        )
      ).then(() => (this.isLoading = false)); // https://github.com/vuejs/vuefire/issues/35
    },
  },
  watch: {
    inboxName: {
      immediate: true,
      handler() {
        this.isLoading = true;
        this.loadRooms();
      },
    },
    statusId: {
      immediate: true,
      handler() {
        this.isLoading = true;
        this.loadRooms();
      },
    },
  },
};
</script>

<style scoped>
.room:not(.router-link-active):focus .card {
  border-left-color: var(--warning) !important;
}
</style>
