import Welcome from '@/components/welcome';
import Agents from '@/components/agents/list';
import AgentRooms from '@/components/agents/rooms';
import AgentForm from '@/components/agents/form';
import RoomDetail from '@/components/rooms/show';

export default [
  {
    path: '/agents',
    name: 'agents',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace-new" */ '@/views/workspace.vue'
      ),
    redirect: 'agents/list',
    props: true,
    meta: {
      protected: true,
      policy: { scope: 'agent', action: 'list' },
    },
    children: [
      {
        path: 'list',
        name: 'listAgents',
        components: {
          default: Welcome,
          sidebar: Agents,
        },
        props: true,
      },
      {
        path: ':agentId/rooms',
        name: 'agent',
        components: {
          default: Welcome,
          sidebar: AgentRooms,
        },
        props: {
          sidebar: true,
        },
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
      {
        path: ':agentId/profile/edit',
        name: 'agentProfile',
        components: {
          default: Welcome,
          sidebar: AgentForm,
        },
        props: {
          sidebar: true,
        },
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
      {
        path: ':agentId/rooms/:id',
        name: 'agentRoomDetail',
        components: {
          default: RoomDetail,
          sidebar: AgentRooms,
        },
        props: {
          default: true,
          sidebar: true,
        },
      },
    ],
  },
];
