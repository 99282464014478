<template>
  <modal name="dialog" height="auto">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center">
        <h5 class="modal-title">{{ $t('agents.form.modal.title') }}</h5>
        <div class="ml-auto">
          <button
            class="btn btn-xl btn-link text-muted pr-0"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <font-awesome-icon :icon="['fal', 'times']" size="lg" />
          </button>
        </div>
      </div>
      <div class="modal-body">
        <p v-html="$t('agents.form.modal.body', { url })"></p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          @click="close"
        >
          {{ $t('agents.form.modal.close') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'NewAgentSuccessModal',
  props: ['response'],
  data() {
    return {
      url: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide('dialog');
    },
  },
  watch: {
    response: {
      immediate: true,
      handler(response) {
        if (response === null) return;

        // Example: http://localhost:8080/reset-password/LJbSqKzWt4PvVPVkp3Vh/L8ThMcV4lwrPQi9WhzPA
        const resolvedPath = this.$router.resolve({
          name: 'resetPassword',
          params: {
            agentId: response.agentID,
            passwordToken: response.agentPasswordToken,
          },
        });

        this.url = `${window.location.origin}${resolvedPath.route.path}`;

        // copy the url in the clipboard
        this.$copyText(this.url)
          .then(() => {})
          .catch(error => console.log('error', error));

        // show the modal!
        this.$modal.show('dialog');
      },
    },
  },
};
</script>
