<template>
  <div class="h-100">
    <!-- Accept or decline the assignment -->
    <div class="text-center" v-if="$p('room', room, 'acceptOrRefuse')">
      <button type="button" class="btn btn-primary" @click="acceptAssignment">
        <font-awesome-icon
          :icon="['fal', 'check-square']"
          class="text-white h4 mr-3 mb-0"
        />
        {{ $t('inbox.room.assignment.accept') }}
      </button>

      <span class="mx-4">{{ $t('inbox.room.assignment.or') }}</span>

      <button type="button" class="btn btn-secondary" @click="refuseAssignment">
        <font-awesome-icon
          :icon="['far', 'times-circle']"
          class="h4 mr-3 mb-0"
        />
        {{ $t('inbox.room.assignment.refuse') }}
      </button>
    </div>

    <!-- Assign the room to myself -->
    <div
      class="d-flex justify-content-center"
      v-if="$p('room', room, 'assignToAgent')"
    >
      <button type="button" class="btn btn-primary" @click="assignToMe">
        <font-awesome-icon
          :icon="['fal', 'hand-point-right']"
          class="text-white h4 mr-3 mb-0"
        />
        {{ $t('inbox.room.assignToMeButton') }}
      </button>

      <!-- Re-snooze the room -->
      <button
        type="button"
        class="btn btn-outline-secondary ml-5"
        @click="openSnoozeModal"
        v-if="$p('room', room, 'reSnooze')"
      >
        <font-awesome-icon :icon="['far', 'clock']" class="h4 mr-3 mb-0" />
        <span :class="cssText">{{ $t('inbox.room.reSnoozeButton') }}</span>
      </button>
    </div>

    <!-- Show who else has been assigned to that room  -->
    <div
      class="alert alert-secondary mb-0 d-flex justify-content-center align-items-center"
      v-if="isAssignedToSomeoneElse"
      role="alert"
    >
      <font-awesome-icon :icon="['fal', 'hand-paper']" class="h4 mr-3 mb-0" />
      <span>{{ assignedAgentName }}</span>

      <!-- Cancel assignement -->
      <button
        class="btn btn-md d-flex align-items-center btn-outline-dark ml-4"
        role="button"
        @click="cancelPendingRoomAssignment"
        v-if="$policy('room', room, 'cancelPendingAssignment')"
      >
        <font-awesome-icon :icon="['far', 'history']" class="mr-4" />
        {{ $t('inbox.room.cancelPendingAssignment') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomOpenStatus',
  props: {
    room: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isAssignedToSomeoneElse() {
      return (
        this.room &&
        this.room.agent &&
        this.room.agent.id !== this.currentAgent.id
      );
    },
    assignedAgentName() {
      const { agent } = this.room;
      const name = this.services.agent.getFullName(agent);
      const key = agent.assignmentAccepted
        ? `inbox.room.assignedToAgent.${agent.gender}`
        : `inbox.room.assignmentWaitingForAcceptation`;
      return this.$t(key, { name });
    },
  },
  methods: {
    acceptAssignment() {
      this.services.room.acceptAssignment(this.room);
    },
    refuseAssignment() {
      this.services.room.refuseAssignment(this.room);
    },
    cancelPendingRoomAssignment() {
      this.services.room.assignAgent(this.room, null, this.currentAgent);
    },
    assignToMe() {
      this.services.room.assignAgent(
        this.room,
        this.currentAgent,
        this.currentAgent
      );
    },
    openSnoozeModal() {
      this.$modal.show(`snooze-room-modal`);
    },
  },
};
</script>
