<template>
  <div>
    <draggable
      tag="ul"
      class="list-group list-group-flush"
      :list="selectedAgents"
      :disabled="!sortable"
      handle=".handle"
      @sort="onSort"
    >
      <li
        v-for="agent in selectedAgents"
        :key="agent.id"
        class="list-group-item py-6"
      >
        <div class="media align-items-center">
          <font-awesome-icon
            :icon="['fas', 'grip-vertical']"
            class="handle cursor-move mr-6"
            v-if="sortable"
          />
          <avatar
            :user="agent"
            color="dark"
            :online="agent.online"
            class="mr-5"
          />
          <div class="media-body">
            <h6 class="mb-0">
              {{ agent | agentFullName }}
              <span class="badge badge-warning ml-2" v-if="agent.isDisabled">
                {{ $t('agents.isDisabledTag') }}
              </span>
            </h6>
            <small class="text-muted">{{ agent | agentTitle }}</small>
          </div>
          <div class="align-self-center ml-5">
            <destroy-button
              :text="$t('admin.agentGroups.form.agentsInput.destroy.text')"
              :buttonLabel="
                $t('admin.agentGroups.form.agentsInput.destroy.button')
              "
              @destroy="onRemove(agent.id)"
            />
          </div>
        </div>
      </li>
    </draggable>

    <div class="d-flex mt-6">
      <select class="form-control" id="saved-reply-parent" v-model="newAgentId">
        <option
          v-for="agent in filteredAgents"
          :key="agent.id"
          :value="agent.id"
          >{{ agentOptionLabel(agent) }}</option
        >
      </select>

      <button
        class="btn btn-outline-secondary btn-sm ml-3"
        :class="cssText"
        v-on:click.stop.prevent="onAdd"
      >
        {{ $t('admin.agentGroups.form.agentsInput.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'AgentsInput',
  components: { Draggable },
  props: {
    agentIds: { type: Array, default: () => [] },
    sortable: { type: Boolean, default: false },
  },
  data() {
    return {
      newAgentId: null,
      agents: [],
      selectedAgents: [],
    };
  },
  mounted() {
    this.$bind('agents', this.services.agent.findAll());
  },
  computed: {
    filteredAgents() {
      return this.services.agent.sortByDisabledLast(
        this.services.agent.filter(this.agents, { excludeIds: this.agentIds })
      );
    },
  },
  methods: {
    agentOptionLabel(agent) {
      const { agentFullName, agentTitle } = this.$options.filters;
      const label = `${agentFullName(agent)} (${agentTitle(agent)})`;
      return agent.isDisabled
        ? this.$i18n.t(`agents.isDisabledOption.${agent.gender}`, { label })
        : label;
    },
    onAdd() {
      if (this.newAgentId) this.$emit('add', this.newAgentId);
    },
    onRemove(agentId) {
      this.$emit('remove', agentId);
    },
    onSort() {
      this.$emit(
        'sort',
        this.selectedAgents.map(agent => agent.id)
      );
    },
  },
  watch: {
    agentIds: {
      immediate: true,
      handler(agentIds) {
        this.services.agent
          .findAllByIds(agentIds)
          .then(
            agents =>
              (this.selectedAgents = this.sortable
                ? agents
                : this.services.agent.sortByDisabledLast(agents))
          );
      },
    },
  },
};
</script>
