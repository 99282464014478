<template>
  <router-link
    :to="{ name: 'editAgentGroup', params: { agentGroupId: agentGroup.id } }"
    tag="div"
    class="media align-items-center py-3"
  >
    <div class="media-body">
      <div class="d-flex align-items-center">
        <font-awesome-icon
          :icon="['fas', 'grip-vertical']"
          class="handle cursor-move mr-3"
        />
        <h6 class="mb-0">
          {{ agentGroup.name }}
        </h6>
        <span class="badge badge-primary badge-pill ml-auto">{{
          agentGroup.agentIds.length
        }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'AgentGroupListItem',
  props: {
    agentGroup: { type: Object },
  },
};
</script>
