<template>
  <div
    class="card card-active-listener rounded-0 border-left-0 border-right-0"
    :class="{
      'primary-border-right': active,
      'secondary-border-right': markIfMine && isMine,
      'transparent-border-right': !active,
      'border-bottom-0': !isLast,
    }"
  >
    <div class="card-body position-relative">
      <div
        class="position-absolute text-primary"
        style="font-size: 0.5rem; bottom: 1.9rem; right: 0.4rem;"
        v-if="!hasRead"
      >
        <font-awesome-icon icon="circle" />
      </div>
      <h5 class="card-title d-flex align-items-center">
        <span class="mr-auto d-flex flex-column" :class="cssText">
          <span>{{ title }}</span>
          <span class="small">{{ kid }}</span>
        </span>
        <p class="small text-muted text-nowrap ml-4" v-if="isSnoozed">
          <span class="mr-3" v-if="hasPrescription">
            <font-awesome-icon
              :icon="['fal', 'clipboard-prescription']"
              size="lg"
              class="text-muted"
            />
          </span>
          <span v-else>{{ formattedDate }}</span>
        </p>
        <p
          class="small text-muted text-nowrap ml-4"
          v-tooltip="{ content: formattedDate }"
          v-else
        >
          <span class="mr-3" v-if="hasPrescription">
            <font-awesome-icon
              :icon="['fal', 'clipboard-prescription']"
              size="lg"
              class="text-muted"
            />
          </span>
          <timeago
            :datetime="date"
            :auto-update="60"
            v-if="useTimeago"
          ></timeago>
          <span v-else>{{ date | formatTimestamp }}</span>
        </p>
      </h5>
      <div class="media d-flex align-items-center">
        <avatar
          color="light"
          :user="whoPostedIt"
          size="xs"
          :onlyFirstName="isPostedByUser"
        />
        <div class="media-body overflow-hidden ml-4">
          <div class="text-truncate" v-html="formattedText" v-if="isText" />
          <div class="text-truncate" v-if="isImage">
            {{ $t('inbox.room.hasUploadedImage') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/shared/utils';

export default {
  name: 'RoomItem',
  props: {
    room: { type: Object, default: undefined },
    active: { type: Boolean, default: false },
    isLast: { type: Boolean, default: false },
    markIfMine: { type: Boolean, default: false },
    useTimeago: { type: Boolean, default: true },
  },
  computed: {
    title() {
      return this.room.user && this.room.user.firstName
        ? this.room.user.firstName
        : this.room.id;
    },
    kid() {
      return this.room.kid && this.room.kid.firstName;
    },
    lastMessage() {
      return this.room.lastMessage;
    },
    hasPrescription() {
      return !this.isBlank(this.room.prescriptions);
    },
    date() {
      if (this.isSnoozed) return this.room.status.snoozedUntil?.toDate();
      if (this.isClosed) return this.room.status.updatedAt?.toDate();
      if (this.lastMessage) return this.lastMessage.createdAt?.toDate();
      else return this.room.createdAt?.toDate();
    },
    formattedDate() {
      return formatDate(this.date);
    },
    isPostedByMe() {
      return this.lastMessage
        ? this.lastMessage.sender.id === this.currentAgent.id
        : false;
    },
    isMine() {
      return this.room && this.room.agent
        ? this.room.agent.id === this.currentAgent.id
        : false;
    },
    isPostedByUser() {
      return this.lastMessage && this.lastMessage.sender.type === 'user';
    },
    isSnoozed() {
      return this.room.status.type === 'snoozed';
    },
    isClosed() {
      return this.room.status.type === 'closed';
    },
    whoPostedIt() {
      if (this.lastMessage === null) return null;
      return this.isPostedByMe ? this.currentAgent : this.lastMessage.sender;
    },
    isText() {
      return this.lastMessage && this.lastMessage.type === 'text';
    },
    isImage() {
      return this.lastMessage && this.lastMessage.type === 'image';
    },
    formattedText() {
      return this.formatMobileMessageToHtml(this.lastMessage.content).split(
        '<br />'
      )[0];
    },
    hasRead() {
      return (this.room.haveAgentsRead || []).includes(this.currentAgent.id);
    },
  },
  watch: {
    hasRead: {
      immediate: false,
      handler(hasRead) {
        if (!hasRead) this.playSound();
      },
    },
  },
};
</script>
