<template>
  <div class="d-flex align-items-center mb-4">
    <div
      class="border rounded border-color-gray-500 font-weight-bold text-dark px-4 py-3"
    >
      {{ brandedDrug.molecules[0].name }} ({{ brandedDrug.name }})
    </div>
    <div
      class="border rounded border-color-gray-500 text-dark px-4 py-3 ml-4"
      v-if="drug"
    >
      {{ drug.shortLabel }}
    </div>
    <div
      class="border rounded border-color-gray-500 text-dark px-4 py-3 ml-4 flex-fill"
      v-if="dosage"
    >
      {{ dosage.shortLabel }} {{ duration }}
    </div>
    <div class="ml-4">
      <button
        class="btn btn-md text-light border-color-gray-500"
        :class="{ 'text-dark': replaceable }"
        @click="$emit('toggle-replaceable', index)"
        v-tooltip="substitutableTooltip"
      >
        <font-awesome-icon :icon="['fal', 'clone']" flip="vertical" />
      </button>
    </div>
    <div class="ml-4">
      <destroy-button
        :text="$t(`${i18nScope}.destroy.text`)"
        :buttonLabel="$t(`${i18nScope}.destroy.button`)"
        :dropup="true"
        @destroy="$emit('destroy', index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrescriptionBoardListItem',
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  computed: {
    brandedDrug() {
      return this.item.brandedDrug;
    },
    drug() {
      return this.item.drug;
    },
    dosage() {
      return this.item.dosage;
    },
    duration() {
      return this.item.duration;
    },
    replaceable() {
      return this.item.replaceable;
    },
    substitutableTooltip() {
      return this.$t(
        `${this.i18nScope}.replaceable.${this.replaceable ? 'on' : 'off'}`
      );
    },
    i18nScope() {
      return 'inbox.room.prescriptionBoard.new.listItem';
    },
  },
};
</script>
