<template>
  <modal
    name="prescription-preview-modal"
    width="650"
    height="auto"
    scrollable
    :clickToClose="false"
  >
    <div class="modal-content">
      <div class="modal-header border-0 pt-4 pb-4 px-6">
        <div class="modal-title w-100 d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ $t(`${i18nScope}.title`) }}
          </h4>

          <div class="ml-auto d-flex align-items-center">
            <button
              class="btn btn-xl btn-link text-muted pr-0"
              @click="close()"
            >
              <font-awesome-icon :icon="['fal', 'times']" size="lg" />
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body px-6 pt-0 pb-7">
        <div class="w-100 bg-gray-100" style="height: 40rem">
          <iframe
            :src="previewUrl"
            v-if="previewUrl"
            class="w-100 h-100 border-0"
          />
        </div>

        <div class="mt-6 d-flex align-items-center">
          <button
            type="button"
            class="btn btn-secondary btn-md ml-auto"
            @click="close()"
          >
            {{ $t(`${i18nScope}.closeButton`) }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PrescriptionConfirmationModal',
  props: {
    previewUrl: { type: String, required: true },
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.previewModal';
    },
  },
  methods: {
    close() {
      this.$modal.hide('prescription-preview-modal');
    },
  },
};
</script>
