<template>
  <div>
    <div class="alert alert-warning mt-6 mx-6" role="alert" v-if="invalidForm">
      {{ $t('admin.savedReplies.form.invalid') }}
    </div>

    <div class="py-6">
      <div class="container-fluid mb-6">
        <form v-on:submit.prevent="onSubmit">
          <properties-form
            :savedReply="savedReply"
            @change="changes => $emit('change', changes)"
          />

          <group-form
            :savedReply="savedReply"
            @change="changes => $emit('change', changes)"
          />

          <hr class="my-6" />

          <content-form
            :savedReply="savedReply"
            @change="changes => $emit('change', changes)"
          />

          <hr class="my-6" />

          <medical-report-form
            :savedReply="savedReply"
            @change="changes => $emit('change', changes)"
          />

          <div class="text-right pt-6">
            <submit-button
              :labels="
                $t(
                  isNew
                    ? 'admin.savedReplies.form.submit.create'
                    : 'admin.savedReplies.form.submit.update'
                )
              "
              :buttonState="buttonState"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PropertiesForm from './properties';
import GroupForm from './group';
import ContentForm from './content';
import MedicalReportForm from './medical-report';

export default {
  name: 'SavedReplyForm',
  components: { PropertiesForm, GroupForm, ContentForm, MedicalReportForm },
  props: {
    savedReply: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  data() {
    return {
      invalidForm: false,
      buttonState: 'default',
    };
  },
  computed: {
    isNew() {
      return !this.savedReply.id;
    },
  },
  methods: {
    onSubmit() {
      if (this.services.savedReply.isValid(this.savedReply)) {
        this.buttonState = 'inProgress';
        this.invalidForm = false;
        this.persist()
          .then(() => (this.buttonState = 'success'))
          .catch(() => (this.buttonState = 'fail'));
      } else {
        this.invalidForm = true;
      }
    },
  },
};
</script>
