var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main main-visible"},[_c('div',{staticClass:"d-flex h-100 w-100"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"sidebar-header chat-header border-bottom"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-9"},[_c('h2',{staticClass:"font-bold mb-0"},[_c('font-awesome-icon',{attrs:{"icon":_vm.topic.icon}}),_vm._v(" "+_vm._s(_vm.$t(("admin.medicalReportLibrary.list.topics." + (_vm.topic.name))))+" "),_c('span',[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.$t('admin.medicalReportLibrary.edit.title'))+" ")],1)]),_c('div',{staticClass:"col-3 text-right"},[_c('ul',{staticClass:"nav justify-content-end"},[(
                    _vm.$p(
                      'medicalReportLibrary',
                      Object.assign({}, _vm.libraryItem, {hasChildren: _vm.hasChildren}),
                      'destroy'
                    )
                  )?_c('li',{staticClass:"nav-item list-inline-item"},[_c('destroy-button',{attrs:{"text":_vm.$t(
                        'admin.medicalReportLibrary.edit.actions.destroy.text'
                      ),"buttonLabel":_vm.$t(
                        'admin.medicalReportLibrary.edit.actions.destroy.button'
                      )},on:{"destroy":_vm.onDestroy}})],1):_vm._e()])])])])]),_c('div',{staticClass:"flex-grow-2 overflow-auto"},[(_vm.libraryItem)?_c('library-item-form',{attrs:{"topic":_vm.topic,"libraryItem":_vm.libraryItem,"persist":_vm.onPersist},on:{"change":_vm.onChange}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }