<template>
  <aside class="room-sidebar">
    <div class="container d-flex flex-column h-100">
      <sidebar-header
        :room="room"
        @close-sidebar="$emit('close')"
        class="mb-4"
      />
      <antecedents-input :room="room" class="mb-7" />

      <history-list :room="room" />
    </div>
  </aside>
</template>

<script>
import SidebarHeader from '@/components/rooms/show/sidebar/header';
import AntecedentsInput from '@/components/rooms/show/sidebar/antecedents';
import HistoryList from '@/components/rooms/show/sidebar/history';

export default {
  name: 'RoomSidebar',
  components: {
    SidebarHeader,
    AntecedentsInput,
    HistoryList,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
};
</script>
