<template>
  <div class="form-group">
    <label :for="name" v-if="!withoutLabel">
      {{ label }}
    </label>
    <multiselect
      v-model="customValue"
      :multiple="true"
      :options="agentGroups"
      track-by="id"
      label="name"
      v-if="agentGroups"
      :placeholder="$t('form.agentGroupInput.placeholder')"
      :selectedLabel="$t('form.agentGroupInput.selectedLabel')"
      :selectLabel="$t('form.agentGroupInput.selectLabel')"
      :deselectLabel="$t('form.agentGroupInput.deselectLabel')"
    >
    </multiselect>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'AgentGroupInput',
  mixins: [ModelInputMixin],
  props: {
    legacy: { type: Boolean, default: false },
  },
  data() {
    return { agentGroups: null };
  },
  mounted() {
    this.$bind('agentGroups', this.services.agentGroup.findAll()).then(
      groups =>
        (this.agentGroups = groups.map(group => ({ id: group.id, ...group })))
    );
  },
  computed: {
    customValue: {
      get() {
        const agentGroupIds = this.safeModel[this.attributeName] || [];
        return agentGroupIds
          .map(id => this.agentGroups.find(group => group.id === id))
          .filter(group => group);
      },
      set(value) {
        // NOTE: the 'all' group is required to list all the saved replies with no groups
        const allValue = this.legacy ? 'all' : 'ALL';
        const agentGroupIds =
          value.length > 0 ? value.map(group => group.id) : [allValue];
        this.$parent.$emit('change', { [this.attributeName]: agentGroupIds });
      },
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
