var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message",class:{ 'message-right': !_vm.isPostedByUser }},[_c('avatar',{attrs:{"user":_vm.whoPostedIt,"color":"dark","onlyFirstName":_vm.isPostedByUser}}),_c('div',{staticClass:"message-body",class:{
      'mr-4 mr-lg-5': !_vm.isPostedByUser,
      'ml-4 ml-lg-5': _vm.isPostedByUser,
    }},[_c('div',{staticClass:"message-content",class:{
        'bg-primary text-white': !(
          _vm.isPostedByUser || _vm.isPostedBySystemOrBiloba
        ),
        'bg-light': _vm.isPostedByUser || _vm.isPostedBySystemOrBiloba,
      }},[(_vm.isText)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.formattedText)}}):_vm._e(),(_vm.isImage)?_c('div',{staticClass:"overflow-hidden",staticStyle:{"height":"24rem","max-width":"24rem"}},[_c('loader',{staticClass:"h-100 d-flex align-items-center",attrs:{"isLoading":!_vm.isImageLoaded}},[_c('img',{staticClass:"d-block h-100",attrs:{"src":_vm.message.content},on:{"load":_vm.onImageZoomable}})]),_c('img',{staticClass:"d-none",attrs:{"src":_vm.message.content},on:{"load":_vm.onImageLoaded}})],1):_vm._e(),(_vm.isVideo)?_c('div',{staticStyle:{"height":"250px","min-width":"250px"}},[_c('loader',{staticClass:"h-100 d-flex align-items-center",attrs:{"isLoading":!_vm.isVideoLoaded}},[_c('video',{attrs:{"controls":"","controlslist":"nodownload","disablepictureinpicture":"","height":"250px","width":"250px"}},[_c('source',{attrs:{"src":_vm.message.content,"type":"video/mp4"}})])]),_c('video',{staticClass:"d-none",attrs:{"controls":"","height":"250"},on:{"loadeddata":_vm.onVideoLoaded}},[_c('source',{attrs:{"src":_vm.message.content,"type":"video/mp4"}})])],1):_vm._e()]),_c('div',{staticClass:"message-footer"},[_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(_vm.message.createdAt))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }