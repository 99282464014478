var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dropdown',{attrs:{"icon":['far', 'ellipsis-v'],"size":"md","menuAlign":"right"}},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item py-3"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('inbox.room.info.prescriptionId'))+" ")]),_c('strong',{class:_vm.cssText},[_vm._v(_vm._s(_vm.prescription.id))])]),_c('div',{staticClass:"align-self-center ml-5"},[_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.prescription.id),expression:"prescription.id",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onPrescriptionIdCopy),expression:"onPrescriptionIdCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"},{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('inbox.room.info.copied'),
              trigger: 'manual',
              show: _vm.prescriptionIdCopied,
            }),expression:"{\n              content: $t('inbox.room.info.copied'),\n              trigger: 'manual',\n              show: prescriptionIdCopied,\n            }"}],staticClass:"btn btn-link p-0",class:_vm.cssText,attrs:{"type":"button"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'copy']}})],1)])])]),_c('li',{staticClass:"list-group-item py-3"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('inbox.room.info.roomURL')))]),_c('strong',{class:_vm.cssText},[_vm._v(_vm._s(_vm.roomURL))])]),_c('div',{staticClass:"align-self-center ml-5"},[_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.roomURL),expression:"roomURL",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onRoomURLCopy),expression:"onRoomURLCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"},{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('inbox.room.info.copied'),
              trigger: 'manual',
              show: _vm.roomURLCopied,
            }),expression:"{\n              content: $t('inbox.room.info.copied'),\n              trigger: 'manual',\n              show: roomURLCopied,\n            }"}],staticClass:"btn btn-link p-0",class:_vm.cssText,attrs:{"type":"button"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'copy']}})],1)])])]),_c('li',{staticClass:"list-group-item py-3"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('inbox.room.info.roomId')))]),_c('strong',{class:_vm.cssText},[_vm._v(_vm._s(_vm.prescription.roomId))])]),_c('div',{staticClass:"align-self-center ml-5"},[_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.prescription.roomId),expression:"prescription.roomId",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onRoomIdCopy),expression:"onRoomIdCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"},{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('inbox.room.info.copied'),
              trigger: 'manual',
              show: _vm.roomIdCopied,
            }),expression:"{\n              content: $t('inbox.room.info.copied'),\n              trigger: 'manual',\n              show: roomIdCopied,\n            }"}],staticClass:"btn btn-link p-0",class:_vm.cssText,attrs:{"type":"button"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'copy']}})],1)])])]),_c('li',{staticClass:"list-group-item py-3"},[_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('inbox.room.info.userId')))]),_c('strong',{class:_vm.cssText},[_vm._v(_vm._s(_vm.prescription.userId))])]),_c('div',{staticClass:"align-self-center ml-5"},[_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.prescription.userId),expression:"prescription.userId",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onUserIdCopy),expression:"onUserIdCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"},{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('inbox.room.info.copied'),
              trigger: 'manual',
              show: _vm.userIdCopied,
            }),expression:"{\n              content: $t('inbox.room.info.copied'),\n              trigger: 'manual',\n              show: userIdCopied,\n            }"}],staticClass:"btn btn-link p-0",class:_vm.cssText,attrs:{"type":"button"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'copy']}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }