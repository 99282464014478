<template>
  <modal name="dosage-picker" width="650" height="auto" scrollable>
    <transition :name="`slide-${slideDirection}`" mode="out-in">
      <component
        v-bind:is="currentStepComponent"
        :kid="kid"
        :prescriptionItem="prescriptionItem"
        @change="change"
        @reset="reset"
        @close="close"
        @accept="add"
      >
      </component>
    </transition>
  </modal>
</template>

<script>
import Step1 from './step-1';
import Step2 from './step-2';
import Step3 from './step-3';

export default {
  name: 'DosagePicker',
  components: { Step1, Step2, Step3 },
  props: {
    kid: { type: Object, required: true },
  },
  data() {
    return {
      step: 1,
      slideDirection: 'left',
      prescriptionItem: {
        brandedDrug: null,
        drug: null,
        dosage: null,
        duration: null,
      },
    };
  },
  computed: {
    currentStepComponent() {
      return [Step1, Step2, Step3][this.step - 1];
    },
  },
  methods: {
    add() {
      this.$emit('add', { ...this.prescriptionItem });
      this.reset();
      this.close();
    },
    change(changes) {
      this.prescriptionItem = { ...this.prescriptionItem, ...changes };
    },
    reset(keep) {
      this.prescriptionItem = {
        brandedDrug: null,
        drug: null,
        dosage: null,
        duration: null,
        ...keep,
      };
    },
    close() {
      this.$modal.hide('dosage-picker');
    },
  },
  watch: {
    prescriptionItem(item) {
      if (!item.brandedDrug) {
        this.step = 1;
      } else if (!item.dosage) {
        this.step = 2;
      } else this.step = 3;
    },
    step(newStep, oldStep) {
      this.slideDirection = newStep > oldStep ? 'left' : 'right';
    },
  },
};
</script>
