import merge from 'deepmerge';
import { getDB, nowOnServer } from '@/shared/db';
import {
  isBlank,
  isObjectEmpty,
  removeAccents,
  interpolate,
  htmlToMobileContent,
  getSupportedLanguage,
} from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';

const calculateDepth = (nodes, depth) => {
  nodes.forEach(node => {
    node.depth = depth;
    calculateDepth(node.children, depth + 1);
  });
};

const flattenTree = (nodes, rejectedId) => {
  return nodes
    .map(node => {
      if (node.id === rejectedId) return [];
      return [].concat(node, flattenTree(node.children, rejectedId));
    })
    .flat(42);
};

export const buildContentTree = (list, language, interpolations) => {
  const safeLanguage = getSupportedLanguage(language);
  const contentify = savedReply => {
    var clonedSavedReply = { id: savedReply.id, ...savedReply };
    clonedSavedReply.title = savedReply.title[safeLanguage];
    if (clonedSavedReply.leaf) {
      clonedSavedReply.image = savedReply.image[safeLanguage];
      clonedSavedReply.content = savedReply.content[safeLanguage];
      clonedSavedReply.content = htmlToMobileContent(
        interpolate(clonedSavedReply.content, interpolations)
      );
    }
    return clonedSavedReply;
  };

  return buildTree(list, contentify);
};

export const buildTree = (list, applyFunc) => {
  var memo = {};
  var root = [];

  // build the memo
  list.forEach(savedReply => {
    savedReply.children = [];
    memo[savedReply.id] = applyFunc ? applyFunc(savedReply) : savedReply;
  });

  // assign a parent to each saved reply
  list.forEach(savedReply => {
    if (savedReply.parentId) {
      var parent = memo[savedReply.parentId];
      if (parent) parent.children.push(memo[savedReply.id]);
    } else root.push(memo[savedReply.id]);
  });

  calculateDepth(root, 0);

  return root;
};

export const buildFlattenTree = (list, rejectedId) => {
  return flattenTree(buildTree(list), rejectedId);
};

export const build = attributes => {
  return merge(
    {
      title: { fr: '', en: '' },
      content: { fr: '', en: '' },
      image: { fr: '', en: '' },
      isImage: false,
      leaf: true,
      published: false,
      parentId: null,
      position: 99,
      agentGroupIds: ['ALL'],
    },
    attributes || {}
  );
};

export const filter = (list, groupIds) => {
  if (list === null) return null;
  let allGroupIds = ['all', 'ALL', ...groupIds];
  return list.filter(savedReply => {
    let requestedGroupIds = isBlank(savedReply.agentGroupIds)
      ? ['ALL']
      : savedReply.agentGroupIds;
    return requestedGroupIds.some(groupId => allGroupIds.includes(groupId));
  });
};

export const findAll = withTracking => {
  if (withTracking) trackAgent('VIEW', 'SAVED_REPLIES');
  return getDB()
    .collection('saved-replies')
    .orderBy('position', 'asc')
    .orderBy('title.fr', 'asc');
};

export const findAllRoots = () => {
  return getDB()
    .collection('saved-replies')
    .where('leaf', '==', false)
    .orderBy('position', 'asc')
    .orderBy('title.fr', 'asc');
};

export const find = id => {
  return getDB()
    .collection('saved-replies')
    .doc(id);
};

export const hasChildren = id => {
  return getDB()
    .collection('saved-replies')
    .where('parentId', '==', id)
    .get()
    .then(snapshot => !snapshot.empty);
};

export const create = savedReply => {
  trackAgent('CREATE', 'SAVED_REPLY', savedReply.title?.fr);
  return getDB()
    .collection('saved-replies')
    .add({ ...savedReply, createdAt: nowOnServer() });
};

export const update = savedReply => {
  trackAgent('UPDATE', 'SAVED_REPLY', savedReply.id);
  const savedReplyDoc = getDB()
    .collection('saved-replies')
    .doc(savedReply.id);
  return savedReplyDoc.update({ ...savedReply, updatedAt: nowOnServer() });
};

export const destroy = savedReply => {
  trackAgent('DESTROY', 'SAVED_REPLY', savedReply.id);
  return getDB()
    .collection('saved-replies')
    .doc(savedReply.id)
    .delete();
};

const recursiveSearch = (node, regexp) => {
  if (node.children && node.children.length > 0) {
    if (node.title && removeAccents(node.title).match(regexp))
      return node.children;
    else return node.children.map(child => recursiveSearch(child, regexp));
  } else
    return (node.content && removeAccents(node.content).match(regexp)) ||
      (node.title && removeAccents(node.title).match(regexp))
      ? node
      : null;
};

export const search = (nodes, query) => {
  const regexp = new RegExp(`${removeAccents(query)}`, 'i');
  return nodes
    .map(node => node.children.map(node => recursiveSearch(node, regexp)))
    .flat(42)
    .filter(node => node && node.leaf && node.published && node.title)
    .sort((n1, n2) => n1.title.localeCompare(n2.title));
};

export const recursiveUpdateTree = (parent, nodes, batch) => {
  nodes.forEach((node, index) => {
    const doc = getDB()
      .collection('saved-replies')
      .doc(node.id);
    var changes = {};

    if (node.position !== index) changes.position = index;

    if (parent && parent.id !== node.parentId) changes.parentId = parent.id;

    if (!isObjectEmpty(changes)) batch.update(doc, changes);

    recursiveUpdateTree(node, node.children, batch);
  });
};

export const updateTree = newTree => {
  var batch = getDB().batch();

  recursiveUpdateTree(null, newTree, batch);

  // update the whole tree with a single call to Firebase
  return batch.commit();
};

export const isValid = savedReply => {
  if (!savedReply) return false;
  return savedReply.title?.fr?.length > 0;
};
