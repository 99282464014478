<template>
  <loader
    :isLoading="isLoading"
    style="min-height: 50vh"
    class="pb-12"
    key="main"
  >
    <div class="container-fluid min-height" v-if="isEmpty">
      <div
        class="d-flex flex-column align-items-center justify-content-center min-height"
      >
        <font-awesome-icon icon="inbox" class="text-light" size="5x" />
        <p class="h5 mt-3 text-gray-700">
          {{ $t('inbox.empty') }}
        </p>
      </div>
    </div>

    <nav class="nav d-block list-discussions-js">
      <router-link
        :to="{
          name: 'userRoomDetail',
          params: { userId: userId, id: room.id },
        }"
        class="text-reset nav-link p-0"
        v-for="(room, index) in rooms"
        :key="room.id"
      >
        <RoomItem
          :room="room"
          :active="activeRoomId === room.id"
          :isLast="index === rooms.length - 1"
        />
      </router-link>
    </nav>

    <loader
      :isLoading="isLoadingMoreRooms"
      :onComplete="addNextRooms"
      :size="25"
      key="pagination"
    />
  </loader>
</template>

<script>
import RoomItem from '@/components/rooms/list/list-item';
import PaginatedRoomsMixin from '@/mixins/paginated-rooms';

export default {
  name: 'PaginatedUserRooms',
  components: { RoomItem },
  mixins: [PaginatedRoomsMixin],
  props: {
    userId: {
      type: String,
      default: undefined,
    },
    activeRoomId: {
      type: String,
      default: undefined,
    },
    statusId: {
      type: String,
      default: 'open',
    },
  },
  methods: {
    loadRooms(from, excludeRoomId) {
      return this.services.room.loadAllByUserByDate(
        this.userId,
        this.statusId,
        from,
        excludeRoomId ? [excludeRoomId] : undefined
      );
    },
  },
  watch: {
    statusId: {
      immediate: true,
      handler() {
        this.loadInitialRooms();
      },
    },
  },
};
</script>
