import merge from 'deepmerge';
import * as API from '@/shared/api';
import Fuse from 'fuse.js';
import { isArraySubset, getKidAgeInMonths } from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';

const buildDefaultFilters = () => ({
  gender: 'ALL',
  minAge: 0,
  maxAge: 216,
  agentGroupIds: ['ALL'],
});

export const build = attributes => {
  return merge(
    {
      name: '',
      shortLabel: '',
      mobileLabel: '',
      molecules: [],
      filters: buildDefaultFilters(),
    },
    attributes || {}
  );
};

export const isValid = attributes => {
  return (
    !!attributes.name &&
    attributes.name.length > 0 &&
    !!attributes.molecules &&
    attributes.molecules.length > 0
  );
};

export const sortDrugs = brandedDrug => {
  if (!brandedDrug?.drugs) return brandedDrug;
  brandedDrug.drugs = sortedDrugs(brandedDrug);
  return brandedDrug;
};

export const sortedDrugs = brandedDrug => {
  return (brandedDrug.drugs || []).sort((d1, d2) =>
    d1.shortLabel.localeCompare(d2.shortLabel)
  );
};

export const findAll = async (context, withTracking) => {
  if (withTracking) trackAgent('VIEW', 'BRANDED-DRUGS');
  return API.findAllBrandedDrugs().then(({ brandedDrugs }) => {
    return context ? filter(brandedDrugs, context) : brandedDrugs;
  });
};

export const find = (id, withTracking) => {
  if (withTracking) trackAgent('VIEW', 'BRANDED-DRUG', id);
  return API.findBrandedDrug(id).then(({ brandedDrug }) => brandedDrug);
};

export const acceptBrandedDrug = (
  brandedDrug,
  { kidAge, kidGender, currentAgentGroupIds }
) => {
  const {
    filters: { gender, minAge, maxAge, agentGroupIds },
  } = brandedDrug;

  return (
    (!kidGender || gender === 'ALL' || gender === kidGender) &&
    minAge <= kidAge &&
    maxAge >= kidAge &&
    (!agentGroupIds ||
      agentGroupIds.includes('ALL') ||
      isArraySubset(currentAgentGroupIds, agentGroupIds))
  );
};

export const filter = (list, { kid, agentGroupIds }) => {
  const kidAge = getKidAgeInMonths(kid.birthDate);
  const kidGender = kid.gender === 0 ? 'GIRL' : 'BOY';

  return list.filter(brandedDrug =>
    acceptBrandedDrug(brandedDrug, {
      kidAge,
      kidGender,
      currentAgentGroupIds: agentGroupIds,
    })
  );
};

export const search = (list, query) => {
  if (!query || query.length === 0) return list;
  const fuse = new Fuse(list, { keys: ['name', 'molecules.name'] });
  return fuse.search(query).map(result => ({ ...result.item }));
};

export const create = async ({ molecules, ...attributes }) => {
  trackAgent('CREATE', 'BRANDED-DRUG', attributes.name);
  return API.createBrandedDrug({
    moleculeIds: molecules.map(molecule => molecule.id),
    ...attributes,
  }).then(({ brandedDrug }) => brandedDrug);
};

export const update = async ({ id, molecules, ...attributes }) => {
  trackAgent('UPDATE', 'BRANDED-DRUG', id);
  return API.updateBrandedDrug(id, {
    id,
    moleculeIds: molecules.map(molecule => molecule.id),
    ...attributes,
  });
};
