<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-9">
                <h2 class="font-bold mb-0">
                  <font-awesome-icon :icon="topic.icon" />
                  {{
                    $t(`admin.medicalReportLibrary.list.topics.${topic.name}`)
                  }}
                  <span>/</span>
                  {{ $t('admin.medicalReportLibrary.edit.title') }}
                </h2>
              </div>
              <div class="col-3 text-right">
                <ul class="nav justify-content-end">
                  <li
                    class="nav-item list-inline-item"
                    v-if="
                      $p(
                        'medicalReportLibrary',
                        { ...libraryItem, hasChildren },
                        'destroy'
                      )
                    "
                  >
                    <destroy-button
                      :text="
                        $t(
                          'admin.medicalReportLibrary.edit.actions.destroy.text'
                        )
                      "
                      :buttonLabel="
                        $t(
                          'admin.medicalReportLibrary.edit.actions.destroy.button'
                        )
                      "
                      @destroy="onDestroy"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <library-item-form
            :topic="topic"
            :libraryItem="libraryItem"
            :persist="onPersist"
            @change="onChange"
            v-if="libraryItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import LibraryItemForm from './form';

export default {
  name: 'EditMedicalReportHistoryItem',
  mixins: [MedicalReportLibraryMixin],
  components: { LibraryItemForm },
  props: ['libraryItemId'],
  data() {
    return {
      libraryItem: null,
      hasChildren: true,
    };
  },
  methods: {
    onChange(changes) {
      this.libraryItem = this.services.medicalReportLibrary.applyChanges(
        this.libraryItem,
        { id: this.libraryItemId, ...changes }
      );
    },
    onPersist() {
      return this.services.medicalReportLibrary.update(
        this.topic.name,
        this.libraryItem
      );
    },
    onDestroy() {
      this.services.medicalReportLibrary
        .destroy(this.topic.name, this.libraryItem)
        .then(() =>
          this.$router.push({
            name: 'medicalReportLibraryTopic',
            params: { topicName: this.topic.name },
          })
        );
    },
  },
  watch: {
    libraryItemId: {
      immediate: true,
      handler(libraryItemId) {
        this.$bind(
          'libraryItem',
          this.services.medicalReportLibrary.find(this.topicName, libraryItemId)
        );
        this.services.medicalReportLibrary
          .hasChildren(this.topicName, libraryItemId)
          .then(hasChildren => {
            this.hasChildren = hasChildren;
          });
      },
    },
  },
};
</script>
