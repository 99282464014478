import Vue from 'vue';

import ModelForm from './model-form';
import NestedModelForm from './nested-model-form';
import ArrayItemNestedModelForm from './array-item-nested-model-form';
import RichTextInput from './rich-text-input';
import SearchInput from './search-input';
import TextInput from './text-input';
import TextareaInput from './textarea-input';
import GenderInput from './gender-input';
import CheckboxInput from './checkbox-input';
import RatingInput from './rating-input';
import SelectInput from './select-input';
import AgeRangeInput from './age-range-input';
import AgentGroupInput from './agent-group-input';

Vue.component('rich-text-input', RichTextInput);
Vue.component('model-form', ModelForm);
Vue.component('nested-model-form', NestedModelForm);
Vue.component('array-item-nested-model-form', ArrayItemNestedModelForm);
Vue.component('text-input', TextInput);
Vue.component('textarea-input', TextareaInput);
Vue.component('search-input', SearchInput);
Vue.component('gender-input', GenderInput);
Vue.component('checkbox-input', CheckboxInput);
Vue.component('rating-input', RatingInput);
Vue.component('select-input', SelectInput);
Vue.component('age-range-input', AgeRangeInput);
Vue.component('agent-group-input', AgentGroupInput);
