<template>
  <div>
    <h5
      class="h6 text-gray-700 font-weight-normal mb-4 d-flex align-items-center"
    >
      <span>{{ title }}</span>
      <a
        class="ml-4 text-gray-700"
        type="button"
        @click="openPreviewModal"
        v-tooltip="$t(`${i18nScope}.previewTooltip`)"
      >
        <font-awesome-icon :icon="['fal', 'file-alt']" size="lg" />
      </a>
    </h5>
    <list-item
      v-for="(item, index) in list"
      :item="item"
      :key="`prescription-item-${index}`"
    />
    <prescription-preview-modal :previewUrl="previewUrl" />
  </div>
</template>

<script>
import ListItem from './list-item';
import PrescriptionPreviewModal from './preview-modal';

export default {
  name: 'PrescriptionBoardList',
  components: { ListItem, PrescriptionPreviewModal },
  props: {
    title: { type: String, default: 'My List' },
    list: { type: Array, default: () => [] },
    previewUrl: { type: String, default: null },
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.show.list';
    },
  },
  methods: {
    openPreviewModal() {
      this.$modal.show(`prescription-preview-modal`);
    },
  },
};
</script>
