<template>
  <div class="pt-2 pb-4 px-5">
    <div class="card drop-shadow">
      <loader :isLoading="!board" :waitForLoop="false" style="min-height: 5rem">
        <new-board
          :sampleBoard="board"
          :room="room"
          @refresh="fetch"
          v-on="$listeners"
          v-if="isNew"
        />
        <show-board :room="room" :board="board" v-on="$listeners" v-else />
      </loader>
    </div>
  </div>
</template>

<script>
import NewBoard from './new';
import ShowBoard from './show';

export default {
  name: 'PrescriptionBoardWrapper',
  components: { NewBoard, ShowBoard },
  props: {
    room: { type: Object },
  },
  data() {
    return { board: null };
  },
  computed: {
    isNew() {
      return this.board && this.board.prescriptions.length === 0;
    },
  },
  methods: {
    async fetch() {
      const service = this.services.prescription;
      this.board = null; // force the loader to spin

      const prescriptions = await service.findAllByRoomId(this.room.id);

      if (prescriptions && prescriptions.length > 0) {
        this.board = service.buildExistingBoard(prescriptions);
      } else {
        this.board = await service.buildBoard(
          this.currentAgentWithGroups,
          this.room,
          this.supervisorAgentId
        );
      }
    },
  },
  watch: {
    room: {
      immediate: true,
      async handler(newRoom, oldRoom) {
        if (!newRoom || (oldRoom && oldRoom.id === newRoom.id)) return;
        this.fetch();
      },
    },
  },
};
</script>
