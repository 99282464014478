<template>
  <div class="modal-content">
    <div class="modal-header border-0 pt-7 pb-4">
      <div class="modal-title w-100 d-flex align-items-center">
        <h4 class="font-weight-bold mb-0">
          {{ title }}
        </h4>

        <slot name="switch"></slot>

        <div class="ml-auto d-flex align-items-center">
          <div class="text-right">
            <strong class="d-block h3 text-dark mb-0"
              >{{ kid.weight | formatNumber }} kg</strong
            >
            <small class="d-block mt-n2">{{ kidAge }}</small>
          </div>
          <button
            class="btn btn-xl btn-link text-muted pr-0"
            @click="$emit('close')"
          >
            <font-awesome-icon :icon="['fal', 'times']" size="lg" />
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body pt-0 pb-7">
      <breadcrumbs
        :prescriptionItem="prescriptionItem"
        v-if="prescriptionItem"
        v-on="$listeners"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { kidAgeFromBirthday } from '@/shared/utils';
import Breadcrumbs from './breadcrumbs';

export default {
  name: 'DosagePickerStepLayout',
  components: { Breadcrumbs },
  props: {
    title: { type: String, default: 'Title' },
    kid: { type: Object, required: true, default: null },
    prescriptionItem: { type: Object, required: false },
  },
  computed: {
    kidAge() {
      return kidAgeFromBirthday(
        this.kid.birthDate,
        this.kid.gender,
        this.relativeDate
      );
    },
  },
};
</script>
