import Welcome from '@/components/welcome';
import Navbar from '@/components/admin/navbar';

import SavedReplyRoutes from './saved-replies';
import AgentGroupRoutes from './agent-groups';
import MedicalReportLibraryRoutes from './medical-report-library';
import MoleculesRoutes from './molecules';
import BrandedDrugsRoutes from './branded-drugs';

export default [
  {
    path: '/settings',
    name: 'admin',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace-new" */ '@/views/workspace.vue'
      ),
    props: true,
    redirect: 'settings/all',
    children: [
      {
        path: 'all',
        name: 'adminHome',
        components: {
          default: Welcome,
          sidebar: Navbar,
        },
      },
      ...SavedReplyRoutes,
      ...AgentGroupRoutes,
      ...MedicalReportLibraryRoutes,
      ...MoleculesRoutes,
      ...BrandedDrugsRoutes,
    ],
    meta: {
      protected: true,
      policy: { scope: 'admin', action: 'show' },
    },
  },
];
