<template>
  <div class="card-body py-4 px-5">
    <div class="cart-title d-flex align-items-center">
      <h4 class="font-weight-bold mb-0">
        {{ $t(`${i18nScope}.title`) }}
      </h4>
      <div
        class="ml-2 transition-colors"
        :class="{
          'text-gray-100': !hasBeenModified,
          'text-dark': hasBeenModified,
        }"
      >
        *
      </div>
      <div class="ml-8" v-if="board">
        <div class="input-group input-group--unit">
          <input
            type="text"
            class="form-control"
            :disabled="hasPrescriptionItems"
            v-model="kidWeightInput"
            ref="kidWeightInput"
          />
          <div class="input-group-append">kg</div>
        </div>
      </div>
      <div class="ml-3" v-if="!hasKidLastName">
        <div class="form-group form-group--simple">
          <input
            type="text"
            class="form-control"
            style="width: 14rem"
            :disabled="hasPrescriptionItems"
            :placeholder="$t(`${i18nScope}.kidLastNamePlaceholder`)"
            v-model="kidLastNameInput"
          />
        </div>
      </div>
      <div class="ml-auto">
        <button
          class="btn btn-xl btn-link text-muted pr-0"
          @click="$emit('close')"
        >
          <font-awesome-icon :icon="['fal', 'times']" size="lg" />
        </button>
      </div>
    </div>

    <div class="row pb-6">
      <div class="col">
        <prescription-item-list-input
          :title="$tc(`${i18nScope}.treatments`, board.treatmentItems.length)"
          :kid="kid"
          v-model="treatmentItemsInput"
          :class="{ 'mt-2': hasTreatmentItems }"
        />

        <div class="mt-6" v-if="hasPrescriptionItems">
          <textarea
            class="form-control"
            v-model="instructionsInput"
            :placeholder="$t(`${i18nScope}.instructionsPlaceholder`)"
          />
        </div>

        <div
          class="d-flex align-items-center justify-content-between"
          :class="{
            'mt-4': !hasPrescriptionItems,
            'mt-6': hasPrescriptionItems,
          }"
        >
          <div>
            <button
              class="btn btn-outline-primary btn-md"
              :class="{
                'btn-outline-primary': canAdd,
                'btn-outline-light': !canAdd,
              }"
              :disabled="!canAdd"
              @click="openDosagePicker"
            >
              <font-awesome-icon :icon="['fal', 'plus']" size="lg" />
              <span class="ml-3" v-if="!hasPrescriptionItems">{{
                $t(`${i18nScope}.addPrescriptionItem`)
              }}</span>
            </button>
          </div>

          <div class="d-flex align-items-center">
            <doctor-picker
              v-model="doctorIdInput"
              v-if="$p('prescriptionBoard', 'requireDoctorPicker')"
            />

            <button
              type="button"
              class="btn btn-primary btn-md ml-8"
              @click="openPrescriptionPreview"
              v-if="hasPrescriptionItems"
            >
              {{ $t(`${i18nScope}.submitButton`) }}
            </button>
            <prescription-confirmation-modal :board="board" v-on="$listeners" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrescriptionItemListInput from './list-input';
import PrescriptionConfirmationModal from './confirmation-modal';
import DoctorPicker from './doctor-picker';
import { mapPropsModels, debounce } from '@/shared/utils';

export default {
  name: 'NewPrescriptionBoard',
  components: {
    PrescriptionConfirmationModal,
    PrescriptionItemListInput,
    DoctorPicker,
  },
  props: {
    room: { type: Object, required: true },
    sampleBoard: { type: Object, required: true },
  },
  data() {
    return { board: null, hasBeenModified: false };
  },
  created() {
    this.persistBoardDraft = debounce(async board => {
      await this.services.prescription.persistBoardDraft(board);
      this.hasBeenModified = false;
    }, 1000);
  },
  mounted() {
    if (this.$refs.kidWeightInput) this.$refs.kidWeightInput.focus();
  },
  computed: {
    ...mapPropsModels(
      [
        'kidWeight',
        'kidLastName',
        'instructions',
        'doctorId',
        'treatmentItems',
      ],
      {
        object: 'board',
      }
    ),
    i18nScope() {
      return 'inbox.room.prescriptionBoard.new';
    },
    isValid() {
      return this.services.prescription.isBoardValid(this.board, this.room);
    },
    kid() {
      return { ...this.room.kid, weight: this.board.kidWeight };
    },
    hasKidLastName() {
      return !this.isBlank(this.room.kid?.lastName);
    },
    canAdd() {
      return (
        !this.isBlank(this.board?.doctorId) &&
        !this.isBlank(this.board?.kidWeight) &&
        parseFloat(this.board?.kidWeight.replace(',', '.')) > 0 &&
        (this.hasKidLastName || !this.isBlank(this.board.kidLastName))
      );
    },
    hasPrescriptionItems() {
      // NOTE: we only deal with treatment prescription items for now
      return this.hasTreatmentItems;
    },
    hasTreatmentItems() {
      return !this.isBlank(this.board?.treatmentItems);
    },
  },
  methods: {
    openDosagePicker() {
      this.$modal.show(`dosage-picker`);
    },
    openPrescriptionPreview() {
      this.$modal.show(`prescription-confirmation-modal`);
    },
  },
  watch: {
    sampleBoard: {
      immediate: true,
      handler(newSampleBoard) {
        this.board = newSampleBoard;
      },
    },
    board() {
      this.hasBeenModified = true;
      this.persistBoardDraft(this.board);
    },
  },
};
</script>
