<template>
  <div class="tab-pane fade h-100 show active">
    <div class="d-flex flex-column h-100">
      <div class="hide-scrollbar h-100">
        <div
          class="alert alert-warning rounded-0"
          role="alert"
          v-if="agent && agent.isDisabled"
        >
          {{ $t('agents.isDisabledWargin') }}
        </div>

        <div class="sidebar-header container-fluid">
          <div class="d-flex align-items-center" v-if="!isLoading">
            <avatar :user="agent" :online="isOnline" size="lg" />
            <div class="ml-5">
              <h2 class="font-bold mb-0" v-if="agent">
                {{
                  agent
                    | agentFullName(null, isNew ? $t('agents.form.new') : '')
                }}
              </h2>
              <h2 class="font-bold mb-0" v-else>
                {{ $t('agents.agentNotFound') }}
              </h2>
              <div class="text-muted">
                {{ agent | agentTitle }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="alert alert-warning mt-6 mx-6"
          role="alert"
          v-if="invalidForm"
        >
          {{ $t('agents.form.invalid') }}
        </div>

        <form class="mt-6 mx-6" v-if="agent" v-on:submit.prevent="submitForm">
          <div class="form-group">
            <label for="firstName">{{ $t('agents.form.firstName') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.firstName"
              id="firstName"
            />
          </div>

          <div class="form-group">
            <label for="lastName">{{ $t('agents.form.lastName') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.lastName"
              id="lastName"
            />
          </div>

          <div class="form-group">
            <label for="gender">{{ $t('agents.form.gender') }}</label>
            <select class="form-control" id="gender" v-model="agent.gender">
              <option value="female">{{ $t('genders.female') }}</option>
              <option value="male">{{ $t('genders.male') }}</option>
            </select>
          </div>

          <div class="form-group" v-if="agent.gender">
            <label for="titleKey">{{ $t('agents.form.titleKey') }}</label>
            <select class="form-control" id="titleKey" v-model="agent.titleKey">
              <option value=""></option>
              <option
                v-for="titleKey in titleList"
                :key="titleKey"
                :value="titleKey"
              >
                {{ $t(`jobTitles.${titleKey}.${agent.gender}`) }}
              </option>
            </select>
          </div>

          <div class="form-group" v-if="!agent.titleKey">
            <label for="title">{{ $t('agents.form.title') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.title"
              id="title"
            />
          </div>

          <div class="form-group">
            <label for="email">{{ $t('agents.form.email') }}</label>
            <input
              class="form-control"
              type="email"
              v-model="agent.email"
              id="email"
              autocomplete="off"
            />
          </div>

          <div class="form-group">
            <label for="slack-id">{{ $t('agents.form.slackID') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.slackID"
              id="slack-id"
              autocomplete="off"
            />
          </div>

          <div class="form-group">
            <label>{{ $t('agents.form.profilePic') }}</label>
            <div class="position-relative text-center bg-secondary rounded p-6">
              <div class="avatar bg-primary text-white mb-5">
                <span>
                  <font-awesome-icon
                    :icon="['fal', 'camera']"
                    class="icon-md"
                  />
                </span>
              </div>

              <p class="small text-muted mb-0">
                {{ $t('agents.form.profilePicInstructions') }}
              </p>

              <p class="small text-muted mt-2" v-if="pictureFile">
                <u
                  >{{ $t('agents.form.profilePicSet') }}
                  {{ pictureFile.name }}</u
                >
              </p>

              <input
                id="upload-user-photo"
                class="d-none"
                type="file"
                ref="file"
                accept="image/*"
                @change="changeProfilePicture"
              />
              <label
                class="stretched-label mb-0"
                for="upload-user-photo"
              ></label>
            </div>
          </div>

          <div class="form-group" v-if="agent.bio">
            <label for="bio-fr">{{ $t('agents.form.bio.fr') }}</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="agent.bio.fr"
              id="bio-fr"
            />
          </div>

          <div class="form-group" v-if="agent.bio">
            <label for="bio-en">{{ $t('agents.form.bio.en') }}</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="agent.bio.en"
              id="bio-en"
            />
          </div>

          <div class="form-group" v-if="agent.bioRD">
            <label for="bio-fr">{{ $t('agents.form.bioRD.fr') }}</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="agent.bioRD.fr"
              id="bio-fr"
            />
          </div>

          <div class="form-group" v-if="agent.bioRD">
            <label for="bio-en">{{ $t('agents.form.bioRD.en') }}</label>
            <textarea
              class="form-control"
              rows="5"
              v-model="agent.bioRD.en"
              id="bio-en"
            />
          </div>

          <div class="form-group">
            <label for="pro-address">{{ $t('agents.form.proAddress') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.professionalAddress"
              id="pro-address"
            />
          </div>

          <div class="form-group">
            <label for="pro-phone-number">{{
              $t('agents.form.proPhoneNumber')
            }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.professionalPhone"
              id="pro-phone-number"
            />
          </div>

          <div class="form-group">
            <label for="rpps">{{ $t('agents.form.rpps') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.rpps"
              id="rpps"
            />
          </div>

          <div class="form-group">
            <label for="adeli">{{ $t('agents.form.adeli') }}</label>
            <input
              class="form-control"
              type="text"
              v-model="agent.adeli"
              id="adeli"
            />
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="isAdmin">{{ $t('agents.form.admin') }}</label>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isAdmin"
                  v-model="agent.admin"
                />
                <label class="custom-control-label" for="isAdmin"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="isDisabled">{{ $t('agents.form.isDisabled') }}</label>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isDisabled"
                  v-model="agent.isDisabled"
                />
                <label class="custom-control-label" for="isDisabled"></label>
              </div>
            </div>
          </div>

          <div class="mt-8">
            <submit-button
              :labels="
                $t(
                  isNew
                    ? 'agents.form.submit.create'
                    : 'agents.form.submit.update'
                )
              "
              :buttonState="buttonState"
              miscButtonClass="btn-block"
            />
            <p class="text-center">
              <router-link :to="backTo" class="btn btn-link">
                <font-awesome-icon
                  :icon="['fal', 'long-arrow-left']"
                  class="icon-md"
                />
                {{
                  $t(
                    isNew
                      ? 'agents.form.backButton.create'
                      : 'agents.form.backButton.update'
                  )
                }}
              </router-link>
            </p>
          </div>
        </form>
      </div>
    </div>

    <SuccessModal :response="successResponse" />
  </div>
</template>

<script>
import SuccessModal from './success_modal';

export default {
  name: 'AgentForm',
  components: { SuccessModal },
  props: ['agentId'],
  data() {
    return {
      isLoading: true,
      agent: null,
      pictureFile: null,
      invalidForm: false,
      buttonState: 'default',
      successResponse: null,
    };
  },
  computed: {
    titleList() {
      return Object.keys(this.$i18n.t('jobTitles'));
    },
    isOnline() {
      return this.agent.online ? true : undefined;
    },
    isNew() {
      return this.agentId === 'new';
    },
    backTo() {
      return this.isNew
        ? {
            name: 'agents',
          }
        : {
            name: 'agent',
            params: { agentId: this.agentId },
          };
    },
  },
  methods: {
    changeProfilePicture() {
      this.pictureFile = this.$refs.file.files[0];
    },
    submitForm() {
      if (this.services.agent.isValid(this.agent)) {
        this.buttonState = 'inProgress';
        this.invalidForm = false;
        if (this.agentId === 'new') this.create();
        else this.update();
      } else {
        this.invalidForm = true;
      }
    },
    create() {
      this.services.agent
        .create(this.agent, this.pictureFile)
        .then(response => {
          this.$router.push({
            name: 'agentProfile',
            params: { agentId: response.agentID },
          });
          this.buttonState = 'success';
          this.successResponse = response; // trigger the modal
        })
        .catch(() => (this.buttonState = 'fail'));
    },
    update() {
      this.services.agent
        .update(this.agentId, this.agent, this.pictureFile)
        .then(() => {
          this.buttonState = 'success';
          this.pictureFile = null;
        })
        .catch(() => (this.buttonState = 'fail'));
    },
  },
  watch: {
    agentId: {
      immediate: true,
      handler(agentId) {
        this.isLoading = true;
        if (agentId === 'new') {
          this.agent = this.services.agent.build();
          this.isLoading = false;
        } else {
          this.$bind('agent', this.services.agent.find(agentId)).then(() => {
            this.isLoading = false;
            // NOTE: for agents without any bio and bioRD fields (legacy)
            if (!this.agent.bio) this.agent.bio = { fr: '', en: '' };
            if (!this.agent.bioRD) this.agent.bioRD = { fr: '', en: '' };
          });
        }
        this.pictureFile = null;
      },
    },
  },
};
</script>
