<template>
  <div class="main main-visible">
    <div id="chat-1" class="chat">
      <!-- Chat: body -->
      <div class="chat-body">
        <!-- Chat: Header -->
        <room-header
          :room="room"
          :showSidebar="showSidebar"
          @open-sidebar="openSidebar"
        />
        <!-- Chat: Header -->

        <!-- Chat: Content-->
        <room-messages
          :roomId="id"
          :room="room"
          :status="room.status.type"
          v-if="room"
        />

        <room-typing-status :room="room" />

        <transition name="chat-footer" mode="out-in" v-if="room">
          <div
            key="chatForm"
            v-if="display === 'chatForm'"
            class="chat-footer border-top py-5"
          >
            <div class="container-fluid" v-if="room">
              <chat-form
                :room="room"
                @open-medical-report="openMedicalReport"
                @open-prescription-board="openPrescriptionBoard"
                v-if="$p('room', room, 'typeMessage') && !isRoomClosed"
                class="mt-n4"
              />

              <room-closed-status
                :room="room"
                class="justify-content-center"
                @open-medical-report="openMedicalReport"
                @open-prescription-board="openPrescriptionBoard"
                v-else-if="isRoomClosed"
              />

              <room-open-status :room="room" v-else />
            </div>
          </div>

          <prescription-board
            key="prescriptionBoard"
            v-if="display === 'prescriptionBoard'"
            :room="room"
            @open-medical-report="openMedicalReport"
            @close="showChatForm"
          />

          <medical-report
            key="medicalReport"
            v-if="display === 'medicalReport'"
            :room="room"
            @open-prescription-board="openPrescriptionBoard"
            @close="showChatForm"
          />
        </transition>
        <!-- Chat: Footer -->
      </div>
      <!-- Chat: body -->
    </div>

    <transition name="sidebar-slide">
      <sidebar :room="room" v-if="room && showSidebar" @close="closeSidebar" />
    </transition>
  </div>
</template>

<script>
import RoomHeader from '@/components/rooms/show/header';
import RoomMessages from '@/components/messages/list';
import ChatForm from '@/components/messages/form';
import Sidebar from '@/components/rooms/show/sidebar';
import MedicalReport from '@/components/rooms/show/medical-report';
import PrescriptionBoard from '@/components/rooms/show/prescription-board';
import RoomTypingStatus from '@/components/rooms/show/status/typing';
import RoomOpenStatus from '@/components/rooms/show/status/open';
import RoomClosedStatus from '@/components/rooms/show/status/closed';

export default {
  name: 'RoomDetail',
  components: {
    RoomHeader,
    Sidebar,
    RoomMessages,
    ChatForm,
    MedicalReport,
    PrescriptionBoard,
    RoomTypingStatus,
    RoomOpenStatus,
    RoomClosedStatus,
  },
  props: ['id'],
  data() {
    return {
      room: null,
      display: 'chartForm',
      showSidebar: false,
    };
  },
  computed: {
    isRoomClosed() {
      return this.room && this.room.status.type === 'closed';
    },
  },
  methods: {
    openSidebar() {
      this.showSidebar = true;
    },
    closeSidebar() {
      this.showSidebar = false;
    },
    showChatForm() {
      this.display = 'chatForm';
    },
    openMedicalReport() {
      this.display = 'medicalReport';
    },
    openPrescriptionBoard() {
      this.display = 'prescriptionBoard';
    },
    markAsRead() {
      if (
        this.room &&
        !this.room.haveAgentsRead?.includes(this.currentAgent.id)
      ) {
        this.services.room.markAsRead(this.room, this.currentAgent);
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newId, oldId) {
        if (newId !== oldId)
          this.$bind('room', this.services.room.find(newId, true));
      },
    },
    room: {
      immediate: true,
      handler(newRoom, oldRoom) {
        if (!newRoom) return;
        if (!this.$policy('room', newRoom, 'show')) {
          this.$router.push({ name: 'home' });
        } else if (!oldRoom || newRoom.id !== oldRoom.id) {
          this.markAsRead();
          this.showSidebar = !!newRoom.kid;
        }
      },
    },
    'room.status'(newStatus, oldStatus) {
      if (!newStatus || !oldStatus) {
        // use case: the agent is visiting another another room
        this.showChatForm(); // DEBUG
        return;
      }
      if (
        this.room.kid &&
        (newStatus.type === 'closed' || newStatus.type === 'snoozed') &&
        oldStatus.type === 'open'
      ) {
        this.openMedicalReport();
      }
    },
  },
};
</script>
