import Vue from 'vue';
import Vuex from 'vuex';
import state from './default-state';
import mutations from './mutations';
import actions from './actions';
import * as getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  mutations,
  actions,
  getters,
  modules: {},
});

// Bind the app version (required if a new deployment has been made)
store.dispatch('bindAppConfigAction');
store.dispatch('bindAppDynConfigAction');

// At startup, don't ask for the email/password if already authenticated
store.dispatch('localSignInAction');

export default store;
