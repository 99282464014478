<template>
  <div class="list-group list-group-flush">
    <div
      v-for="(brandedDrug, index) in list"
      :key="compositeKey(brandedDrug)"
      class="list-group-item"
      :class="{
        'border-top': index === 0,
        'border-left-0 border-right-0 primary-border-right':
          brandedDrug.id === brandedDrugId,
      }"
    >
      <branded-drug
        :brandedDrug="brandedDrug"
        :drugId="drugId"
        :active="brandedDrug.id === brandedDrugId"
      />
    </div>
  </div>
</template>

<script>
import BrandedDrug from './branded-drug';

export default {
  name: 'BrandedDrugList',
  components: { BrandedDrug },
  props: {
    list: { type: Array, default: null },
    brandedDrugId: { type: String, default: null },
    drugId: { type: String, default: null },
  },
  methods: {
    compositeKey(brandedDrug) {
      return this.buildCompositeKey(
        brandedDrug.id,
        (brandedDrug.drugs || []).map(d => [d.id, d.name])
      );
    },
  },
};
</script>
