import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import { FIREBASE_CONFIG } from '@/shared/config';

let app = null;
let db = null;

export const firebaseTestHost = 'localhost:8080';

export const getApp = () => {
  if (!app) {
    app = firebase.initializeApp(FIREBASE_CONFIG);
  }
  return app;
};
export const getDB = () => {
  if (!db) {
    db = getApp().firestore();
    if (process.env.NODE_ENV === 'test') {
      db.settings({ host: firebaseTestHost, ssl: false });
    }
  }
  return db;
};

export const serialize = snapshot => {
  // snapshot.data() DOES NOT contain the `id` of the document. By
  // default, Vuefire adds it as a non enumerable property named id.
  // This allows to easily create copies when updating documents, as using
  // the spread operator won't copy it
  return { id: snapshot.id, ...snapshot.data() };
};

export const buildAgent = agent => {
  if (!agent) return null;

  var _agent = { id: agent.id, ...agent };

  // remove undefined attributes
  Object.keys(_agent).forEach(key =>
    _agent[key] === undefined ? delete _agent[key] : {}
  );

  return _agent;
};

export const authenticateDb = async token => {
  return firebase
    .auth()
    .signInWithCustomToken(token)
    .then(() => {
      setupOnlinePresence();
      return true;
    })
    .catch(error => {
      console.log(
        'DB Auth ERROR',
        'code =',
        error.code,
        ', message = ',
        error.message
      );
      return null;
    });
};

export const signOutFromDb = async () => {
  finishOnlinePresence();
  return firebase.auth().signOut();
};

// Others

export const nowOnServer = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const fromDate = date => {
  return firebase.firestore.Timestamp.fromMillis(date);
};

export const findAppConfig = () => {
  return getDB()
    .collection('configs')
    .doc('desk');
};

export const findDynAppConfig = () => {
  return getDB()
    .collection('configs')
    .doc('dynamic');
};

// NOTE: https://cloud.google.com/firestore/docs/solutions/presence
export const setupOnlinePresence = () => {
  var uid = firebase.auth().currentUser.uid;

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  var isOfflineForDatabase = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
    state: 'online',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };

  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function(snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function() {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });

  // Updating Cloud Firestore's local cache
  let userStatusFirestoreRef = firebase.firestore().doc('/agents/' + uid);

  // Firestore uses a different server timestamp value, so we'll
  // create two more constants for Firestore state.
  let isOfflineForFirestore = {
    online: false,
    onlineLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  };

  let isOnlineForFirestore = {
    online: true,
    onlineLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  };

  firebase
    .database()
    .ref('.info/connected')
    .on('value', function(snapshot) {
      if (snapshot.val() == false) {
        // Instead of simply returning, we'll also set Firestore's state
        // to 'offline'. This ensures that our Firestore cache is aware
        // of the switch to 'offline.'
        userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
        return;
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function() {
          userStatusDatabaseRef.set(isOnlineForDatabase);

          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
        });
    });
};

export const finishOnlinePresence = () => {
  // realtime database
  var uid = firebase.auth().currentUser.uid;
  var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
  var isOfflineForDatabase = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };
  userStatusDatabaseRef.set(isOfflineForDatabase);

  // firestore
  let userStatusFirestoreRef = firebase.firestore().doc('/agents/' + uid);
  let isOfflineForFirestore = {
    online: false,
    onlineLastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  };
  userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
};
