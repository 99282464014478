var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('step-layout',_vm._g({attrs:{"title":_vm.$t((_vm.i18nScope + ".title")),"kid":_vm.kid,"prescriptionItem":_vm.prescriptionItem},scopedSlots:_vm._u([{key:"switch",fn:function(){return [_c('custom-switch',{staticClass:"mt-1 ml-8",model:{value:(_vm.isCustom),callback:function ($$v) {_vm.isCustom=$$v},expression:"isCustom"}})]},proxy:true}])},_vm.$listeners),[_c('transition',{attrs:{"name":"chat-footer","mode":"out-in"}},[(_vm.isCustom)?_c('div',{key:"custom",staticClass:"pt-8 px-3"},[_c('custom-dosage-form',_vm._g({},_vm.$listeners))],1):_c('loader',{staticClass:"pt-4",staticStyle:{"min-height":"18rem"},attrs:{"isLoading":!_vm.drugs}},[(_vm.drugs)?_c('step-list',{attrs:{"list":_vm.dosages},on:{"select":function (ref) {
	var drug = ref.drug;
	var dosage = ref.dosage;

	return _vm.$emit('change', { drug: drug, dosage: dosage });
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_item = ref.item;
var drug = ref_item.drug;
var dosage = ref_item.dosage;
return [_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('span',[_vm._v(_vm._s(drug.shortLabel))]),_c('small',[_vm._v(_vm._s(dosage.shortLabel))])])]}}],null,false,3097538333)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }