import { SYSTEM_ADMINISTRATORS, BETA_TESTERS } from '@/services/agent-group';

export default {
  isSignedIn() {
    return !!this.user;
  },
  isAdmin() {
    return this.isSimpleMemberOf(SYSTEM_ADMINISTRATORS);
  },
  isAgent() {
    return !this.isAdmin();
  },
  agentId() {
    return this.user?.id;
  },
  isBetaTester() {
    return this.isMemberOf(BETA_TESTERS);
  },
  isMemberOf(groupName, agent) {
    let group = this.getGroupByName(groupName);
    if (!group) return false;
    if (group.addSystemAdministrators && this.isAdmin()) {
      return true;
    } else return this.isSimpleMemberOf(groupName, agent);
  },
  isSimpleMemberOf(groupName, agent) {
    let group = this.getGroupByName(groupName);
    return (group?.agentIds || []).indexOf((agent || this.user)?.id) !== -1;
  },
  getGroupByName(name) {
    return (this.groups || []).find(group => group.name === name);
  },
};
