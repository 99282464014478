import version from '@/assets/version.json';

export const API_URL = process.env.VUE_APP_API_URL;
export const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
export const HONEYBADGER_API_KEY = process.env.VUE_APP_HONEYBADGER_API_KEY;
export const APP_REVISION = version;
export const IS_PRODUCTION = process.env.VUE_APP_PRODUCTION === '1';

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebaseConfig.measurementId) delete firebaseConfig.measurementId;

export const FIREBASE_CONFIG = firebaseConfig;
