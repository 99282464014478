import * as AgentGroupService from './agent-group';
import * as AgentService from './agent';
import * as AgentTrackingService from './agent-tracking';
import * as AuthenticationService from './authentication';
import * as BrandedDrugService from './branded-drug';
import * as DrugService from './drug';
import * as KidService from './kid';
import * as MedicalReportLibraryService from './medical-report-library';
import * as MedicalReportService from './medical-report';
import * as MessageService from './message';
import * as MoleculeService from './molecule';
import * as MyProfileService from './my-profile';
import * as PrescriptionService from './prescription';
import * as RoomService from './room';
import * as SavedReplyService from './saved-reply';
import * as UserService from './user';

export default {
  agentGroup: AgentGroupService,
  agent: AgentService,
  agentTracking: AgentTrackingService,
  authentication: AuthenticationService,
  brandedDrug: BrandedDrugService,
  drug: DrugService,
  kid: KidService,
  medicalReportLibrary: MedicalReportLibraryService,
  medicalReport: MedicalReportService,
  message: MessageService,
  molecule: MoleculeService,
  myProfile: MyProfileService,
  prescription: PrescriptionService,
  room: RoomService,
  savedReply: SavedReplyService,
  user: UserService,
};
