<template>
  <div class="row">
    <div
      :class="{
        col: editable,
        'col-12': !editable,
      }"
    >
      <textarea
        class="form-control mt-0"
        :placeholder="$t('inbox.room.medicalReport.comments.placeholder')"
        v-model="comments"
        v-if="editable"
        rows="4"
      />
      <div v-if="!editable && areCommentsPresent" class="mt-3">
        <figure class="form-control highlight h-100">
          {{ comments }}
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/shared/utils';

export default {
  name: 'MedicalReportComments',
  props: {
    medicalReport: { type: Object },
    editable: { type: Boolean, default: true },
  },
  data() {
    return { comments: null };
  },
  created() {
    this.change = debounce(change => {
      this.$emit('change', change);
    }, 1000);
  },
  computed: {
    areCommentsPresent() {
      return !this.isBlank(this.comments);
    },
  },
  watch: {
    medicalReport: {
      immediate: true,
      async handler() {
        this.comments = this.medicalReport.comments;
      },
    },
    comments(newComments) {
      this.change({ comments: newComments });
    },
  },
};
</script>
