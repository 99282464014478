<template>
  <div>
    <div class="media">
      <router-link
        :to="{
          name: 'editBrandedDrug',
          params: { brandedDrugId: brandedDrug.id },
        }"
        tag="div"
        class="media-body cursor-pointer"
      >
        <h5 class="mb-0">
          {{ brandedDrug.shortLabel }}
        </h5>
        <p>
          <small>{{ moleculeNames }}</small>
        </p>
      </router-link>
      <div class="">
        <button
          class="btn btn-link text-dark cursor-pointer"
          @click="toggleDrugList"
          role="expandBrandedDrug"
        >
          <font-awesome-icon
            :icon="['fal', drugListExpanded ? 'chevron-up' : 'chevron-down']"
          />
        </button>
      </div>
    </div>

    <collapse-transition>
      <drug-list
        :key="drugListKey"
        :brandedDrug="brandedDrug"
        class="mt-4 mx-4"
        v-if="drugListExpanded"
      />
    </collapse-transition>
  </div>
</template>

<script>
import DrugList from './drug-list';

export default {
  name: 'BrandedDrugListItem',
  components: { DrugList },
  props: {
    brandedDrug: { type: Object },
    drugId: { type: String, default: null },
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      drugListExpanded: false,
    };
  },
  mounted() {
    this.drugListExpanded = this.active;
  },
  computed: {
    drugListKey() {
      return this.buildCompositeKey(
        this.brandedDrug.id,
        this.brandedDrug.drugs?.length || 0
      );
    },
    moleculeNames() {
      return this.brandedDrug.molecules
        .map(molecule => molecule.name)
        .join(', ');
    },
  },
  methods: {
    toggleDrugList() {
      this.drugListExpanded = !this.drugListExpanded;
    },
  },
};
</script>
