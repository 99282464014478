<template>
  <div>
    <h5 class="h6 text-gray-700 font-weight-normal" v-if="title">
      {{ title }}
    </h5>

    <div class="d-flex flex-wrap mt-4">
      <div class="w-100" v-if="isEmptyList">
        <p class="text-center my-4">
          {{ $t('inbox.room.prescriptionBoard.dosagePicker.noResults') }}
        </p>
      </div>
      <div
        v-for="(item, index) in list"
        :key="uniqueKey(index)"
        :class="{ 'mb-4 mr-4': item, 'flex-basis-100': !item }"
      >
        <button
          class="btn btn-md d-flex align-items-center btn-outline-dark border-color-gray-500 font-weight-bold px-4 py-3"
          :class="{ 'btn-dark text-white': isActive(item) }"
          type="button"
          @click="$emit('select', item)"
          v-if="item"
        >
          <slot v-bind:item="item"></slot>
          <span class="ml-5" v-if="showChevron">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { parameterize } from '@/shared/utils';

export default {
  name: 'DosagePickerList',
  props: {
    title: { type: String, required: false, default: null },
    list: { type: Array, default: () => [] },
    currentItem: { type: String, default: null, required: false },
    isCurrent: { type: Function, default: null, required: false },
    showChevron: { type: Boolean, default: true },
  },
  computed: {
    isEmptyList() {
      return this.list && this.list.length === 0;
    },
  },
  methods: {
    uniqueKey(index) {
      return `${parameterize(this.title || 'scoped')}-item-${index}`;
    },
    isActive(item) {
      if (this.isCurrent) {
        return this.isCurrent(item);
      } else {
        return this.currentItem === item;
      }
    },
  },
};
</script>
