<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.molecules.new.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <molecule-form
            :molecule="molecule"
            :persist="onPersist"
            @change="onChange"
            v-if="molecule"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MoleculeForm from './form';

export default {
  name: 'NewMolecule',
  components: { MoleculeForm },
  data() {
    return { molecule: null };
  },
  mounted() {
    this.molecule = this.services.molecule.build();
  },
  methods: {
    ...mapActions(['createMolecule']),
    onChange(changes) {
      this.molecule = merge(this.molecule, changes);
    },
    onPersist() {
      return this.createMolecule(this.molecule).then(molecule => {
        this.$router.push({
          name: 'editMolecule',
          params: { moleculeId: molecule.id },
        });
      });
    },
  },
};
</script>
