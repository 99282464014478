<template>
  <button
    class="btn btn-sm btn-outline-primary d-flex align-items-center"
    type="button"
    @click="$emit('click')"
  >
    <span>{{ label }}</span>
    <font-awesome-icon
      :icon="['fal', 'plus']"
      size="lg"
      class="ml-3"
      v-if="isNewRDLetter"
    />
  </button>
</template>

<script>
export default {
  name: 'MedicalReportRDLetterShowButton',
  props: {
    isNewRDLetter: { type: Boolean, default: true },
  },
  computed: {
    label() {
      const key = this.isNewRDLetter ? 'newButton' : 'editButton';
      return this.$i18n.t(`inbox.room.medicalReport.rdLetter.${key}`);
    },
  },
};
</script>
