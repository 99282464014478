<template>
  <modal
    name="prescription-confirmation-modal"
    width="650"
    height="auto"
    scrollable
    @opened="fetchPdfBase64"
    :clickToClose="false"
  >
    <div class="modal-content">
      <div class="modal-header border-0 pt-4 pb-0 px-6">
        <div class="modal-title w-100 d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ $t(`${i18nScope}.title`) }}
          </h4>

          <div class="ml-auto d-flex align-items-center">
            <button
              class="btn btn-xl btn-link text-muted pr-0"
              @click="close()"
            >
              <font-awesome-icon :icon="['fal', 'times']" size="lg" />
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body px-6 pt-0 pb-7">
        <div
          class="alert alert-warning d-flex align-items-center"
          v-if="apiErrorDetected"
        >
          <font-awesome-icon
            :icon="['fad', 'exclamation-triangle']"
            size="lg"
            class="mr-3"
          />
          <span>{{ $t(`${i18nScope}.apiError`) }}</span>
        </div>

        <tabs navClass="rounded-0 pt-0">
          <tab
            :name="$t(`${i18nScope}.tabs.pdf`)"
            :selected="true"
            v-slot="{ isTabActive }"
          >
            <pdf-preview
              :boardPreview="boardPreview"
              :isTabActive="isTabActive"
            />
          </tab>
          <tab :name="$t(`${i18nScope}.tabs.mobile`)">
            <mobile-preview :boardPreview="boardPreview" />
          </tab>
        </tabs>

        <div class="mt-6 text-center alert alert-success" role="alert">
          {{ $t(`${i18nScope}.text`) }}
        </div>

        <div class="mt-6 d-flex align-items-center">
          <button
            type="button"
            class="btn btn-secondary btn-md"
            @click="close()"
          >
            {{ $t(`${i18nScope}.cancelButton`) }}
          </button>

          <submit-button
            type="button"
            class="ml-auto"
            :labels="$t(`${i18nScope}.submitButton`)"
            :dropup="true"
            :buttonState="buttonState"
            @click="submit"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import PdfPreview from './pdf-preview';
import MobilePreview from './mobile-preview';

export default {
  name: 'PrescriptionConfirmationModal',
  components: { PdfPreview, MobilePreview },
  props: {
    board: { type: Object, required: true },
  },
  data() {
    return {
      boardPreview: null,
      buttonState: 'default',
      apiErrorDetected: false,
    };
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.confirmationModal';
    },
  },
  methods: {
    async fetchPdfBase64() {
      this.services.prescription
        .getBoardPreview(this.board)
        .then(data => (this.boardPreview = data))
        .catch(() => (this.apiErrorDetected = true));
    },
    close() {
      this.boardPreview = null;
      this.$modal.hide('prescription-confirmation-modal');
    },
    submit() {
      this.buttonState = 'inProgress';
      this.services.prescription
        .persistBoard(this.board)
        .then(() => this.$emit('refresh'))
        .catch(error => {
          console.log('[Prescription] error, reason:', error);
          this.buttonState = 'fail';
          this.apiErrorDetected = true;
        });
    },
  },
};
</script>
