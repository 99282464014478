<template>
  <div
    class="tab-pane fade h-100 show active"
    id="tab-content-dialogs"
    role="tabpanel"
  >
    <div class="d-flex flex-column h-100">
      <infinite-scrolling
        class="hide-scrollbar h-100"
        :onScroll="loadMoreRooms"
        :isListening="canLoadMoreRooms"
      >
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold">
            <font-awesome-icon :icon="inbox.icon" class="mr-5" />
            {{ inbox.label }}
          </h2>
        </div>

        <div class="container-fluid">
          <div class="mb-6 d-flex align-items-center justify-content-between">
            <status-picker currentStatusId="closed" :inboxName="inboxName" />
            <date-picker v-model="date" />
          </div>
        </div>

        <loader style="min-height: 50vh" :isLoading="isLoading" key="main">
          <div class="container-fluid min-height" v-if="isEmpty">
            <div
              class="d-flex flex-column align-items-center justify-content-center min-height"
            >
              <font-awesome-icon icon="inbox" class="text-light" size="5x" />
              <p class="h5 mt-3 text-gray-700">
                {{ $t('inbox.empty') }}
              </p>
            </div>
          </div>

          <div class="container-fluid px-0 pb-12" v-if="!isEmpty">
            <nav class="nav d-block list-discussions-js">
              <router-link
                :to="{
                  name: 'closedRoomDetail',
                  params: { id: room.id, statusId: 'closed' },
                }"
                class="text-reset nav-link p-0 room"
                v-for="(room, index) in rooms"
                :key="room.id"
                :tabindex="index + 1"
              >
                <RoomItem
                  :room="room"
                  :active="roomId === room.id"
                  :isLast="index === rooms.length - 1"
                  :useTimeago="false"
                />
              </router-link>
            </nav>

            <loader
              :isLoading="isLoadingMoreRooms"
              :onComplete="addNextRooms"
              :size="25"
              key="pagination"
            />
          </div>
        </loader>
      </infinite-scrolling>
    </div>
  </div>
</template>

<script>
import RoomItem from './list/list-item';
import StatusPicker from './list/status-picker';
import DatePicker from './list/date-picker';
import { findInbox } from '@/shared/inboxes';
import PaginatedRoomsMixin from '@/mixins/paginated-rooms';

export default {
  name: 'PaginatedRooms',
  components: { StatusPicker, DatePicker, RoomItem },
  mixins: [PaginatedRoomsMixin],
  props: ['inboxName', 'id'],
  mounted() {
    this.loadInitialRooms();
  },
  computed: {
    inbox() {
      return findInbox(this.inboxName);
    },
    roomId() {
      return this.id;
    },
  },
  methods: {
    loadRooms(from, excludeRoomId) {
      return this.services.room.loadAllByDate(
        this.inboxName,
        'closed',
        this.currentAgent,
        from,
        excludeRoomId ? [excludeRoomId] : undefined
      );
    },
    getDateFromLastRoom(lastRoom) {
      return lastRoom.status.updatedAt.toDate();
    },
  },
  watch: {
    date() {
      this.loadInitialRooms().then(() => {
        // clear the main view if it's displaying a room
        if (this.$route.name === 'closedRoomDetail')
          this.$router.push({
            name: 'closedRooms',
            params: { inboxName: this.inboxName },
          });
      });
    },
  },
};
</script>
