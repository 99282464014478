<template>
  <model-form
    :model="customItem"
    :i18nScope="i18nScope"
    :onSubmit="null"
    @change="onChange"
  >
    <text-input
      attributeName="drug"
      :placeholder="$t(`${i18nScope}.drugPlaceholder`)"
      :max-characters="100"
      withoutLabel
    />

    <textarea-input
      attributeName="dosage"
      :placeholder="$t(`${i18nScope}.dosagePlaceholder`)"
      :max-characters="300"
      withoutLabel
    />

    <template v-slot:button>
      <div class="mt-8 text-right">
        <button
          type="button"
          class="btn btn-primary"
          @click="onSubmit()"
          :disabled="!validate()"
        >
          {{ $t(`${i18nScope}.submit`) }}
        </button>
      </div>
    </template>
  </model-form>
</template>

<script>
export default {
  name: 'DosageFormStep2',
  data() {
    return { customItem: { drug: '', dosage: '' } };
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.dosagePicker.step2.custom';
    },
  },
  methods: {
    onChange(changes) {
      this.customItem = { ...this.customItem, ...changes };
    },
    validate() {
      const { dosage, drug } = this.customItem;
      return !this.isBlank(drug.trim()) && !this.isBlank(dosage.trim());
    },
    async onSubmit() {
      const { drug, dosage } = this.customItem;
      this.$emit('change', {
        drug: { name: drug, shortLabel: drug },
        dosage: { name: dosage, shortLabel: dosage },
      });
    },
  },
};
</script>
