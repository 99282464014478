import Welcome from '@/components/welcome';
import MedicalReportLibrarySidebar from '@/components/admin/medical-report-library/sidebar';
import NewMedicalReportLibraryItem from '@/components/admin/medical-report-library/new';
import EditMedicalReportLibraryItem from '@/components/admin/medical-report-library/edit';

export default [
  {
    path: 'medical-report-library',
    name: 'medicalReportLibrary',
    components: {
      default: Welcome,
      sidebar: MedicalReportLibrarySidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'medical-report-library/:topicName',
    name: 'medicalReportLibraryTopic',
    components: {
      default: Welcome,
      sidebar: MedicalReportLibrarySidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'saved-medical-report-library/:topicName/new',
    name: 'newMedicalReportLibraryItem',
    components: {
      default: NewMedicalReportLibraryItem,
      sidebar: MedicalReportLibrarySidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'medical-report-library/:topicName/edit/:libraryItemId',
    name: 'editMedicalReportLibraryItem',
    components: {
      default: EditMedicalReportLibraryItem,
      sidebar: MedicalReportLibrarySidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
];
