<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-lg-12">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.brandedDrugs.edit.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-1 overflow-auto">
          <branded-drug-form
            :key="brandedDrug.id"
            :brandedDrug="brandedDrug"
            :persist="onPersist"
            @change="onChange"
            v-if="brandedDrug"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import BrandedDrugForm from './form';

export default {
  name: 'EditBrandedDrug',
  components: { BrandedDrugForm },
  props: {
    brandedDrugId: { type: String },
  },
  data() {
    return {
      brandedDrug: null,
    };
  },
  created() {
    this.$watch(
      vm => (vm.brandedDrugs, vm.brandedDrugId, Date.now()),
      () => this.fetchBrandedDrug(),
      { immediate: true }
    );
  },
  methods: {
    ...mapActions(['updateBrandedDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.brandedDrug = merge.all(
        [this.brandedDrug, changes, { id: this.brandedDrugId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.updateBrandedDrug(this.brandedDrug);
    },
    async fetchBrandedDrug() {
      this.brandedDrug = await this.services.brandedDrug.find(
        this.brandedDrugId,
        true
      );
      // the branded drug doesn't exist
      if (!this.brandedDrug) this.$router.push({ name: 'brandedDrugs' });
    },
  },
};
</script>
