var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown d-flex align-self-center h-100",class:( _obj = {
    dropup: _vm.dropup
  }, _obj[_vm.customClass] = true, _obj['w-100'] =  _vm.fullWidth, _obj )},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"btn",class:( _obj$1 = {
      'dropdown-toggle': _vm.toggle
    }, _obj$1[("btn-outline-" + _vm.color)] = _vm.border, _obj$1[("btn-" + (_vm.size || 'md'))] = true, _obj$1['btn-ico'] =  _vm.onlyIcon, _obj$1[_vm.buttonCustomClass] = !!_vm.buttonCustomClass, _obj$1['d-flex align-self-center w-100'] =  _vm.fullWidth, _obj$1 ),attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.openDropdown($event)}}},[_vm._t("button",[_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.icon)?_c('font-awesome-icon',{class:_vm.cssText,attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.label)?_c('span',{class:( _obj$2 = { 'ml-3': _vm.icon }, _obj$2[_vm.cssText] = true, _obj$2 )},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.label)?_c('font-awesome-icon',{class:{
            'ml-auto': _vm.fullWidth,
            'ml-3': !_vm.fullWidth,
          },attrs:{"icon":['fas', _vm.dropup ? 'caret-up' : 'caret-down']}}):_vm._e()],1)])],2),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"dropdown-menu dropdown-outline-secondary min-w-16 mt-3",class:( _obj$3 = {
      show: _vm.open
    }, _obj$3[("dropdown-menu-" + _vm.menuAlign)] = true, _obj$3['w-100'] =  _vm.fullWidth, _obj$3 )},[_vm._t("default",null,{"closeDropdown":_vm.closeDropdown})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }