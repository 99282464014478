<template>
  <div>
    <div class="py-6">
      <div class="container-fluid mb-6">
        <model-form
          :model="libraryItem"
          i18nScope="admin.medicalReportLibrary.form"
          :validate="services.medicalReportLibrary.isValid"
          :on-submit="persist"
          v-on="$listeners"
        >
          <tabs navClass="rounded-0 p-0">
            <tab
              :name="$t('admin.medicalReportLibrary.form.languages.fr')"
              :selected="true"
              v-on="$listeners"
            >
              <text-input attributeName="title" locale="fr" class="mt-5" />
            </tab>
            <tab
              :name="$t('admin.medicalReportLibrary.form.languages.en')"
              v-on="$listeners"
            >
              <text-input attributeName="title" locale="en" class="mt-5" />
            </tab>
          </tabs>

          <hr class="my-6" />

          <div class="row">
            <div class="col">
              <checkbox-input attributeName="published" class="mb-1" />
            </div>
            <div class="col">
              <rating-input
                attributeName="severity"
                :customGetColor="getSeverityColor"
                class="mb-1"
                v-if="!isCategory"
              />
            </div>
          </div>

          <select-input
            attributeName="parentId"
            class="mt-3 mb-6"
            v-if="allowParent"
          >
            <option value="">{{
              $t('admin.medicalReportLibrary.form.noParent')
            }}</option>
            <option
              v-for="parent in parentsTree"
              :key="parent.id"
              :value="parent.id"
            >
              {{ parentOptionName(parent) }}
            </option>
          </select-input>

          <div class="row">
            <div class="col-6">
              <checkbox-input attributeName="canBeHidden" class="mb-1" />
              <div
                class="alert alert-warning alert-sm"
                v-if="initialCanBeHidden && !libraryItem.canBeHidden"
              >
                {{ $t('admin.medicalReportLibrary.form.canBeHiddenWarning') }}
              </div>
            </div>
            <div class="col-6">
              <checkbox-input
                attributeName="triggerRDLetter"
                class="mb-1"
                v-if="topic.triggerRDLetter"
              />
            </div>
          </div>

          <hr class="my-6" />

          <agent-group-input attributeName="agentGroupIds" class="mb-6" />

          <nested-model-form
            v-model="libraryItem.filters"
            i18nScope="admin.medicalReportLibrary.form.filters"
            v-if="!isCategory"
          >
            <div class="row mt-3 mb-8">
              <div class="col-3">
                <gender-input
                  attributeName="gender"
                  :legacy="true"
                  class="mb-0"
                />
              </div>
              <div class="col-9">
                <age-range-input attributeName="age" class="mb-0 h-100" />
              </div>
            </div>
          </nested-model-form>
        </model-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicalReportLibraryItemForm',
  props: {
    topic: { type: Object },
    libraryItem: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  data() {
    return {
      parents: [],
      parentsTree: [],
      initialCanBeHidden: null,
    };
  },
  computed: {
    isNew() {
      return !this.libraryItem.id;
    },
    isCategory() {
      return this.topic.maxDepth > 1 && !this.libraryItem.parentId;
    },
    allowParent() {
      return this.topic.maxDepth > 1;
    },
  },
  methods: {
    getSeverityColor(index, value) {
      return this.services.medicalReportLibrary.getSeverityColor(index, value);
    },
    parentOptionName(parent) {
      var prefix = '';
      for (var i = 0; i < parent.depth; i++) {
        prefix = `--${prefix}`;
      }
      return `${prefix} ${parent.title.fr}`;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (!this.libraryItem.title.fr && this.$route.query.title)
          this.libraryItem.title.fr = this.$route.query.title;
      },
    },
    topic: {
      immediate: true,
      handler(newTopic) {
        this.$bind(
          'parents',
          this.services.medicalReportLibrary.findAllRoots(newTopic.name)
        );
      },
    },
    parents() {
      this.parentsTree = this.services.medicalReportLibrary.buildFlattenTree(
        this.parents,
        this.libraryItem.id
      );
    },
    libraryItem: {
      immediate: true,
      handler() {
        if (this.isNew || this.initialCanBeHidden !== null) return;
        this.initialCanBeHidden = this.libraryItem.canBeHidden;
      },
    },
  },
};
</script>
