<template>
  <modal
    name="drug-preview-modal"
    width="650"
    height="auto"
    scrollable
    @opened="fetchPdfBase64"
    :clickToClose="false"
  >
    <div class="modal-content">
      <div class="modal-header border-0 pt-4 pb-4 px-6">
        <div class="modal-title w-100 d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ $t(`${i18nScope}.title`) }}
          </h4>

          <div class="ml-auto d-flex align-items-center">
            <button
              class="btn btn-xl btn-link text-muted pr-0"
              @click="close()"
            >
              <font-awesome-icon :icon="['fal', 'times']" size="lg" />
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body px-6 pt-0 pb-7">
        <div class="w-100 bg-gray-100" style="height: 40rem">
          <iframe
            :src="pdfBase64"
            v-if="pdfBase64"
            class="w-100 h-100 border-0"
          />
        </div>

        <div class="mt-6 text-center alert alert-success" role="alert">
          {{ $t(`${i18nScope}.text`) }}
        </div>

        <div class="mt-6 d-flex align-items-center">
          <button
            type="button"
            class="btn btn-secondary btn-md ml-auto"
            @click="close()"
          >
            {{ $t(`${i18nScope}.closeButton`) }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PrescriptionConfirmationModal',
  props: {
    brandedDrug: { type: Object, required: true },
    drug: { type: Object, required: true },
  },
  data() {
    return { pdfBase64: null, buttonState: 'default' };
  },
  computed: {
    i18nScope() {
      return 'admin.drugs.previewModal';
    },
  },
  methods: {
    async fetchPdfBase64() {
      this.pdfBase64 = await this.services.drug.getPdfPreviewUrl(
        this.brandedDrug.id,
        this.drug.id
      );
    },
    close() {
      this.pdfBase64 = null;
      this.$modal.hide('drug-preview-modal');
    },
  },
};
</script>
