import { MEDICAL_REPORT_ASSIGNERS } from '@/services/agent-group';

export default {
  assign(medicalReport) {
    return !!medicalReport && this.isMemberOf(MEDICAL_REPORT_ASSIGNERS);
  },
  open({ room }) {
    return !!room.kid;
  },
};
