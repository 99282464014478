<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.agentGroups.new.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <agent-group-form
            :agentGroup="agentGroup"
            :persist="onPersist"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import AgentGroupForm from './form';

export default {
  name: 'NewAgentGroup',
  components: { AgentGroupForm },
  data() {
    return {
      agentGroup: {
        name: '',
        agentIds: [],
        position: 99,
      },
    };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.agentGroup = merge(this.agentGroup, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.services.agentGroup
        .create(this.agentGroup)
        .then(agentGroup => {
          this.$router.push({
            name: 'editAgentGroup',
            params: { agentGroupId: agentGroup.id },
          });
        });
    },
  },
};
</script>
