import Vue from 'vue';
import VueTimeago from 'vue-timeago';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInDays from 'date-fns/difference_in_days';
import i18n from './i18n.js';

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en',
  locales: {
    en: require('date-fns/locale/en'),
    fr: require('date-fns/locale/fr'),
  },
  converter(date) {
    if (!date) date = Date.now();

    const minutes = differenceInMinutes(Date.now(), date);
    const hours = differenceInHours(Date.now(), date);
    const days = differenceInDays(Date.now(), date);

    if (minutes < 1) {
      return i18n.t('timeago.lessAMinute');
    } else if (hours < 1) {
      return i18n.tc('timeago.lessAHour', minutes);
    } else if (hours < 24) {
      return i18n.tc('timeago.lessADay', hours);
    } else return i18n.tc('timeago.greaterThanADay', days);
  },
});
