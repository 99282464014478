import Welcome from '@/components/welcome';
import RoomsList from '@/components/rooms/list';
import PaginatedRoomsList from '@/components/rooms/paginated-list';
import SingleRoomList from '@/components/rooms/single-list';
import RoomDetail from '@/components/rooms/show';
import { setRoom } from '@/router/before_actions';

export default [
  {
    path: '/room',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace" */ '@/views/workspace.vue'
      ),
    redirect: { name: 'home' },
    props: true,
    meta: {
      protected: true,
    },
    children: [
      {
        path: ':id',
        name: 'shortcutRoom',
        beforeEnter: (to, from, next) => {
          setRoom(to, from, next);
        },
        components: {
          default: RoomDetail,
          sidebar: SingleRoomList,
        },
        props: {
          default: true,
          sidebar: true,
        },
      },
    ],
  },
  {
    path: '/inbox',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace" */ '@/views/workspace.vue'
      ),
    redirect: '/inbox/you/open',
    props: true,
    meta: {
      protected: true,
    },
    children: [
      {
        path: ':inboxName/closed',
        name: 'closedRooms',
        components: {
          default: Welcome,
          sidebar: PaginatedRoomsList,
        },
        props: {
          sidebar: true,
        },
      },
      {
        path: ':inboxName/closed/rooms/:id',
        name: 'closedRoomDetail',
        components: {
          default: RoomDetail,
          sidebar: PaginatedRoomsList,
        },
        props: {
          default: true,
          sidebar: true,
        },
      },
      {
        path: ':inboxName/:statusId',
        name: 'inbox',
        components: {
          default: Welcome,
          sidebar: RoomsList,
        },
        props: {
          sidebar: true,
        },
      },
      {
        path: ':inboxName/:statusId/rooms/:id',
        name: 'roomDetail',
        components: {
          default: RoomDetail,
          sidebar: RoomsList,
        },
        props: {
          default: true,
          sidebar: true,
        },
      },
    ],
  },
];
