import List from '@/components/messages/saved-replies/list';
import ListItem from '@/components/messages/saved-replies/list-item';

export default {
  components: { List, ListItem },
  data() {
    return {
      rawList: null,
      tree: null,
      currentItem: null,
      locale: 'fr',
    };
  },
  computed: {
    service() {
      return this.services.savedReply;
    },
    list() {
      return this.service.filter(this.rawList, this.currentAgentGroupIds);
    },
  },
  methods: {
    reset() {
      this.currentItem = this.breadcrumbs = null;
    },
    editContent() {
      this.$emit(
        'edit',
        this.sanitizeContent(this.currentItem.content),
        this.currentItem
      );
      this.$emit('closeDropdown');
      this.reset();
    },
    sendContent() {
      const { isImage, image, content } = this.currentItem;
      if (isImage) this.$emit('sendImage', image, this.currentItem);
      else
        this.$emit('sendText', this.sanitizeContent(content), this.currentItem);
      this.$emit('closeDropdown');
      this.reset();
    },
    sanitizeContent(content) {
      return content.replace(/<br\/>/g, '\n');
    },
    buildTree() {
      this.reset();
      this.tree = this.service.buildContentTree(this.list, this.locale, {
        userFirstName: this.user.firstName,
        kidFirstName: this.kid.firstName,
        kidGenderPronoun: this.$i18n.t(
          `gender.pronoun.${this.kid.gender === 0 ? 'female' : 'male'}`,
          this.locale
        ),
        kidGenderPronounObject: this.$i18n.t(
          `gender.pronounObject.${this.kid.gender === 0 ? 'female' : 'male'}`,
          this.locale
        ),
        kidGenderArticle: this.$i18n.t(
          `gender.article.${this.kid.gender === 0 ? 'female' : 'male'}`,
          this.locale
        ),
        kidGenderAgreement: this.kid.gender === 0 ? 'e' : '',
      });
    },
  },
  watch: {
    list() {
      this.buildTree();
    },
    locale() {
      if (this.list) this.buildTree();
    },
    user: {
      immediate: true,
      handler() {
        this.locale = this.uiLocale;
      },
    },
    currentAgentGroupIds: {
      immediate: true,
      handler() {
        this.$bind('rawList', this.service.findAll());
      },
    },
  },
};
