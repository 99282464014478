import Welcome from '@/components/welcome';
import Profile from '@/components/profile';

export default [
  {
    path: '/',
    redirect: '/inbox',
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: () =>
      import(/* webpackChunkName: "bundled-sign-in" */ '@/views/sign-in.vue'),
  },
  {
    path: '/reset-password/:agentId/:passwordToken',
    name: 'resetPassword',
    component: () =>
      import(
        /* webpackChunkName: "bundled-reset-password" */ '@/views/reset-password.vue'
      ),
    props: true,
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace" */ '@/views/workspace.vue'
      ),
    redirect: 'settings/profile',
    children: [
      {
        path: 'profile',
        name: 'profile',
        components: {
          default: Welcome,
          sidebar: Profile,
        },
      },
    ],
    meta: {
      protected: true,
    },
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: () =>
      import(/* webpackChunkName: "bundled-404" */ '@/views/not-found.vue'),
  },
];
