<template>
  <div class="hide-scrollbar">
    <div class="sidebar-header container-fluid">
      <h2 class="font-bold mb-0">
        <font-awesome-icon
          :icon="['fad', 'clipboard-prescription']"
          class="mr-5"
        />
        {{ $t('prescriptions.title') }}
      </h2>
    </div>

    <prescription-list-filter v-model="filter" />

    <loader style="min-height: 50vh" :isLoading="isLoading">
      <div
        class="min-height py-6"
        v-if="$p('prescription', 'list')"
        id="prescription-list"
      >
        <prescription-list
          :prescriptions="prescriptions"
          :prescriptionId="prescriptionId"
          :filterParams="filterParams"
          v-if="!isBlank(prescriptions)"
        />

        <div class="container-fluid min-height" v-else>
          <div
            class="d-flex flex-column align-items-center justify-content-center min-height"
          >
            <font-awesome-icon icon="inbox" class="text-light" size="5x" />
            <p class="h5 mt-3 text-gray-700">
              {{ $t('prescriptions.empty') }}
            </p>
          </div>
        </div>
      </div>
    </loader>
  </div>
</template>

<script>
import PrescriptionListFilter from './filter';
import PrescriptionList from './list';

export default {
  name: 'PrescriptionSidebar',
  components: { PrescriptionListFilter, PrescriptionList },
  props: ['prescriptionId'],
  data() {
    return { filter: {}, prescriptions: null, isLoading: true };
  },
  async mounted() {
    this.filter = await this.services.prescription.parseFilterParams(
      this.$route.query
    );
  },
  computed: {
    filterParams() {
      return this.services.prescription.toFilterParams(this.filter);
    },
  },
  methods: {
    async fetchList() {
      const service = this.services.prescription;
      this.prescriptions = await service.findAll(this.filter);
    },
    updateURL() {
      const currentParams = this.$route.query;
      const nextParams = this.filterParams;

      if (JSON.stringify(currentParams) !== JSON.stringify(nextParams)) {
        this.$router.push({ name: this.$route.name, query: nextParams });
      }
    },
  },
  watch: {
    prescriptions() {
      this.isLoading = false;
    },
    async filter() {
      this.isLoading = true;
      this.fetchList();
      this.updateURL();
    },
    async '$route.query'(query) {
      const currentParams = this.filterParams;
      if (
        !this.isBlank(query) &&
        JSON.stringify(currentParams) !== JSON.stringify(query)
      ) {
        this.filter = await this.services.prescription.parseFilterParams(query);
      }
    },
  },
};
</script>
