<template>
  <div class="list-group list-group-flush">
    <div
      v-for="(molecule, index) in list"
      :key="molecule.id"
      class="list-group-item cursor-pointer"
      :class="{
        'border-top': index === 0,
        'border-left-0 border-right-0 primary-border-right':
          molecule.id === moleculeId,
      }"
    >
      <molecule :molecule="molecule" />
    </div>
  </div>
</template>

<script>
import Molecule from './molecule';

export default {
  name: 'MoleculeList',
  components: { Molecule },
  props: {
    list: { type: Array, default: null },
    moleculeId: { type: String, default: null },
  },
};
</script>
