<template>
  <div
    class="dropdown d-flex align-self-center h-100"
    :class="{
      dropup: dropup,
      [customClass]: true,
      'w-100': fullWidth,
    }"
  >
    <button
      class="btn"
      :class="{
        'dropdown-toggle': toggle,
        [`btn-outline-${color}`]: border,
        [`btn-${size || 'md'}`]: true,
        'btn-ico': onlyIcon,
        [buttonCustomClass]: !!buttonCustomClass,
        'd-flex align-self-center w-100': fullWidth,
      }"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      v-tooltip="tooltip"
      :disabled="disabled"
      v-on:click.prevent="openDropdown"
    >
      <slot name="button">
        <span class="d-flex align-items-center justify-content-center">
          <font-awesome-icon :icon="icon" :class="cssText" v-if="icon" />
          <span :class="{ 'ml-3': icon, [cssText]: true }" v-if="label">{{
            label
          }}</span>
          <font-awesome-icon
            :icon="['fas', dropup ? 'caret-up' : 'caret-down']"
            v-if="label"
            :class="{
              'ml-auto': fullWidth,
              'ml-3': !fullWidth,
            }"
          />
        </span>
      </slot>
    </button>

    <div
      class="dropdown-menu dropdown-outline-secondary min-w-16 mt-3"
      :class="{
        show: open,
        [`dropdown-menu-${menuAlign}`]: true,
        'w-100': fullWidth,
      }"
      v-on-clickaway="closeDropdown"
    >
      <slot :closeDropdown="closeDropdown"></slot>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'DropdownButton',
  mixins: [clickaway],
  props: {
    icon: { default: null },
    color: { type: String, default: 'secondary' },
    border: { type: Boolean, default: true },
    label: { type: String, default: null },
    tooltip: { type: String, default: null },
    toggle: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
    menuAlign: { type: String, default: 'left' },
    dropup: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    customClass: { type: String, default: undefined },
    buttonCustomClass: { type: String, default: undefined },
    onlyIcon: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openDropdown() {
      this.open = !this.open;
    },
    closeDropdown(event) {
      // NOTE: click happening from the dropdown button?
      if (event && this.$el.contains(event.target)) return;
      if (this.open) this.open = false;
    },
  },
};
</script>
