import Vue from 'vue';
import services from '@/services';
import {
  truncate,
  formatTimestamp,
  formatDate,
  formatNumber,
} from '@/shared/utils';
import i18n from '@/plugins/i18n';

Vue.filter('agentFullName', services.agent.getFullName);
Vue.filter('agentFullNameInSentence', services.agent.getFullNameInSentence);
Vue.filter('agentTitle', services.agent.getTitle);
Vue.filter('truncate', truncate);
Vue.filter('formatTimestamp', formatTimestamp);
Vue.filter('formatDate', formatDate);
Vue.filter('formatNumber', value => formatNumber(value, i18n.locale));
