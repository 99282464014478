<template>
  <div class="d-flex flex-column h-100 overflow-x-hidden overflow-y-scroll">
    <h5 class="font-weight-bold mb-5">
      {{ $t('inbox.room.sidebar.history') }}
    </h5>

    <div>
      <div
        class="list-group list-group-spaced"
        v-if="medicalReports.length > 0"
      >
        <div
          class="list-group-item pt-4 pb-0 px-5"
          v-for="(medicalReport, index) in medicalReports"
          :key="index"
        >
          <medical-report :medicalReport="medicalReport" />
        </div>
      </div>
      <p v-else>
        {{ $t('inbox.room.sidebar.notFound', { kid: room.kid.firstName }) }}
      </p>
    </div>
  </div>
</template>

<script>
import MedicalReport from '@/components/rooms/show/sidebar/history/medical-report';

export default {
  name: 'RoomSidebarHistory',
  components: { MedicalReport },
  props: {
    room: { type: Object, required: true },
  },
  data() {
    return {
      medicalReports: [],
    };
  },
  mounted() {
    this.$bind(
      'medicalReports',
      this.services.medicalReport.findAllByKidId(this.room.kid.id)
    );
  },
  methods: {
    isMedicalReportFullyDisplayed(medicalReport, index) {
      return index === 0 || medicalReport.roomId === this.room.id;
    },
  },
};
</script>
