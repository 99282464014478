<template>
  <div class="row">
    <div v-for="topic in topics" :key="topic.name" class="col-6">
      <library-item-input
        :topicName="topic.name"
        :list="medicalReport[topic.name] || []"
        :letterBody="medicalReport.letterBody"
        editable
        @add="add"
        @remove="remove"
        class="mt-5"
      />
    </div>
  </div>
</template>

<script>
import LibraryItemInput from '@/components/medical-report/library-item-input';

export default {
  name: 'SavedReplyMedicalReportForm',
  components: { LibraryItemInput },
  props: {
    savedReply: { type: Object },
  },
  computed: {
    topics() {
      return this.services.medicalReportLibrary
        .findAllTopics()
        .filter(topic => topic.medicalReport);
    },
    medicalReport() {
      return this.savedReply.medicalReport || {};
    },
  },
  methods: {
    add(topicName, item) {
      const items = this.services.medicalReport.addLibraryItemToArray(
        this.medicalReport[topicName],
        item
      );
      this.$emit('change', { medicalReport: { [topicName]: items } });
    },
    remove(topicName, item) {
      const items = this.services.medicalReport.removeLibraryItemFromArray(
        this.medicalReport[topicName],
        item
      );
      this.$emit('change', { medicalReport: { [topicName]: items } });
    },
  },
};
</script>
