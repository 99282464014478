<template>
  <div class="card mb-6">
    <div class="card-header">
      <h5>{{ $t('myProfile.settings.title') }}</h5>
    </div>
    <div class="card-body">
      <form class="mt-4">
        <div class="form-group">
          <label for="locale">{{
            $t('myProfile.settings.labels.locale')
          }}</label>
          <select
            class="form-control"
            id="locale"
            :value="uiLocale"
            @change="changeUiLocale"
          >
            <option value="en">{{ $t('locales.en') }}</option>
            <option value="fr">{{ $t('locales.fr') }}</option>
          </select>
        </div>

        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label for="soundNotifications">{{
              $t('myProfile.settings.labels.soundNotifications')
            }}</label>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="soundNotifications"
                v-model="formSoundNotifications"
              />
              <label
                class="custom-control-label"
                for="soundNotifications"
              ></label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Profile',
  data() {
    return {
      formSoundNotifications: false,
    };
  },
  mounted() {
    this.formSoundNotifications = this.soundNotifications;
  },
  computed: {
    ...mapState(['soundNotifications']),
  },
  methods: {
    ...mapActions(['toggleSoundNotificationsAction']),
    changeUiLocale(event) {
      this.services.myProfile.changeLocale(
        this.currentAgent,
        event.target.value
      );
    },
  },
  watch: {
    formSoundNotifications: {
      immediate: false,
      handler(enabled) {
        this.toggleSoundNotificationsAction(enabled);
      },
    },
  },
};
</script>
