<template>
  <div class="form-group d-flex">
    <label :for="name" v-if="!withoutLabel">{{ label }}</label>
    <rating v-model="value" :customGetColor="customGetColor" class="ml-auto" />
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'RatingInput',
  mixins: [ModelInputMixin],
  props: {
    customGetColor: { type: Function, default: null },
  },
};
</script>
