import * as axios from 'axios';
import { API_URL, APP_REVISION } from './config';
import { parseAjaxResponse } from './utils';
// import { parseAjaxResponse, uuidv4 } from './utils';
// import { brandedDrugs as defaultBrandedDrugs, molecules as defaultMolecules } from '@/../tests/factory';
// import { prescriptionsAPIResponse } from '@/../tests/factory';

let jwt = null;
export const setJwt = newJwt => (jwt = newJwt);

let instance = axios.create({
  baseURL: API_URL,
  headers: { 'X-Biloba-desk': APP_REVISION },
});

export const getInstance = () => instance;

function APIException(message) {
  const error = new Error(message);
  error.code = 'API_ERROR';
  return error;
}
APIException.prototype = Object.create(Error.prototype);

const makeRequest = async (verb, url, attributes) => {
  if (jwt) {
    instance.defaults.headers.common['Authorization'] = jwt;
  }
  try {
    const response = await instance[verb](url, attributes);
    return parseAjaxResponse(response, [200, 201, 204]);
  } catch (error) {
    let message = `[API] ${verb.toUpperCase()} to ${url} failed, reason:`;
    console.error(message, error);
    throw new APIException(message);
  }
};

const get = async (url, attributes) => makeRequest('get', url, attributes);
const post = async (url, attributes) => makeRequest('post', url, attributes);
const put = async (url, attributes) => makeRequest('put', url, attributes);

// === ENDPOINTS ===

// Agents
export const signIn = async credentials => post('/agent/login', credentials);
export const refreshToken = async () => get('/agent/refresh');
export const registerAgent = async attributes =>
  post('/agent/register', attributes);
export const setAgentPassword = async (agentId, passwordToken, password) => {
  const response = await post(`/agent/password/${agentId}/${passwordToken}`, {
    password,
  });
  return response?.status === 'ok';
};
export const setMyNewPassword = async password =>
  post('/agent/password', { password });

// Molecules
export const findAllMolecules = async () => get('/molecules');
export const createMolecule = async attributes =>
  post('/molecules', attributes);
export const updateMolecule = async (id, attributes) =>
  put(`/molecules/${id}`, attributes);

// BrandedDrugs
export const findAllBrandedDrugs = async () => get('/branded-drugs');
export const findBrandedDrug = async id => get(`/branded-drugs/${id}`);
export const createBrandedDrug = async attributes =>
  post('/branded-drugs', attributes);
export const updateBrandedDrug = async (id, attributes) =>
  put(`/branded-drugs/${id}`, attributes);

// Drugs
export const createDrug = async (brandedDrugId, attributes) =>
  post(`/branded-drugs/${brandedDrugId}/drugs`, attributes);
export const updateDrug = async (brandedDrugId, id, attributes) =>
  put(`/branded-drugs/${brandedDrugId}/drugs/${id}`, attributes);
export const getDrugPdfPreview = async (brandedDrugId, id) =>
  get(`/branded-drugs/${brandedDrugId}/drugs/${id}/preview`);

// Prescriptions
export const findAllPrescriptions = async query =>
  get(`/desk/prescriptions`, { params: { ...query } });
export const findPrescription = async id => get(`/desk/prescriptions/${id}`);

export const findPrescriptions = async roomId =>
  get(`/rooms/${roomId}/prescriptions`);
export const createBulkPrescriptions = async (roomId, list) =>
  post(`/rooms/${roomId}/prescriptions`, list);
export const getPrescriptionPreviewUrl = async (roomId, list) =>
  post(`/rooms/${roomId}/prescriptions/preview`, list);

export const findPrescriptionDraft = async roomId =>
  get(`/rooms/${roomId}/prescriptions/draft`);
export const persistPrescriptionDraft = async (roomId, list) =>
  post(`/rooms/${roomId}/prescriptions/draft`, list);

// MedicalReports
export const findMedicalReportRDLetter = async medicalReportId =>
  get(`/doctors/desk/view-letter`, { params: { medicalReportId } });

// Logs
export const createLog = async action => post(`/desk/logs`, { action });

// === MOCKS ===
// let brandedDrugs = [].concat(defaultBrandedDrugs);

// export const findAllBrandedDrugs = async () => ({ brandedDrugs });
// export const findBrandedDrug = async id => ({ brandedDrug: brandedDrugs.find(brandedDrug => brandedDrug.id === id) });
// export const createBrandedDrug = async ({ moleculeIds, ...attributes }) => {
//   const molecules = moleculeIds.map(id => defaultMolecules.find(item => item.id === id));
//   const brandedDrug = { id: uuidv4(), molecules, ...attributes };
//   brandedDrugs.push(brandedDrug);
//   return { brandedDrug };
// };
// export const updateBrandedDrug = async (id, { moleculeIds, ...attributes }) => {
//   const molecules = moleculeIds.map(id => defaultMolecules.find(item => item.id === id));
//   const response = await findBrandedDrug(id);
//   const index = brandedDrugs.findIndex(item => item.id === id);
//   brandedDrugs[index] = { ...response.brandedDrug, molecules, ...attributes };
//   return brandedDrugs[index];
// };

// export const createDrug = async (brandedDrugId, attributes) => {
//   const response = await findBrandedDrug(brandedDrugId);
//   const index = brandedDrugs.findIndex(item => item.id === brandedDrugId);
//   const brandedDrug = { ...response.brandedDrug };
//   const drug = { id: uuidv4(), ...attributes };
//   brandedDrug.drugs = [].concat(brandedDrug.drugs || [], [drug]);
//   brandedDrugs[index] = brandedDrug;
//   return { drug };
// };

// export const updateDrug = async (brandedDrugId, id, attributes) => {
//   const response = await findBrandedDrug(brandedDrugId);
//   const index = brandedDrugs.findIndex(item => item.id === brandedDrugId);
//   const brandedDrug = { ...response.brandedDrug };
//   const drugIndex = brandedDrug.drugs.findIndex(item => item.id === id);
//   const drug = brandedDrug.drugs[drugIndex];
//   brandedDrug.drugs[drugIndex] = { ...drug, ...attributes };
//   brandedDrugs[index] = brandedDrug;
//   return { drug };
// };
