<template>
  <div class="hide-scrollbar">
    <div class="sidebar-header container-fluid">
      <h2 class="font-bold mb-0">
        <font-awesome-icon :icon="['fad', 'tools']" class="mr-5" />
        {{ $t('admin.navbar.title') }}
      </h2>
    </div>

    <div class="py-6">
      <ul class="list-group list-group-flush">
        <navbar-item
          routeName="savedReplies"
          :icon="['fal', 'rocket']"
          labelKey="savedReplies"
        />

        <navbar-item
          routeName="agentGroups"
          :icon="['fad', 'user-friends']"
          labelKey="agentGroups"
        />

        <navbar-item
          routeName="medicalReportLibrary"
          :icon="['fad', 'book-medical']"
          labelKey="medicalReportLibrary"
        />

        <navbar-item
          routeName="molecules"
          :icon="['fad', 'dna']"
          labelKey="molecules"
        />

        <navbar-item
          routeName="brandedDrugs"
          :icon="['fad', 'pills']"
          labelKey="brandedDrugs"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import NavbarItem from './navbar-item';

export default {
  name: 'AdminNavbar',
  components: { NavbarItem },
};
</script>

<style scoped>
.media {
  cursor: pointer;
}
</style>
