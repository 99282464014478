import Welcome from '@/components/welcome';
import Sidebar from '@/components/prescriptions/sidebar';
import Detail from '@/components/prescriptions/show';

export default [
  {
    path: '/prescriptions',
    name: 'prescriptions',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace-new" */ '@/views/workspace.vue'
      ),
    redirect: 'prescriptions/list',
    props: true,
    meta: {
      protected: true,
      policy: { scope: 'prescription', action: 'list' },
    },
    children: [
      {
        path: 'list',
        name: 'listPrescriptions',
        components: {
          default: Welcome,
          sidebar: Sidebar,
        },
        props: true,
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
      {
        path: ':prescriptionId',
        name: 'prescription',
        components: {
          default: Detail,
          sidebar: Sidebar,
        },
        props: {
          sidebar: true,
          default: true,
        },
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
    ],
  },
];
