<template>
  <div>
    <font-awesome-icon
      :icon="['fad', 'heartbeat']"
      :color="color"
      v-if="severity > 2"
    />
  </div>
</template>

<script>
export default {
  name: 'SeverityBadge',
  props: {
    severity: { type: Number, default: 0 },
  },
  computed: {
    color() {
      return this.services.medicalReportLibrary.getSeverityColor(
        this.severity,
        this.severity
      );
    },
  },
};
</script>
