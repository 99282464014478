<template>
  <div class="custom-control custom-switch custom-switch--dark">
    <input
      type="checkbox"
      class="custom-control-input cursor-pointer"
      :id="name"
      :checked="value"
      v-bind:value="value"
      v-on:input="change"
    />
    <label class="custom-control-label cursor-pointer" :for="name"></label>
  </div>
</template>

<script>
export default {
  name: 'CustomSwitch',
  props: {
    name: { type: String, default: 'switch' },
    value: { type: Boolean, default: false },
  },
  methods: {
    change(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>
