<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.agentGroups.edit.title') }}
                </h2>
              </div>
              <div class="col-3 col-lg-6 text-right">
                <ul class="nav justify-content-end">
                  <li
                    class="nav-item list-inline-item"
                    v-if="
                      $p(
                        'agentGroup',
                        { ...agentGroup, hasChildren },
                        'destroy'
                      )
                    "
                  >
                    <destroy-button
                      :text="$t('admin.agentGroups.edit.actions.destroy.text')"
                      :buttonLabel="
                        $t('admin.agentGroups.edit.actions.destroy.button')
                      "
                      @destroy="onDestroy"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <agent-group-form
            :agentGroup="agentGroup"
            :persist="onPersist"
            @change="onChange"
            v-if="agentGroup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import AgentGroupForm from './form';

export default {
  name: 'EditAgentGroup',
  components: { AgentGroupForm },
  props: ['agentGroupId'],
  data() {
    return {
      agentGroup: null,
      hasChildren: true,
    };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

      this.agentGroup = merge.all(
        [this.agentGroup, changes, { id: this.agentGroupId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.services.agentGroup.update(this.agentGroup);
    },
    onDestroy() {
      this.services.agentGroup
        .destroy(this.agentGroup)
        .then(() => this.$router.push({ name: 'agentGroups' }));
    },
  },
  watch: {
    agentGroupId: {
      immediate: true,
      handler(agentGroupId) {
        this.$bind('agentGroup', this.services.agentGroup.find(agentGroupId));
      },
    },
  },
};
</script>
