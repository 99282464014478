<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="font-bold mb-0">
                  <font-awesome-icon :icon="topic.icon" />
                  {{
                    $t(`admin.medicalReportLibrary.list.topics.${topic.name}`)
                  }}
                  <span>/</span>
                  {{ $t('admin.medicalReportLibrary.new.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <library-item-form
            :topic="topic"
            :libraryItem="libraryItem"
            :persist="onPersist"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import LibraryItemForm from './form';

export default {
  name: 'NewMedicalReportHistoryItem',
  mixins: [MedicalReportLibraryMixin],
  components: { LibraryItemForm },
  data() {
    return { libraryItem: null };
  },
  methods: {
    onChange(changes) {
      this.libraryItem = this.services.medicalReportLibrary.applyChanges(
        this.libraryItem,
        changes
      );
    },
    onPersist() {
      return this.services.medicalReportLibrary
        .create(this.topic.name, this.libraryItem)
        .then(libraryItem => {
          this.$router.push({
            name: 'editMedicalReportLibraryItem',
            params: {
              topicName: this.topic.name,
              libraryItemId: libraryItem.id,
            },
          });
        });
    },
  },
  watch: {
    services: {
      immediate: true,
      handler() {
        this.libraryItem = this.services.medicalReportLibrary.build();
      },
    },
  },
};
</script>
