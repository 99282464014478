<template>
  <div class="form-group">
    <label :for="name" v-if="!withoutLabel">{{ label }}</label>
    <textarea
      class="form-control"
      :id="name"
      :name="name"
      v-model="value"
      :maxlength="maxCharacters || 524288"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
    />
    <p class="text-right" v-if="maxCharacters">
      <small>{{ valueLength }} / {{ maxCharacters }}</small>
    </p>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'TextareaInput',
  mixins: [ModelInputMixin],
  props: {
    autocomplete: { type: String, default: 'on' },
    maxCharacters: { type: Number, default: null },
    placeholder: { type: String, default: null },
    withoutLabel: { type: Boolean, default: false },
  },
  computed: {
    valueLength() {
      return this.isBlank(this.value) ? 0 : this.value.length;
    },
  },
};
</script>
