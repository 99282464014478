<template>
  <model-form
    :model="customItem"
    :i18nScope="i18nScope"
    :onSubmit="null"
    @change="onChange"
  >
    <text-input
      attributeName="duration"
      :placeholder="$t(`${i18nScope}.durationPlaceholder`)"
      :max-characters="35"
      withoutLabel
    />

    <template v-slot:button>
      <div class="mt-8 text-right">
        <button
          type="button"
          class="btn btn-primary"
          @click="onSubmit()"
          :disabled="!validate()"
        >
          {{ $t(`${i18nScope}.submit`) }}
        </button>
      </div>
    </template>
  </model-form>
</template>

<script>
export default {
  name: 'DurationFormStep3',
  data() {
    return { customItem: { duration: '' } };
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.dosagePicker.step3.custom';
    },
  },
  methods: {
    onChange(changes) {
      this.customItem = { ...this.customItem, ...changes };
    },
    validate() {
      return !this.isBlank(this.customItem.duration.trim());
    },
    async onSubmit() {
      const { duration } = this.customItem;
      this.$emit('change', { duration }) && this.$emit('accept');
    },
  },
};
</script>
