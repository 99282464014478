<template>
  <ul class="list-group list-group-flush ">
    <li
      v-for="(prescription, index) in prescriptions"
      :key="prescription.id"
      class="list-group-item cursor-pointer min-h-4"
      :class="{
        'border-top': index === 0,
        'border-left-0 border-right-0 primary-border-right':
          prescription.id === prescriptionId,
      }"
    >
      <prescription-list-item
        :prescription="prescription"
        :filterParams="filterParams"
      />
    </li>
  </ul>
</template>

<script>
import PrescriptionListItem from './list-item';

export default {
  name: 'PrescriptionList',
  components: { PrescriptionListItem },
  props: {
    prescriptionId: { type: String, required: false, default: null },
    prescriptions: { type: Array, required: true },
    filterParams: { type: Object, default: undefined },
  },
};
</script>
