<template>
  <div class="card-body py-4 px-5">
    <div class="cart-title d-flex align-items-center">
      <h4 class="font-weight-bold mb-0">
        {{ $t(`${i18nScope}.title`) }}
      </h4>
      <div class="ml-8">
        <div
          class="border rounded d-flex font-weight-bold text-black border-color-gray-500 h4 px-4 py-2 mb-0"
        >
          <span>{{ kidWeight | formatNumber }}</span>
          <span class="ml-2">kg</span>
        </div>
      </div>
      <div class="ml-6 d-flex align-items-center">
        <font-awesome-layers class="fa-lg">
          <font-awesome-icon icon="circle" :style="{ color: '#0AE496' }" />
          <font-awesome-icon
            icon="check"
            transform="shrink-6"
            :style="{ color: '#fff' }"
          />
        </font-awesome-layers>
        <span class="ml-3">{{ sentAt }}</span>
      </div>
      <div class="ml-auto">
        <button
          class="btn btn-ico btn-link btn-minimal btn-sm text-dark"
          @click="$emit('open-medical-report')"
        >
          <font-awesome-icon :icon="['fal', 'notes-medical']" size="lg" />
        </button>
        <button
          class="btn btn-xl btn-link text-muted pr-0"
          @click="$emit('close')"
        >
          <font-awesome-icon :icon="['fal', 'times']" size="lg" />
        </button>
      </div>
    </div>

    <div class="row pb-3">
      <div class="col-12">
        <prescription-list
          :title="$tc(`${i18nScope}.treatments.title`, treatmentItems.length)"
          :previewUrl="board.previewUrl"
          :list="treatmentItems"
          class="mt-6"
        />
      </div>
    </div>

    <div class="pb-6" v-if="hasInstructions">
      <div
        class="bg-gray rounded px-5 py-5 bg-light overflow-auto"
        style="height: 5rem"
      >
        <pre class="text-wrap">{{ instructions }}</pre>
      </div>
    </div>

    <div class="pb-6 d-flex">
      <destroy-button
        :text="$t(`${i18nScope}.destroy.text`)"
        :buttonLabel="$t(`${i18nScope}.destroy.button`)"
        :dropup="true"
        v-tooltip="$t(`${i18nScope}.destroy.tooltip`)"
        class="ml-auto"
        v-if="$policy('prescriptionBoard', { room }, 'cancel')"
        @destroy="onCancel"
      />
    </div>
  </div>
</template>

<script>
import PrescriptionList from './list';
import { formatDate } from '@/shared/utils';

export default {
  name: 'PrescriptionBoard',
  components: { PrescriptionList },
  props: {
    room: { type: Object, required: true },
    board: { type: Object, required: true },
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.show';
    },
    kidWeight() {
      return this.board.kidWeight;
    },
    sentAt() {
      return this.$t(`${this.i18nScope}.sentAt`, {
        date: formatDate(this.board.sentAt),
      });
    },
    treatmentItems() {
      return this.board.treatmentItems;
    },
    hasInstructions() {
      return !this.isBlank(this.instructions);
    },
    instructions() {
      return this.board.instructions;
    },
  },
  methods: {
    onCancel() {
      this.services.prescription.bulkCancel(this.room.id).then(() => {
        this.$emit('close');
      });
    },
  },
};
</script>
