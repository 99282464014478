<template>
  <div>
    <div class="d-flex w-100 justify-content-between mb-3">
      <div @click="$emit('toggle')" class="d-flex cursor-pointer">
        <avatar
          class="mt-1 mr-3"
          :user="medicalReport.agent"
          v-if="medicalReport.agent"
          size="xs"
        />
        <strong :class="cssText">{{
          medicalReport.agent | agentFullName
        }}</strong>
      </div>
      <div>
        <router-link
          :to="{ name: 'shortcutRoom', params: { id: medicalReport.roomId } }"
          v-slot="{ href }"
        >
          <a :href="href" target="_blank" class="d-flex align-items-center">
            <small class="ml-auto text-body">
              {{ formattedDate }}
            </small>
          </a>
        </router-link>
        <p class="text-right" :class="{ 'mb-0': hasPrescriptions }">
          <small>{{ kidAge }}</small>
        </p>
        <p class="text-right" v-if="hasPrescriptions">
          <font-awesome-icon
            :icon="['fal', 'clipboard-prescription']"
            class="text-black"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { kidAgeFromBirthday, formatDate } from '@/shared/utils';

export default {
  name: 'RoomSidebarHistoryHeader',
  props: {
    medicalReport: { type: Object, required: true },
  },
  data() {
    return { hasPrescriptions: false };
  },
  computed: {
    formattedDate() {
      return formatDate(this.medicalReport.roomCreatedAt.toDate());
    },
    kid() {
      return this.medicalReport.kid;
    },
    kidAge() {
      return kidAgeFromBirthday(
        this.kid.birthDate,
        this.kid.gender,
        this.medicalReport.roomCreatedAt,
        true
      );
    },
  },
  watch: {
    medicalReport: {
      immediate: true,
      async handler(medicalReport) {
        const room = await this.services.room.load(medicalReport.roomId);
        this.hasPrescriptions = !this.isBlank(room?.prescriptions);
      },
    },
  },
};
</script>
