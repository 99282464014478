<template>
  <div class="mx-5 my-3" style="width: 40rem">
    <div class="d-flex align-items-center">
      <h4>{{ $t('inbox.room.savedReplies.title') }}</h4>

      <locale-picker v-model="locale" class="ml-auto" />
    </div>

    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <transition :name="`slide-${slideDirection}`" mode="out-in">
      <div class="d-flex flex-column" v-if="!currentItem">
        <template v-for="(mainCategory, index) in tree">
          <list
            :list="mainCategory.children"
            :key="mainCategory.id"
            :class="{ 'pt-6': index === 0 }"
            @click="selectItem"
            v-if="mainCategory.published"
          />
          <hr
            v-if="index < tree.length - 1 && mainCategory.published"
            :key="`${index}-sep`"
            class="mt-3 mb-6 w-100 border-secondary"
          />
        </template>
      </div>

      <list
        class="py-6"
        :list="currentItem.children"
        :key="currentItem.id"
        @click="selectItem"
        v-if="currentItem && !currentItem.leaf"
      />

      <list-item
        class="mt-6"
        :item="currentItem"
        @edit="editContent"
        @send="sendContent"
        v-if="currentItem && currentItem.leaf"
      />
    </transition>
  </div>
</template>

<script>
import Breadcrumbs from './breadcrumbs';
import SavedRepliesMixin from '@/mixins/saved-replies';

export default {
  name: 'SavedRepliesDropdownButton',
  mixins: [SavedRepliesMixin],
  components: { Breadcrumbs },
  props: {
    user: { type: Object },
    kid: { type: Object },
  },
  data() {
    return {
      deepBreadcrumbs: null,
      slideDirection: 'left',
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          id: 'root',
          label: this.$i18n.t('inbox.room.savedReplies.root'),
          select: () => {
            this.selectItem(null);
          },
        },
      ].concat(this.deepBreadcrumbs || []);
    },
  },
  methods: {
    reset() {
      this.currentItem = this.deepBreadcrumbs = null;
    },
    selectItem(item) {
      if (!item || item.depth === 1) this.selectRootItem(item);
      else this.selectChildItem(item);
    },
    selectRootItem(item) {
      this.reset();
      this.currentItem = item ? { ...item } : null;
      if (item) this.buildDefaultDeepBreadcrumbs(item);
    },
    buildDefaultDeepBreadcrumbs(item) {
      this.deepBreadcrumbs = [
        {
          id: item.id,
          label: item.title,
          select: () => {
            this.selectItem(item);
          },
        },
      ];
    },
    selectChildItem(item) {
      // item among the breadcrumbs?
      const index = this.deepBreadcrumbs.findIndex(
        breadcrumb => breadcrumb.id === item.id
      );
      if (index !== -1) {
        this.deepBreadcrumbs = [].concat(this.deepBreadcrumbs).slice(0, index);
      }

      // feed the breadcrumb
      this.deepBreadcrumbs.push({
        id: item.id,
        label: item.title,
        select: item.leaf
          ? null
          : () => {
              this.selectItem(item);
            },
      });

      this.currentItem = { ...item };
    },
  },
  watch: {
    breadcrumbs: {
      immediate: true,
      handler(newBreadcrumbs, oldBreadcrumbs) {
        this.slideDirection =
          newBreadcrumbs.length >= (oldBreadcrumbs || []).length
            ? 'left'
            : 'right';
      },
    },
  },
};
</script>
