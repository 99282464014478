import firebase from 'firebase/app';
import { buildAgent, getDB, nowOnServer } from '@/shared/db';
import { htmlToMobileContent, getFileExtension, uuidv4 } from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';

export const findAll = roomId => {
  return getDB()
    .collection('rooms')
    .doc(roomId)
    .collection('messages')
    .where('type', 'in', ['text', 'image', 'video'])
    .orderBy('createdAt');
};

const build = (agent, content, type) => {
  var sender;

  if (agent === 'biloba') sender = { type: 'biloba' };
  else sender = { ...buildAgent(agent), type: 'agent' };

  return {
    content,
    type: type,
    createdAt: nowOnServer(),
    sender,
  };
};

const create = (agent, room, content, type) => {
  trackAgent('CREATE', 'MESSAGE', room.id);
  var batch = getDB().batch();
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  const messageDoc = roomDoc.collection('messages').doc();
  const message = build(agent, content, type);

  // add the `push the message` operation to the batch
  batch.set(messageDoc, message);

  var modifiedAttributes = {
    lastMessage: message,
    hasUserRead: false,
    haveAgentsRead: message.sender.type === 'agent' ? [agent.id] : [],
  };

  if (agent !== 'biloba' && (room.agent === null || room.agent === undefined)) {
    modifiedAttributes.agent = buildAgent(agent);
  }

  // add `modify the room` operation to the batch
  batch.update(roomDoc, modifiedAttributes);

  // update the whole room with one single request
  return batch.commit().then(() => clearTextAsDraft(room));
};

export const postText = (agent, room, content) => {
  // send a content for the mobile without any HTML tags
  create(agent, room, htmlToMobileContent(content), 'text');
};

export const postImageUrl = (agent, room, imageURL) => {
  create(agent, room, imageURL, 'image');
};

export const postImage = (agent, room, file) => {
  if (!room.kid?.id) throw `The room '${room.id}' doesn't have a kid field.`;

  const storageRef = firebase.storage().ref();
  const extension = getFileExtension(file.name) || '.jpg';
  const filename = `kids/${room.kid.id}/${uuidv4()}.${extension}`;

  var fileRef = storageRef.child(filename);
  fileRef.put(file).then(snapshot => {
    // console.log('Uploaded a blob or file!', snapshot);
    snapshot.ref.getDownloadURL().then(downloadURL => {
      postImageUrl(agent, room, downloadURL);
    });
  });
};

export const saveTextAsDraft = (room, content) => {
  if (content && content.trim().length > 0)
    localStorage.setItem(`${room.id}-lastmessage`, content);
  else clearTextAsDraft(room);
};

export const loadTextFromDraft = room => {
  return localStorage.getItem(`${room.id}-lastmessage`);
};

export const clearTextAsDraft = room => {
  localStorage.removeItem(`${room.id}-lastmessage`);
};
