<template>
  <div class="my-2">
    <span v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.id">
      <span v-if="index > 0">
        <span class="mx-3">&gt;</span>
      </span>
      <a
        class="text-gray-700 font-weight-normal d-inline-block"
        :class="{ 'text-underline': index < breadcrumbs.length - 1 }"
        href="#"
        @click="breadcrumb.select"
        v-if="breadcrumb.select && index < breadcrumbs.length - 1"
      >
        {{ breadcrumb.label }}
      </a>

      <span class="text-gray-700 font-weight-normal d-inline-block" v-else>
        {{ breadcrumb.label }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SavedRepliesBreadcrumbs',
  props: {
    breadcrumbs: { type: Array },
  },
};
</script>
