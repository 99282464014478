import Welcome from '@/components/welcome';
import AgentGroupsSidebar from '@/components/admin/agent-groups/sidebar';
import NewAgentGroup from '@/components/admin/agent-groups/new';
import EditAgentGroup from '@/components/admin/agent-groups/edit';

export default [
  {
    path: 'agent-groups',
    name: 'agentGroups',
    components: {
      default: Welcome,
      sidebar: AgentGroupsSidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'agent-groups/new',
    name: 'newAgentGroup',
    components: {
      default: NewAgentGroup,
      sidebar: AgentGroupsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'agent-groups/:agentGroupId/edit',
    name: 'editAgentGroup',
    components: {
      default: EditAgentGroup,
      sidebar: AgentGroupsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
];
