<template>
  <div>
    <report-header :medicalReport="medicalReport" @toggle="toggle" />

    <div v-if="isMedicalReportNotEmpty">
      <collapse-transition>
        <div v-if="collapsed">
          <library-item-input
            v-for="topic in shortListTopics"
            :key="topic.name"
            :topicName="topic.name"
            :list="medicalReport[topic.name]"
            :kid="medicalReport.kid"
            :editable="false"
            class="pt-2 pb-4"
          />
        </div>
      </collapse-transition>
      <collapse-transition>
        <div v-if="!collapsed">
          <library-item-input
            v-for="topic in topics"
            :key="topic.name"
            :topicName="topic.name"
            :list="medicalReport[topic.name]"
            :kid="medicalReport.kid"
            :editable="false"
            class="py-2"
          />

          <comments-input
            :medicalReport="medicalReport"
            :editable="false"
            class="py-2"
            v-if="hasComments"
          />
        </div>
      </collapse-transition>
    </div>
    <div class="mt-4" v-else>
      <p class="text-center pb-4">
        {{ $t('inbox.room.sidebar.emptyMedicalReport') }}
      </p>
    </div>
  </div>
</template>

<script>
import ReportHeader from '@/components/rooms/show/sidebar/history/header';
import LibraryItemInput from '@/components/medical-report/library-item-input';
import CommentsInput from '@/components/rooms/show/medical-report/comments-input';

export default {
  name: 'RoomSidebarHistoryMedicalReport',
  components: { ReportHeader, LibraryItemInput, CommentsInput },
  props: {
    medicalReport: { type: Object, required: true },
    initialCollapsed: { type: Boolean, default: false },
  },
  data() {
    return { collapsed: this.initialCollapsed };
  },
  computed: {
    topics() {
      return this.services.medicalReportLibrary
        .findAllTopics()
        .filter(
          topic =>
            topic.medicalReport && this.medicalReport[topic.name]?.length > 0
        );
    },
    shortListTopics() {
      return this.services.medicalReportLibrary
        .findAllTopics()
        .filter(
          topic =>
            (topic.name === 'diagnosis' &&
              this.medicalReport.diagnosis?.length > 0) ||
            (topic.medicalReport &&
              (this.medicalReport[topic.name] || []).some(
                item => item.severity > 2
              ))
        );
    },
    isMedicalReportNotEmpty() {
      return this.topics.length > 0 || this.hasComments;
    },
    hasComments() {
      return this.medicalReport.comments?.length > 0;
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
