export const displayDrowning = dynConfig => {
  if (dynConfig) {
    return (
      dynConfig.open &&
      (dynConfig.almostDrowning === true || dynConfig.drowning === true)
    );
  }
  return false;
};

export const displayClosed = dynConfig => {
  if (dynConfig) {
    return dynConfig.open === false;
  }
  return false;
};

export const displayClosing = (dynConfig, room) => {
  if (dynConfig && room) {
    return (
      dynConfig.open === true &&
      dynConfig.closing === true &&
      room.status &&
      (room.status.type === 'opening' || room.status.type === 'open')
    );
  }
  return false;
};
