<template>
  <agent-picker
    :agentId="doctorId"
    :groupName="groupNames.PRESCRIPTION_REFERRERS"
    :strictlyInGroup="true"
    :allowUnselecting="!value"
    :hintLabel="$t(`${i18nScope}.hint`)"
    :unselectedLabel="$t(`${i18nScope}.unselected`)"
    @select-agent="assignDoctor"
    :dropup="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PrescriptionBoardDoctorPicker',
  props: {
    value: { type: String },
  },
  computed: {
    ...mapGetters(['getGroupByName']),
    i18nScope() {
      return 'inbox.room.prescriptionBoard.doctorPicker';
    },
    doctorId() {
      return this.value;
    },
  },
  methods: {
    assignDoctor(doctor) {
      this.$emit('input', doctor?.id);
    },
  },
};
</script>
