<template>
  <dropdown :icon="['far', 'ellipsis-v']" menuAlign="right">
    <ul class="list-group list-group-flush">
      <!-- Room URL -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.roomURL') }}</h6>
            <strong :class="cssText">{{ roomURL }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="roomURL"
              v-clipboard:success="onRoomURLCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: roomURLCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>

      <!-- Room ID -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.roomId') }}</h6>
            <strong :class="cssText">{{ room.id }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="room.id"
              v-clipboard:success="onRoomIdCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: roomIdCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>

      <!-- User ID -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.userId') }}</h6>
            <strong :class="cssText">{{ room.user.id }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="room.user.id"
              v-clipboard:success="onRoomUserIdCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: roomUserIdCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>
      <!-- Rating -->
      <li class="list-group-item py-3" v-if="rating">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.rating') }}</h6>
            <strong :class="cssText">{{ rating }}/5</strong>
          </div>
        </div>
      </li>
    </ul>
  </dropdown>
</template>

<script>
export default {
  name: 'RoomInfo',
  props: ['room'],
  data() {
    return {
      roomIdCopied: false,
      roomURLCopied: false,
      roomUserIdCopied: false,
    };
  },
  computed: {
    roomURL() {
      const resolvedPath = this.$router.resolve({
        name: 'shortcutRoom',
        params: { id: this.room.id },
      });
      return `${window.location.origin}${resolvedPath.route.path}`;
    },
    rating() {
      return this.room.rating?.value;
    },
  },
  methods: {
    onRoomURLCopy() {
      this.roomURLCopied = true;
      setTimeout(() => {
        this.roomURLCopied = false;
      }, 1000);
    },
    onRoomIdCopy() {
      this.roomIdCopied = true;
      setTimeout(() => {
        this.roomIdCopied = false;
      }, 1000);
    },
    onRoomUserIdCopy() {
      this.roomUserIdCopied = true;
      setTimeout(() => {
        this.roomUserIdCopied = false;
      }, 1000);
    },
    onError() {
      alert('Error copying id to the clipboard');
    },
  },
};
</script>
