export default {
  inject: ['parentModel', 'parentI18nScope', 'modelName'],
  props: {
    model: { type: Object },
    i18nScope: { type: String },
    attributeName: { type: String },
    withoutLabel: { type: Boolean, default: false },
    locale: { type: String, default: undefined },
  },
  computed: {
    name() {
      return `${this.modelName}-${this.attributeName}-input`;
    },
    safeModel() {
      return this.model || this.parentModel();
    },
    safeI18nScope() {
      return this.i18nScope || this.parentI18nScope;
    },
    label() {
      return this.$t(`${this.safeI18nScope}.${this.attributeName}`);
    },
    value: {
      get() {
        const value = this.safeModel[this.attributeName];
        return this.locale ? value[this.locale] : value;
      },
      set(value) {
        let change = { [this.attributeName]: value };
        if (this.locale)
          change = { [this.attributeName]: { [this.locale]: value } };
        this.$parent.$emit('change', change);
      },
    },
  },
  methods: {
    t(name) {
      return this.$t(`${this.safeI18nScope}.${this.attributeName}.${name}`);
    },
  },
};
