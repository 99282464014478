import Vue from 'vue';
import { mapState } from 'vuex';
import { playSound } from '../shared/utils';

Vue.mixin({
  computed: {
    ...mapState(['soundNotifications']),
  },
  methods: {
    playSound() {
      if (this.soundNotifications) playSound();
    },
  },
});
