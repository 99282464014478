<template>
  <div class="chat-content">
    <div
      class="sticky-top py-3 text-center bg-orange text-gray-700 capitalize-first"
      style="z-index: 30"
      v-if="hasWarning"
    >
      {{ warningMessage }}
    </div>
    <div
      class="sticky-top py-3 text-center bg-light-blue text-gray-700 capitalize-first"
      style="z-index: 30"
      v-if="displayClosing"
    >
      <span v-html="$t('inbox.room.closing')" />
    </div>
    <div class="container-fluid py-3 py-lg-4">
      <div v-for="streamItem in stream" :key="streamItem.id">
        <MessageItem
          class="mt-8"
          :message="streamItem.message"
          v-if="streamItem.type === 'message'"
        />
        <div
          class="d-flex align-items-center justify-content-center my-8 position-relative"
          v-else
        >
          <div
            class="border border-secondary rounded-pill px-6 py-1 bg-white position-relative z-10"
          >
            <small>{{ streamItem.date | formatDate }}</small>
          </div>
          <div
            class="position-absolute top-50 w-100 h-1 bg-secondary"
            style="height: 1px"
          ></div>
        </div>
      </div>

      <!-- Scroll to end -->
      <div ref="endOfChat"></div>
    </div>
  </div>
</template>

<script>
import { formatDate, isSameDay, daysBetweenDates } from '@/shared/utils';
import MessageItem from './list-item';
import RoomValidator from '@/validators/room';
import { displayClosing } from '@/shared/status';

export default {
  name: 'Messages',
  components: { MessageItem },
  props: {
    roomId: {
      type: String,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
    status: {
      validator: RoomValidator.status,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      displayClosing: false,
    };
  },
  mounted() {
    window.addEventListener(
      'room.scrollToLastMessage',
      this.scrollToLastMessage
    );
  },
  destroyed() {
    window.removeEventListener(
      'room.scrollToLastMessage',
      this.scrollToLastMessage
    );
  },
  computed: {
    hasWarning() {
      return (
        this.room.status.type === 'closed' ||
        this.room.status.type === 'snoozed'
      );
    },
    warningMessage() {
      switch (this.room.status.type) {
        case 'closed':
          return this.closeWarningMessage;
        case 'snoozed':
          return this.snoozedWarningMessage;
        default:
          return 'Unknown status';
      }
    },
    closeWarningMessage() {
      const { agentFullNameInSentence } = this.$options.filters;
      return this.$t('inbox.room.closedWarning', {
        date: formatDate(this.room.status.updatedAt?.toDate()?.getTime()),
        name: agentFullNameInSentence(this.room.status.agent),
      });
    },
    snoozedWarningMessage() {
      const { agentFullNameInSentence } = this.$options.filters;
      const snoozedUntilDays = daysBetweenDates(
        this.room.status.snoozedUntil?.toDate(),
        this.room.status.updatedAt?.toDate()
      );
      return this.$tc('inbox.room.snoozedWarning', snoozedUntilDays, {
        date: formatDate(this.room.status.updatedAt?.toDate()?.getTime()),
        name: agentFullNameInSentence(this.room.status.agent),
        snoozedUntilDays,
        snoozedUntilDate: formatDate(
          this.room.status.snoozedUntil?.toDate()?.getTime()
        ),
      });
    },
    stream() {
      let stream = [];
      let previousDate = null;
      this.messages.forEach((message, index) => {
        let date = message.createdAt?.toDate();
        if (previousDate && !isSameDay(previousDate, date)) {
          stream.push({ id: `day.${index}`, type: 'day', date });
        }
        stream.push({ id: index, type: 'message', message });
        previousDate = date;
      });
      return stream;
    },
  },
  methods: {
    scrollToLastMessage() {
      if (this.$refs.endOfChat)
        this.$refs.endOfChat.scrollIntoView({ behavior: 'smooth' });
    },
  },
  watch: {
    roomId: {
      immediate: true,
      handler(roomId) {
        this.$bind('messages', this.services.message.findAll(roomId));
      },
    },
    messages: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.scrollToLastMessage());
      },
    },
    currentAppDynConfig: {
      immediate: true,
      handler(newDyn) {
        if (newDyn) {
          this.displayClosing = displayClosing(newDyn, this.room);
        }
      },
    },
  },
};
</script>
