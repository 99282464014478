<template>
  <ul class="list-unstyled d-flex flex-wrap mb-0">
    <li v-for="item in publishedItems" :key="item.id" class="mr-4 mb-4">
      <a
        href="#"
        class="btn btn-md d-flex align-items-center btn-outline-dark border-color-gray-500 font-weight-bold px-4 py-3"
        @click="$emit('click', item)"
      >
        <font-awesome-icon icon="image" class="mr-3" v-if="item.isImage" />
        <span>{{ item.title }}</span>
        <span class="ml-5" v-if="!isBlank(item.children)">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SavedReplyList',
  props: {
    list: { type: Array },
  },
  computed: {
    publishedItems() {
      return this.list.filter(item => item.published && item.title);
    },
  },
};
</script>
