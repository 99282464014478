<template>
  <div class="w-100 mt-6 overflow-auto" style="height: 38rem">
    <div v-if="boardPreview">
      <div
        class="bg-gray-200 text-dark rounded px-6 py-6 mb-6"
        v-if="hasInstructions"
      >
        <h5 class="mb-1">{{ $t(`${i18nScope}.instructions`) }}</h5>
        <p
          class="mb-1"
          v-for="(line, index) in instructions"
          :key="`instructions-${index}`"
        >
          {{ line }}
        </p>
      </div>
      <div
        class="bg-gray-100 rounded px-6 py-6 mb-6"
        v-for="(item, index) in prescriptionItems"
        :key="`prescription-item-${index}`"
      >
        <h3 class="mb-0">{{ item.title }}</h3>
        <h5>{{ item.subtitle }}</h5>
        <p class="mt-4 text-dark" v-if="item.description">
          {{ item.description }}
        </p>

        <div
          class="position-relative rounded bg-white px-4 pt-6 pb-4 mt-8"
          v-if="item.card"
        >
          <div
            class="position-absolute bg-blue-500 px-4 py-1 text-white rounded"
            style="top: -1rem; right: 1.5rem"
            v-if="item.badge"
          >
            <small>{{ item.badge }}</small>
          </div>
          <p class="text-dark">{{ item.card }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobilePreviewPrescriptionConfirmationModal',
  props: {
    boardPreview: { type: Object },
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.confirmationModal.mobilePreview';
    },
    prescriptionItems() {
      return this.boardPreview.mobile.prescriptionItems;
    },
    instructions() {
      return this.boardPreview.mobile.instructions;
    },
    hasInstructions() {
      return !this.isBlank(this.instructions);
    },
  },
};
</script>
