import Welcome from '@/components/welcome';
import MoleculesSidebar from '@/components/admin/molecules/sidebar';
import NewMolecule from '@/components/admin/molecules/new';
import EditMolecule from '@/components/admin/molecules/edit';

export default [
  {
    path: 'molecules',
    name: 'molecules',
    components: {
      default: Welcome,
      sidebar: MoleculesSidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'molecules/new',
    name: 'newMolecule',
    components: {
      default: NewMolecule,
      sidebar: MoleculesSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'molecules/:moleculeId/edit',
    name: 'editMolecule',
    components: {
      default: EditMolecule,
      sidebar: MoleculesSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
];
