<template>
  <button
    class="btn btn-sm d-flex align-items-center btn-outline-dark"
    type="button"
    :class="{
      'pointer-events-none tag-disabled': !deletable,
    }"
    v-on:click.stop.prevent="toggleTrashButton"
  >
    <slot>
      <span class="font-weight-bold">
        {{ label }}
      </span>
    </slot>
    <transition name="slide-left">
      <span
        class="ml-5 mt-n1"
        v-if="showTrashButton"
        v-on:click.stop.prevent="deleteTag"
      >
        <font-awesome-icon :icon="['far', 'trash-alt']" />
      </span>
    </transition>
  </button>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    label: { type: String, default: 'Label' },
    deletable: { type: Boolean, default: true },
  },
  data() {
    return {
      showTrashButton: false,
      timer: null,
    };
  },
  methods: {
    toggleTrashButton() {
      if (!this.deletable) return;

      this.showTrashButton = !this.showTrashButton;
      if (this.showTrashButton)
        this.timer = setTimeout(() => this.toggleTrashButton(), 2000);
      else if (this.timer) clearTimeout(this.timer);
    },
    deleteTag() {
      this.$emit('delete');
    },
  },
};
</script>
