<template>
  <div class="d-flex h-100">
    <div class="d-flex flex-column flex-grow-1">
      <div>
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold mb-0">
            <font-awesome-icon :icon="['fad', 'user-friends']" class="mr-5" />
            {{ $t('admin.agentGroups.list.title') }}
          </h2>
        </div>
        <div class="pt-6">
          <div class="container-fluid mb-6">
            <router-link
              :to="{ name: 'newAgentGroup' }"
              class="btn btn-primary btn-block"
            >
              {{ $t('admin.agentGroups.list.newButton') }}
            </router-link>

            <p class="text-center">
              <router-link :to="{ name: 'admin' }" class="btn btn-link">
                <font-awesome-icon
                  :icon="['fal', 'long-arrow-left']"
                  class="icon-md"
                />
                {{ $t('admin.agentGroups.list.backButton') }}
              </router-link>
            </p>
          </div>
        </div>
      </div>

      <div class="flex-grow-2 overflow-auto">
        <div class="pb-6">
          <list
            :agentGroupId="agentGroupId"
            :class="{ 'list-group': true }"
            @change="onChange"
            :list="list"
            v-if="list"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from './list';

export default {
  name: 'agentGroupsList',
  components: { List },
  props: ['agentGroupId'],
  data() {
    return {
      list: null,
    };
  },
  methods: {
    onChange() {
      this.services.agentGroup.updatePositions(this.list);
    },
  },
  mounted() {
    this.$bind('list', this.services.agentGroup.findAll(true));
  },
};
</script>
