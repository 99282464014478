import merge from 'deepmerge';
import { getDB, nowOnServer } from '@/shared/db';
import {
  isBlank,
  isObjectEmpty,
  getSupportedLanguage,
  getKidAgeInMonths,
  isArraySubset,
} from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';

export const findAllTopics = withTracking => {
  if (withTracking) trackAgent('VIEW', 'MEDICAL-REPORT-LIBRARY');
  return [
    {
      name: 'antecedents',
      icon: 'star-of-life',
      maxDepth: 2,
      searchable: true,
      multiple: true,
      medicalReport: false,
      triggerRDLetter: false,
    },
    {
      name: 'causes',
      icon: ['far', 'comments'],
      maxDepth: 2,
      searchable: true,
      multiple: true,
      medicalReport: true,
      triggerRDLetter: false,
    },
    {
      name: 'diagnosis',
      icon: ['fal', 'user-md-chat'],
      maxDepth: 2,
      searchable: true,
      multiple: true,
      medicalReport: true,
      triggerRDLetter: false,
    },
    {
      name: 'directions',
      icon: 'directions',
      maxDepth: 1,
      searchable: false,
      medicalReport: true,
      singleItem: true,
      triggerRDLetter: true,
    },
    {
      name: 'supervisions',
      icon: ['far', 'heart-rate'],
      maxDepth: 1,
      searchable: false,
      medicalReport: true,
      multiple: true,
      triggerRDLetter: false,
    },
  ];
};

export const isValid = attributes => {
  return !isBlank(attributes.title.fr);
};

export const findTopic = name => {
  return findAllTopics().find(topic => topic.name === name);
};

const calculateDepth = (nodes, depth) => {
  nodes.forEach(node => {
    node.depth = depth;
    calculateDepth(node.children, depth + 1);
  });
};

const flattenTree = (nodes, rejectedId) => {
  return nodes
    .map(node => {
      if (node.id === rejectedId) return [];
      return [].concat(node, flattenTree(node.children, rejectedId));
    })
    .flat(42);
};

export const buildContentTree = (list, language, filters) => {
  const safeLanguage = getSupportedLanguage(language);
  const contentify = libraryItem => {
    var clonedLibraryItem = { ...libraryItem };
    clonedLibraryItem.translatedTitle = libraryItem.title;
    clonedLibraryItem.title = libraryItem.title[safeLanguage];
    clonedLibraryItem.id = libraryItem.id; // I love Javascript

    // don't filter categories (so only leaves)
    if (
      filters &&
      clonedLibraryItem.leaf &&
      !acceptLibraryItem(clonedLibraryItem, filters)
    )
      return null;

    return clonedLibraryItem;
  };

  return buildTree(list, contentify);
};

export const buildTree = (list, applyFunc) => {
  var memo = {};
  var root = [];

  // build the memo
  list.forEach(libraryItem => {
    libraryItem.children = [];
    memo[libraryItem.id] = applyFunc ? applyFunc(libraryItem) : libraryItem;
  });

  // assign a parent to each saved reply
  list.forEach(libraryItem => {
    if (!memo[libraryItem.id]) return; // filtered or not
    if (libraryItem.parentId) {
      var parent = memo[libraryItem.parentId];
      if (parent) parent.children.push(memo[libraryItem.id]);
    } else root.push(memo[libraryItem.id]);
  });

  calculateDepth(root, 0);

  return root;
};

export const buildFlattenTree = (list, rejectedId) => {
  return flattenTree(buildTree(list), rejectedId);
};

export const acceptLibraryItem = (
  libraryItem,
  { kid, query, agentGroupIds, rejectedIds }
) => {
  const {
    title,
    agentGroupIds: itemAgentGroupIds,
    filters: { gender, minAge, maxAge },
  } = libraryItem;

  let kidAge = minAge;
  let kidGender = null;

  if (kid) {
    kidAge = getKidAgeInMonths(kid.birthDate);
    kidGender = kid.gender === 0 ? 'girl' : 'boy';
  }

  return (
    libraryItem.published &&
    (!query || title.toLowerCase().indexOf(query.toLowerCase()) !== -1) &&
    (!kidGender || gender === 'all' || gender === kidGender) &&
    minAge <= kidAge &&
    maxAge >= kidAge &&
    (!rejectedIds || rejectedIds.indexOf(libraryItem.id) === -1) &&
    (!agentGroupIds ||
      itemAgentGroupIds.includes('all') ||
      itemAgentGroupIds.includes('ALL') ||
      isArraySubset(itemAgentGroupIds, agentGroupIds))
  );
};

export const filter = (list, groupIds) => {
  if (list === null) return null;
  let allGroupIds = ['all', 'ALL', ...groupIds];
  return list.filter(item => {
    let requestedGroupIds = isBlank(item.agentGroupIds)
      ? ['ALL']
      : item.agentGroupIds;
    return requestedGroupIds.some(groupId => allGroupIds.includes(groupId));
  });
};

export const findTopicItems = topic => {
  return getDB()
    .collection('medical-report-library')
    .doc(topic)
    .collection('items');
};

export const findAll = topic => {
  return findTopicItems(topic)
    .orderBy('position', 'asc')
    .orderBy('title.fr', 'asc');
};

export const findAllRoots = topic => {
  return findTopicItems(topic)
    .where('leaf', '==', false)
    .orderBy('position', 'asc')
    .orderBy('title.fr', 'asc');
};

export const find = (topic, id) => {
  trackAgent('VIEW', topic, id);
  return findTopicItems(topic).doc(id);
};

export const load = async (topic, id) => {
  return findTopicItems(topic)
    .doc(id)
    .get()
    .then(document => document.data());
};

export const hasChildren = (topic, id) => {
  return findTopicItems(topic)
    .where('parentId', '==', id)
    .get()
    .then(snapshot => !snapshot.empty);
};

export const build = () => {
  return {
    title: { fr: '', en: '' },
    leaf: false,
    published: false,
    parentId: '',
    position: 99,
    agentGroupIds: ['ALL'],
    severity: 2, // range: 0..4 (5 values)
    filters: {
      gender: 'all',
      minAge: 0, // in months
      maxAge: 216, // in months
    },
  };
};

export const applyChanges = (libraryItem, changes) => {
  const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
  return merge({ ...libraryItem }, changes, {
    arrayMerge: overwriteMerge,
  });
};

export const create = (topic, libraryItem) => {
  trackAgent('CREATE', topic, libraryItem.title?.fr);
  return findTopicItems(topic).add({
    ...libraryItem,
    leaf: !isBlank(libraryItem.parentId),
    createdAt: nowOnServer(),
  });
};

export const update = (topic, libraryItem) => {
  trackAgent('UPDATE', topic, libraryItem.id);
  const libraryItemDoc = findTopicItems(topic).doc(libraryItem.id);
  return libraryItemDoc.update({
    ...libraryItem,
    leaf: !isBlank(libraryItem.parentId),
    updatedAt: nowOnServer(),
  });
};

export const destroy = (topic, libraryItem) => {
  trackAgent('DESTROY', topic, libraryItem.id);
  return findTopicItems(topic)
    .doc(libraryItem.id)
    .delete();
};

export const recursiveUpdateTree = (topic, parent, nodes, batch) => {
  nodes.forEach((node, index) => {
    const doc = findTopicItems(topic).doc(node.id);
    var changes = {};

    if (node.position !== index) changes.position = index;

    if (parent && parent.id !== node.parentId) changes.parentId = parent.id;

    if (!isObjectEmpty(changes)) batch.update(doc, changes);

    recursiveUpdateTree(topic, node, node.children, batch);
  });
};

export const updateTree = (topic, newTree) => {
  var batch = getDB().batch();

  recursiveUpdateTree(topic, null, newTree, batch);

  // update the whole tree with a single call to Firebase
  return batch.commit();
};

export const getSeverityColor = (index, value) => {
  if (index > value) return null;
  else if (index <= 2 && value <= 2) return 'var(--primary)';
  else if (index <= 3 && value === 3) return 'orange';
  else return 'red';
};
