<template>
  <router-link
    :to="{ name: 'editSavedReply', params: { savedReplyId: savedReply.id } }"
    tag="div"
    class="media align-items-center py-3"
  >
    <div class="media-body">
      <div class="d-flex align-items-center">
        <font-awesome-icon
          :icon="['fas', 'grip-vertical']"
          class="handle cursor-move mr-3"
        />
        <h6 class="mb-0" :class="{ 'font-italic': !savedReply.published }">
          {{ savedReply.title.fr }}
        </h6>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'SavedReplyListItem',
  props: {
    savedReply: { type: Object },
  },
};
</script>
