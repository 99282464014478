<template>
  <div class="main" data-mobile-height>
    <div class="chat flex-column justify-content-center text-center">
      <div class="container">
        <avatar :user="agent" size="lg" />

        <h6 class="mt-5">
          {{ $t('welcome.title', { name: getAgentFullName }) }}
        </h6>
        <p>{{ $t('welcome.subTitle') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Welcome',
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['getAgentFullName']),
  },
};
</script>
