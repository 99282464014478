<template>
  <dropdown
    :toggle="true"
    :fullWidth="fullWidth"
    :dropup="dropup"
    menuAlign="right"
    ref="dropdown"
  >
    <!-- button -->
    <template v-slot:button>
      <span v-if="selectedAgent" class="d-flex align-items-center w-100">
        <avatar :user="selectedAgent" size="xs" />
        <span class="ml-5 pr-2" :class="cssText">
          {{ selectedAgent | agentFullName(currentAgent) }}
        </span>
        <font-awesome-icon
          :icon="['fas', dropup ? 'caret-up' : 'caret-down']"
          :class="{
            'ml-auto': fullWidth,
            'ml-3': !fullWidth,
          }"
        />
      </span>
      <span class="d-flex align-items-center w-100 mt-n1" v-else>
        <span class="w-5 h-100 d-flex align-items-center">
          <font-awesome-icon :icon="unassignedIcon" :class="cssText" />
        </span>
        <span class="ml-2 pr-2" :class="cssText">
          {{ unselectedLabel }}
        </span>
        <font-awesome-icon
          :icon="['fas', 'caret-down']"
          :class="{
            'ml-auto': fullWidth,
            'ml-3': !fullWidth,
          }"
        />
      </span>
    </template>

    <div class="overflow-auto list" :class="{ small: small }">
      <!-- Me -->
      <a
        class="dropdown-item d-flex align-items-center"
        :class="{ 'text-primary': isSelected(currentAgent) }"
        @click="selectAgent(currentAgent)"
        href="#"
        v-if="showCurrentAgentSelector"
      >
        <span>
          <avatar :user="currentAgent" :online="true" size="md" />
          <span class="h6 ml-5">{{ currentAgent | agentFullName }}</span>
        </span>
        <span class="ml-auto pl-4" v-if="isSelected(currentAgent)">
          <font-awesome-icon icon="check" />
        </span>
      </a>

      <!-- Un-select -->
      <button
        class="dropdown-item d-flex align-items-center"
        :class="{ 'text-primary': !selectedAgent }"
        @click="selectAgent(null)"
        v-if="allowUnselecting"
      >
        <span>
          <div class="d-inline-block text-center" style="min-width: 3.125rem">
            <font-awesome-icon :icon="unassignedIcon" class="fa-2x" />
          </div>
          <span class="h6 ml-5">{{ unselectedLabel }}</span>
        </span>
        <span class="ml-auto pl-4" v-if="!selectedAgent">
          <font-awesome-icon icon="check" />
        </span>
      </button>

      <!-- Team -->
      <h6 class="dropdown-header" :class="cssText">
        {{ hintLabel }}
      </h6>
      <button
        v-for="agent in availableAgents"
        :key="agent.id"
        class="dropdown-item d-flex align-items-center"
        :class="{ 'text-primary': isSelected(agent) }"
        @click="selectAgent(agent)"
      >
        <div class="align-self-start mt-n1">
          <avatar :user="agent" size="md" :online="isOnline(agent)" />
        </div>
        <div class="ml-5">
          <div class="h6 mb-0">{{ agent | agentFullName }}</div>
          <div class="mt-n1 text-muted">
            <small>{{ agent | agentTitle }}</small>
          </div>
        </div>
        <div
          class="ml-auto pl-4 align-self-start mt-n1"
          v-if="isSelected(agent)"
        >
          <font-awesome-icon icon="check" />
        </div>
      </button>
    </div>
  </dropdown>
</template>

<script>
export default {
  name: 'AgentPicker',
  props: {
    agent: { type: Object, default: null },
    agentId: { type: String, default: null },
    groupName: { default: null, required: true },
    allowUnselecting: { default: false },
    strictlyInGroup: { default: false },
    hintLabel: { default: 'Choose someone in the team' },
    unselectedLabel: { default: 'Not selected' },
    small: { default: false },
    unassignedIcon: { default: 'user-cog' },
    fullWidth: { type: Boolean, default: false },
    dropup: { type: Boolean, default: false },
    skipDisabled: { type: Boolean, default: true },
  },
  data() {
    return {
      groups: [],
      agents: [],
      selectedAgent: null,
    };
  },
  computed: {
    group() {
      return this.groups[0];
    },
    availableAgents() {
      if (!this.group) return [];
      return this.services.agent.sortByOnlineStatus(
        this.services.agent.filter(this.agents, {
          ids: this.group.agentIds,
          withoutCurrentAgent: true,
          skipDisabled: this.skipDisabled,
        })
      );
    },
    showCurrentAgentSelector() {
      return (
        !this.strictlyInGroup ||
        (this.group && this.group.agentIds.indexOf(this.currentAgent.id) !== -1)
      );
    },
  },
  methods: {
    selectAgent(agent) {
      if (!this.isSelected(agent)) {
        this.$emit('select-agent', agent);
        this.$refs.dropdown.closeDropdown();
      }
    },
    selectAgentFromId() {
      if (this.isBlank(this.agentId) || this.isBlank(this.agents)) return;
      this.selectedAgent = this.agents.find(agent => {
        return (
          agent.id === this.agentId &&
          (!this.strictlyInGroup ||
            this.group.agentIds.indexOf(agent.id) !== -1)
        );
      });
    },
    isSameAgent(agent1, agent2) {
      if (!agent1 || !agent2) return false;
      return agent1?.id === agent2?.id || agent1?.email === agent2?.email;
    },
    isSelected(agent) {
      return this.isSameAgent(this.selectedAgent, agent);
    },
    isOnline(agent) {
      return agent.online ? true : undefined;
    },
  },
  watch: {
    agent: {
      immediate: true,
      handler() {
        this.selectedAgent = this.agent;
      },
    },
    agentId: {
      immediate: true,
      handler() {
        this.selectAgentFromId();
      },
    },
    groupName: {
      immediate: true,
      handler() {
        if (!this.groupName) return;
        this.$bind(
          'groups',
          this.services.agentGroup.findByName(this.groupName)
        );
      },
    },
    async group() {
      await this.$bind('agents', this.services.agent.findAll());
      this.selectAgentFromId();
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  font-size: 1rem;
  max-height: 40rem;
  &.small {
    max-height: 15rem;
  }
}
</style>
