<template>
  <modal :name="modalName" width="650" height="auto" scrollable>
    <div class="modal-content">
      <div
        class="modal-header border-0 py-7 pb-0 px-6 d-flex align-items-center"
      >
        <div class="modal-title d-flex align-items-center">
          <font-awesome-icon :icon="topic.icon" />
          <h4 class="font-weight-bold mb-0 ml-3">
            {{ title }}
          </h4>
        </div>
        <div class="ml-auto">
          <button
            class="btn btn-xl btn-link text-muted p-0"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <font-awesome-icon :icon="['fal', 'times']" size="lg" />
          </button>
        </div>
      </div>
      <div class="modal-body pt-0 px-0" v-if="rawList">
        <search-input
          @search="search"
          class="mt-0 mb-2 px-6"
          v-if="searchable"
        />

        <div class="pt-4 pb-6 text-center" v-if="isListEmpty">
          <p>{{ $t(`${i18nScope}.noResults`) }}</p>
          <div class="mt-2" v-if="query">
            <a
              :href="newItemUrl"
              target="_blank"
              class="btn btn-sm btn-outline-primary"
              v-if="$policy('medicalReportLibrary', null, 'create')"
            >
              {{ $t(`${i18nScope}.add`, { name: query }) }}
            </a>
          </div>
        </div>

        <div v-else>
          <library-item-categories
            class="mt-6 px-6 pb-12"
            :topic="topic"
            :categories="list"
            :selectedItems="selectedItems"
            @select="select"
            v-if="hasCategories"
          />

          <library-item-list
            class="px-6 pb-12"
            :topic="topic"
            :list="list"
            :selectedItems="selectedItems"
            @select="select"
            v-else
          />

          <div class="px-6 pb-3 mt-3 text-right" v-if="multiple">
            <button
              class="btn btn-primary"
              @click="validate"
              :disabled="selectedItems.length === 0"
            >
              {{ $t(`${i18nScope}.submit`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ADMIN_URL } from '@/shared/config';
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import LibraryItemCategories from './categories';
import LibraryItemList from './list';

export default {
  name: 'MedicalReportPicker',
  components: { LibraryItemCategories, LibraryItemList },
  mixins: [MedicalReportLibraryMixin],
  props: {
    name: String,
    kid: { type: Object, required: false },
    topicName: { type: String, required: true },
    title: { type: String, required: true },
    searchable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    alreadySelectedItemIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      rawList: null,
      query: null,
      selectedItems: [],
    };
  },
  computed: {
    service() {
      return this.services.medicalReportLibrary;
    },
    modalName() {
      return this.name || this.topicName;
    },
    hasCategories() {
      return this.topic.maxDepth > 1;
    },
    isListEmpty() {
      if (this.hasCategories) {
        return this.list.every(category => category.children.length === 0);
      } else return this.list.length === 0;
    },
    filters() {
      return {
        kid: this.kid,
        query: this.query,
        rejectedIds: this.alreadySelectedItemIds,
      };
    },
    list() {
      return this.service.buildContentTree(
        this.service.filter(this.rawList, this.currentAgentGroupIds),
        this.uiLocale,
        this.filters
      );
    },
    newItemUrl() {
      return `${ADMIN_URL}/settings/saved-medical-report-library/${this.topicName}/new?title=${this.query}`;
    },
    i18nScope() {
      return 'inbox.room.medicalReport.picker';
    },
  },
  methods: {
    select(libraryItem, alreadySelected) {
      if (!alreadySelected) this.addItem(libraryItem);
      else this.deleteItem(libraryItem);

      if (!this.multiple) this.validate();
    },
    addItem(libraryItem) {
      this.selectedItems = [].concat(this.selectedItems, [libraryItem]);
    },
    deleteItem(libraryItem) {
      this.selectedItems = this.selectedItems.filter(
        item => libraryItem.id !== item.id
      );
    },
    validate() {
      this.selectedItems.forEach(libraryItem => {
        this.$emit('select', libraryItem);
      });
      this.close();
    },
    close() {
      this.query = null;
      this.selectedItems = [];
      this.$modal.hide(this.modalName);
    },
    search(query) {
      this.query = query;
      this.selectedItems = [];
    },
    load() {
      this.$bind('rawList', this.service.findAll(this.topic.name));
    },
  },
  watch: {
    topic: {
      immediate: true,
      handler() {
        this.load();
      },
    },
    currentAgentGroupIds: {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },
};
</script>
