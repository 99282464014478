<template>
  <div>
    <slot name="title">
      <h6 class="d-flex align-items-center text-body">
        <font-awesome-icon :icon="topic.icon" />
        <span class="ml-3">{{
          $tc(`inbox.room.medicalReport.${topicName}.title`, list.length)
        }}</span>
      </h6>
    </slot>

    <div class="d-flex mt-3 mb-0">
      <transition-group
        name="list-complete"
        tag="ul"
        :appear="false"
        class="list-inline mb-0"
      >
        <library-item
          v-for="(libraryItem, index) in list"
          :key="`${topicName}-${index}`"
          :editable="editable"
          :libraryItem="libraryItem"
          @delete="remove(libraryItem)"
          class="mb-3"
        />
        <li
          class="list-inline-item list-complete-item mb-3"
          v-if="allowAdd"
          :key="`add-${topicName}`"
        >
          <button
            class="btn btn-sm btn-outline-primary"
            type="button"
            @click="showPicker"
          >
            <font-awesome-icon :icon="['fal', 'plus']" size="lg" />
          </button>
        </li>

        <li
          class="list-inline-item list-complete-item mb-3"
          v-if="allowRDLetter"
          :key="`rd-letter-${topicName}`"
        >
          <show-rd-letter-button
            :isNewRDLetter="isNewRDLetter"
            @click="showRDLetterModal"
            v-if="$policy('rdLetter', null, 'show')"
          />
        </li>
      </transition-group>
    </div>

    <medical-report-picker
      :name="`${topicName}-picker`"
      :topicName="topicName"
      :kid="kid"
      :searchable="topic.searchable"
      :multiple="topic.multiple"
      :title="$i18n.t(`inbox.room.medicalReport.${topicName}.pickerTitle`)"
      :alreadySelectedItemIds="alreadySelectedItemIds"
      @select="add"
      v-if="editable"
    />
  </div>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import MedicalReportPicker from './picker';
import ShowRdLetterButton from './rd-letter/show-button';
import LibraryItem from './library-item';

export default {
  name: 'MedicalReportItemInput',
  components: { MedicalReportPicker, LibraryItem, ShowRdLetterButton },
  mixins: [MedicalReportLibraryMixin],
  props: {
    topicName: String,
    editable: { type: Boolean, default: true },
    list: { type: Array, default: () => [] },
    letterBody: { type: Object, default: null },
    kid: Object,
  },
  computed: {
    allowAdd() {
      return (
        this.editable && (!this.topic.singleItem || this.list.length === 0)
      );
    },
    alreadySelectedItemIds() {
      return this.list.map(item => item.id);
    },
    allowRDLetter() {
      return (
        this.editable &&
        this.topic.triggerRDLetter &&
        this.list.some(item => item.triggerRDLetter)
      );
    },
    isNewRDLetter() {
      return this.isBlank(this.letterBody);
    },
  },
  methods: {
    showPicker() {
      this.$modal.show(`${this.topicName}-picker`);
    },
    showRDLetterModal() {
      this.$modal.show(`medical-report-rd-letter-modal`);
    },
    add(libraryItem) {
      const {
        id,
        translatedTitle,
        severity,
        hidden,
        canBeHidden,
        triggerRDLetter,
      } = libraryItem;
      this.$emit('add', this.topicName, {
        id,
        severity,
        hidden,
        canBeHidden,
        triggerRDLetter: triggerRDLetter || false,
        title: translatedTitle,
      });
    },
    remove(libraryItem) {
      this.$emit('remove', this.topicName, libraryItem);
    },
  },
};
</script>
