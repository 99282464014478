<template>
  <div class="hide-scrollbar">
    <div class="container-fluid py-6">
      <!-- Title -->
      <h2 class="font-bold mb-6">{{ $t('myProfile.title') }}</h2>
      <!-- Title -->

      <div class="card mb-6">
        <div class="card-body">
          <div class="text-center py-6" v-if="agent">
            <!-- Photo -->
            <avatar :user="agent" size="xl" />

            <h5 class="mt-5">{{ getAgentFullName }}</h5>
            <p class="text-muted mb-0">Agent ID: {{ agent.id }}</p>
            <p class="text-muted">App REV.: {{ localAppRevision }}</p>

            <!-- Button -->
            <button
              type="button"
              class="btn btn-block btn-secondary d-flex align-items-center"
              v-on:click="signOutAction"
            >
              {{ $t('myProfile.signOut') }}
              <font-awesome-icon :icon="['fal', 'sign-out']" class="ml-auto" />
            </button>

            <a
              href="https://privacy-pro.bilo.ba"
              class="mt-4 d-block"
              target="_blank"
            >
              <small class="text-muted">{{
                $t('myProfile.privacyPolicy')
              }}</small>
            </a>
          </div>
        </div>
      </div>

      <Form />

      <NewPassword />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Form from './form';
import NewPassword from './new_password';

export default {
  name: 'Profile',
  components: { Form, NewPassword },
  methods: {
    ...mapActions(['signOutAction']),
  },
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['getAgentFullName']),
  },
};
</script>
