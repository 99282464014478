import { endOfDay } from '@/shared/utils';

export default {
  data() {
    return {
      date: endOfDay(new Date()),
      rooms: [],
      nextRooms: [],
      isLoading: true,
      isLoadingMoreRooms: false,
      hasMoreRooms: false,
    };
  },
  computed: {
    isEmpty() {
      return this.rooms.length === 0;
    },
    canLoadMoreRooms() {
      return (
        this.hasMoreRooms &&
        !this.isLoadingMoreRooms &&
        this.nextRooms.length === 0
      );
    },
  },
  methods: {
    loadInitialRooms() {
      this.hasMoreRooms = false;
      this.isLoading = true;
      return this.loadRooms(this.date).then(([rooms, hasMoreRooms]) => {
        this.rooms = rooms;
        this.isLoading = false;
        this.hasMoreRooms = hasMoreRooms;
      });
    },
    loadMoreRooms() {
      if (!this.canLoadMoreRooms) return;
      var lastRoom = this.rooms.slice(-1)[0];
      if (lastRoom) {
        var from = this.getDateFromLastRoom(lastRoom);
        this.isLoadingMoreRooms = true;
        this.loadRooms(from, lastRoom.id).then(([rooms, hasMoreRooms]) => {
          this.nextRooms = rooms;
          this.hasMoreRooms = hasMoreRooms;
          this.isLoadingMoreRooms = false;
        });
      }
    },
    addNextRooms() {
      this.rooms = [].concat(this.rooms, this.nextRooms);
      this.nextRooms = [];
    },
    getDateFromLastRoom(lastRoom) {
      return lastRoom.lastMessage.createdAt.toDate();
    },
  },
};
