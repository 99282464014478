<template>
  <agent-picker
    :groupName="groupNames.MEDICAL_REPORT_ASSIGNEES"
    :agent="agent"
    :hintLabel="$t('inbox.room.medicalReport.agentPicker.hint')"
    @select-agent="assignAgent"
    :small="true"
    v-if="medicalReport"
  />
</template>

<script>
export default {
  name: 'MedicalReportAgentPicker',
  props: {
    medicalReport: { default: null },
  },
  computed: {
    agent() {
      return this.medicalReport.agent;
    },
  },
  methods: {
    assignAgent(agent) {
      this.$emit('assign-agent', agent);
    },
  },
};
</script>
