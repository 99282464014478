<template>
  <div
    class="media-body align-self-center d-flex align-items-center mb-n2"
    v-if="room"
  >
    <h4 class="mb-0">
      <router-link :to="{ name: 'user', params: { userId: room.user.id } }">
        <u :class="cssText">{{ title }}</u>
      </router-link>
    </h4>

    <transition name="slide-fade">
      <div class="d-flex align-items-center" v-if="room.kid && !compactDisplay">
        <font-awesome-icon icon="chevron-right" class="mx-8" :class="cssText" />

        <kid-info-title :kid="room.kid" :relativeDate="room.createdAt" />
      </div>
    </transition>
  </div>
</template>

<script>
import KidInfoTitle from '@/components/rooms/shared/kid-info-title';

export default {
  name: 'RoomTitle',
  components: { KidInfoTitle },
  props: {
    room: { type: Object, default: undefined },
    compactDisplay: { type: Boolean, default: false },
  },
  computed: {
    title() {
      return this.room.user && this.room.user.firstName
        ? this.room.user.firstName
        : this.room.id;
    },
  },
};
</script>
