<template>
  <div class="d-flex align-self-center h-100" :class="cssText">
    <!-- Re-open -->
    <a
      class="btn btn-outline-secondary"
      role="button"
      @click="reOpenRoom"
      v-if="$policy('room', room, 'reOpen')"
    >
      <font-awesome-icon :icon="['fas', 'inbox-out']" class="mr-4" />
      {{ $t('inbox.statusHints.open') }}
    </a>

    <!-- Open the medical report -->
    <a
      class="btn btn-outline-secondary ml-5"
      role="button"
      @click="$emit('open-medical-report')"
      v-if="$policy('medicalReport', { room }, 'open')"
    >
      <font-awesome-icon :icon="['fal', 'notes-medical']" class="mr-4" />
      {{ $t('inbox.statusHints.openMedicalReport') }}
    </a>

    <!-- Open the prescription report -->
    <a
      class="btn btn-outline-secondary ml-5"
      role="button"
      @click="$emit('open-prescription-board')"
      v-if="$policy('prescriptionBoard', { room }, 'openReadOnly')"
    >
      <font-awesome-icon
        :icon="['fal', 'clipboard-prescription']"
        class="mr-4"
      />
      {{ $t('inbox.statusHints.openPrescriptionBoard') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'RoomClosedStatus',
  props: {
    room: {
      type: Object,
      default: null,
    },
  },
  methods: {
    reOpenRoom() {
      this.services.room.reOpen(this.room, this.currentAgent);
    },
  },
};
</script>
