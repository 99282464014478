<template>
  <router-link
    :to="{
      name: 'prescription',
      params: { prescriptionId: prescription.id },
      query: filterParams,
    }"
    tag="div"
    class="media align-items-center"
  >
    <div class="media-body" v-if="!loading">
      <div class="d-flex">
        <div class="mr-auto">
          <h5 class="prescription-list-item-kid-name mb-0 font-bold">
            {{ kid.firstName }}
          </h5>
          <p class="prescription-list-item-kid-info">
            {{ $t('birthday.past', { age: kidAge }) }}
            ({{ prescription.kidWeightInKg | formatNumber }} kg)
          </p>
        </div>
        <div class="prescription-list-item-date">
          {{ formattedDate }}
        </div>
      </div>
      <div class="prescription-list-item-agent d-flex align-items-center mt-2">
        <avatar class="mr-3" :user="agent" size="xs" />
        <strong :class="cssText">{{ agent | agentFullName }}</strong>
      </div>
    </div>
  </router-link>
</template>

<script>
import { kidAgeFromBirthday, formatDateFromString } from '@/shared/utils';

export default {
  name: 'PrescriptionItem',
  props: {
    prescription: { type: Object, default: undefined },
    filterParams: { type: Object, default: undefined },
  },
  data() {
    return { kid: null, agent: null, loading: true };
  },
  async mounted() {
    // TODO: move this to the API endpoint instead
    const { userId, kidId, agentId } = this.prescription;
    this.kid = await this.services.kid.loadByExternalId(userId, kidId);
    this.agent = await this.services.agent.load(agentId);
    this.loading = false;
  },
  computed: {
    formattedDate() {
      return formatDateFromString(this.prescription.createdAt);
    },
    kidAge() {
      return kidAgeFromBirthday(
        this.kid.birthDate,
        this.kid.gender,
        this.prescription.createdAt,
        true
      );
    },
  },
};
</script>
