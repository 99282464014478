<template>
  <modal
    :name="modalName"
    width="650"
    height="auto"
    @before-open="loadLetter"
    scrollable
  >
    <div class="modal-content">
      <div
        class="modal-header border-0 py-7 pb-0 px-6 d-flex align-items-center"
      >
        <div class="modal-title d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ $i18n.t(`${i18nScope}.title`) }}
          </h4>
          <custom-switch
            v-model="rawModeEnabled"
            class="mt-1 ml-8"
            v-if="!isLoading && $policy('rdLetter', null, 'editInAdvancedMode')"
          />
        </div>
        <div class="ml-auto">
          <button
            class="btn btn-xl btn-link text-muted p-0"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <font-awesome-icon :icon="['fal', 'times']" size="lg" />
          </button>
        </div>
      </div>
      <div class="modal-body pt-0 px-0">
        <loader style="min-height: 10vh" :isLoading="isLoading">
          <div class="px-6">
            <p v-html="header" class="my-4 mb-0 font-semi-bold" />

            <p v-html="intro" class="my-4" />

            <div v-if="rawModeEnabled">
              <textarea
                class="form-control"
                rows="8"
                :placeholder="$i18n.t(`${i18nScope}.raw.placeholder`)"
                v-model="body.raw"
              />
            </div>

            <div v-else>
              <p v-html="body.causes" class="my-4" />
              <div class="form-group mt-2" v-if="false">
                <textarea class="form-control" rows="2" v-model="body.causes" />
              </div>

              <p v-html="body.diagnosis" class="my-4" />
              <div class="form-group mt-2" v-if="false">
                <textarea
                  class="form-control"
                  rows="2"
                  v-model="body.diagnosis"
                />
              </div>

              <p v-html="body.supervisions" class="my-4" />
              <div class="form-group mt-2" v-if="false">
                <textarea
                  class="form-control"
                  rows="2"
                  v-model="body.supervisions"
                />
              </div>

              <div class="form-group mt-8 mb-2">
                <textarea
                  class="form-control"
                  rows="2"
                  :placeholder="$i18n.t(`${i18nScope}.comment.placeholder`)"
                  v-model="body.comment"
                />
              </div>
            </div>

            <p
              v-for="(footerParagraph, index) in footer"
              :key="index"
              v-html="footerParagraph"
              class="mt-8"
            />

            <div
              class="mt-3 pb-3 d-flex align-items-center justify-content-end"
            >
              <destroy-button
                :text="$t(`${i18nScope}.destroy.text`)"
                :buttonLabel="$t(`${i18nScope}.destroy.text`)"
                buttonSize="xl"
                :dropup="true"
                @destroy="clearRDLetter"
                v-if="!isNewRDLetter"
                class="mr-4"
              />

              <button
                class="btn btn-primary btn-xl"
                @click="validate"
                :disabled="body.length === 0"
              >
                {{ $t(`${i18nScope}.submit`) }}
              </button>
            </div>
          </div>
        </loader>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'MedicalReportRDLetterModal',
  props: {
    name: String,
    medicalReport: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: true,
      header: '',
      intro: '',
      body: { causes: '', diagnosis: '', supervisions: '', comment: '' },
      footer: '',
      rawModeEnabled: false,
    };
  },
  computed: {
    isNewRDLetter() {
      return this.isBlank(this.medicalReport.letterBody);
    },
    modalName() {
      return `medical-report-rd-letter-modal`;
    },
    i18nScope() {
      return 'inbox.room.medicalReport.rdLetter';
    },
  },
  methods: {
    validate() {
      // clear the raw if the agent doesn't want it
      if (!this.rawModeEnabled) this.body.raw = '';

      // update the medical report
      this.$emit('change', { letterBody: this.body });
      // don't forget to update the room too
      this.services.room.updateRdLetterPresence(
        { id: this.medicalReport.roomId },
        true
      );
      this.close();
    },
    clearRDLetter() {
      // update the medical report
      this.$emit('change', { letterBody: null });
      // don't forget to update the room too
      this.services.room.updateRdLetterPresence(
        { id: this.medicalReport.roomId },
        false
      );
      this.close();
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async loadLetter() {
      this.isLoading = true;
      const {
        header,
        body: { intro, ...body },
        footer,
      } = await this.services.medicalReport.findRDLetter(this.medicalReport.id);
      this.header = header;
      this.intro = intro;
      this.body = body;
      this.footer = footer;
      this.isLoading = false;
      this.rawModeEnabled = !this.isBlank(this.body.raw);
    },
  },
  watch: {
    rawModeEnabled() {
      if (this.rawModeEnabled && this.isBlank(this.body.raw)) {
        this.body.raw = [
          this.body.causes,
          this.body.diagnosis,
          this.body.supervisions,
          '',
        ].join('\n\n');
      }
    },
  },
};
</script>
