<template>
  <div class="form-group mb-1">
    <label :for="name">{{ t('label') }}</label>

    <transition-group
      name="list"
      tag="ul"
      class="list-group list-group-flush ml-4"
    >
      <li
        v-for="dosage in dosages"
        :key="dosage.id"
        class="list-group-item py-6"
      >
        <array-item-nested-model-form
          v-model="value"
          :model="dosage"
          i18nScope="admin.drugs.form.dosages.form"
          class="pt-4"
        >
          <div class="position-relative">
            <text-input attributeName="name" :max-characters="300" />
            <div class="position-absolute" style="top: -1rem; right: 0">
              <destroy-button
                :text="t('destroy.text')"
                :buttonLabel="t('destroy.button')"
                @destroy="onRemove(dosage)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <text-input attributeName="shortLabel" />
            </div>
            <div class="col">
              <text-input attributeName="mobileLabel" />
            </div>
          </div>
        </array-item-nested-model-form>
      </li>
    </transition-group>

    <div class="mt-6 d-flex">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm ml-auto"
        :class="cssText"
        v-on:click.stop.prevent="onAdd"
        role="addDosage"
      >
        {{ t('addButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'DosagesInput',
  mixins: [ModelInputMixin],
  computed: {
    dosages() {
      return (this.value || []).filter(dosage => dosage.deleted !== true);
    },
  },
  methods: {
    onAdd() {
      this.value = [...this.value, this.services.drug.buildDosage()];
    },
    onRemove(dosage) {
      const index = this.value.findIndex(el => el.id === dosage.id);
      let newValue = this.value.slice();
      if (dosage.isNew) {
        newValue = [
          ...this.value.slice(0, index),
          ...this.value.slice(index + 1),
        ];
      } else {
        const updatedDosage = merge.all([{}, dosage, { deleted: true }]);
        newValue[index] = updatedDosage;
      }
      this.value = newValue;
    },
  },
};
</script>
