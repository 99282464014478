<template>
  <div class="sidebar-header chat-header border-bottom">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <transition
            name="slide-fade"
            v-on:after-enter="afterSidebarClosed"
            v-on:after-leave="afterSidebarDisplayed"
          >
            <div class="media text-center">
              <Title :room="room" v-if="room" :compactDisplay="showSidebar" />
            </div>
          </transition>

          <!-- Chat toolbar -->
          <ul class="nav ml-auto" v-if="room">
            <li class="nav-item list-inline-item mr-3">
              <Status :room="room" :compactDisplay="showSidebar" />
            </li>
            <li
              class="nav-item list-inline-item mr-3"
              v-if="$p('room', room, 'assignToAnyone')"
            >
              <Agents :room="room" />
            </li>
            <li class="nav-item list-inline-item mr-0">
              <Info :room="room" />
            </li>
            <li
              class="nav-item list-inline-item ml-5"
              v-if="!showSidebar && isShowSidebarButtonDisplayed && room.kid"
            >
              <a
                class="btn btn-sm btn-outline-secondary h-100"
                :class="cssText"
                @click="$emit('open-sidebar')"
              >
                <font-awesome-icon :icon="['far', 'angle-double-left']" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- .row -->
    </div>
  </div>
</template>

<script>
import Agents from './agents';
import Info from './info';
import Status from './status';
import Title from './title';

export default {
  name: 'RoomHeader',
  components: {
    Agents,
    Info,
    Status,
    Title,
  },
  props: {
    room: { type: Object, default: null },
    showSidebar: { type: Boolean, default: false },
  },
  data() {
    return {
      isShowSidebarButtonDisplayed: true,
    };
  },
  methods: {
    afterSidebarDisplayed() {
      this.isShowSidebarButtonDisplayed = false;
    },
    afterSidebarClosed() {
      this.isShowSidebarButtonDisplayed = true;
    },
  },
};
</script>
