<template>
  <div style="min-h-4">
    <router-link
      v-for="drug in brandedDrug.drugs"
      :key="drug.id"
      :to="{
        name: 'editDrug',
        params: { brandedDrugId: brandedDrug.id, drugId: drug.id },
      }"
      tag="div"
      class="mb-4 cursor-pointer"
      activeClass="font-weight-bold text-dark"
      title="drug-name"
    >
      {{ drug.shortLabel }}
    </router-link>

    <div class="mt-8">
      <router-link
        :to="{ name: 'newDrug', params: { brandedDrugId: brandedDrug.id } }"
        class="btn btn-primary btn-sm btn-block"
      >
        {{ $t('admin.drugs.newButton') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DrugList',
  props: {
    brandedDrug: { type: Object },
    drugId: { type: String },
  },
};
</script>
