<template>
  <div class="d-flex h-100">
    <div class="d-flex flex-column flex-grow-1">
      <div>
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold mb-0">
            <font-awesome-icon :icon="['fad', 'pills']" class="mr-5" />
            {{ $t('admin.brandedDrugs.list.title') }}
          </h2>
        </div>
        <div class="pt-6">
          <div class="container-fluid mb-6">
            <router-link
              :to="{ name: 'newBrandedDrug' }"
              class="btn btn-primary btn-block"
            >
              {{ $t('admin.brandedDrugs.list.newButton') }}
            </router-link>

            <p class="text-center">
              <router-link :to="{ name: 'admin' }" class="btn btn-link">
                <font-awesome-icon
                  :icon="['fal', 'long-arrow-left']"
                  class="icon-md"
                />
                {{ $t('admin.brandedDrugs.list.backButton') }}
              </router-link>
            </p>
          </div>
        </div>
      </div>

      <div class="flex-grow-2 overflow-auto">
        <div class="pb-6">
          <list
            :brandedDrugId="brandedDrugId"
            :drugId="drugId"
            :class="{ 'list-group': true }"
            :list="brandedDrugs"
            v-if="brandedDrugs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import List from './list';

export default {
  name: 'BrandedDrugSidebar',
  components: { List },
  props: ['brandedDrugId', 'drugId'],
  computed: {
    ...mapState(['brandedDrugs']),
  },
  methods: {
    ...mapActions(['listBrandedDrugs']),
  },
  mounted() {
    this.listBrandedDrugs();
  },
};
</script>
