<template>
  <div class="rich-text-input">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="d-flex align-items-center">
        <div>
          <dropdown
            :toggle="false"
            color="primary"
            :dropup="true"
            customClass="dropdown--emoji"
            ref="emojiDropdown"
          >
            <template v-slot:button>
              <span>
                <font-awesome-icon :icon="['fal', 'smile']" />
              </span>
            </template>

            <VEmojiPicker
              v-show="true"
              :labelSearch="$i18n.t(`inbox.room.form.emoji.search`)"
              @select="placeholders.emoji"
            />
          </dropdown>
        </div>

        <div
          class="btn-group ml-5"
          role="group"
          aria-label="Rich text menu bar"
        >
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            :class="{ active: isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <font-awesome-icon :icon="['far', 'list-ul']" />
          </button>
        </div>

        <div class="ml-5">
          <dropdown
            :toggle="true"
            menuAlign="left"
            ref="placeholdersDropdown"
            color="primary"
          >
            <template v-slot:button>
              <span class="mr-2">
                <font-awesome-icon :icon="['fal', 'bolt']" />
              </span>
            </template>

            <button
              type="button"
              class="dropdown-item"
              @click="placeholders.userFirstName"
            >
              {{ $t('admin.savedReplies.form.placeholders.userFirstName') }}
            </button>
            <button
              type="button"
              class="dropdown-item"
              @click="placeholders.kidFirstName"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidFirstName') }}
            </button>
            <button
              type="button"
              class="dropdown-item"
              @click="placeholders.kidGenderPronoun"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidGenderPronoun') }}
            </button>
            <button
              type="button"
              class="dropdown-item"
              @click="placeholders.kidGenderAgreement"
              v-if="language === 'fr'"
            >
              {{
                $t('admin.savedReplies.form.placeholders.kidGenderAgreement')
              }}
            </button>
            <button
              type="button"
              class="dropdown-item"
              @click="placeholders.kidGenderArticle"
              v-if="language === 'fr'"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidGenderArticle') }}
            </button>
          </dropdown>
        </div>
      </div>
    </editor-menu-bar>

    <div class="form-control h-100 mt-4">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
// TODO: use a slot to customize the editor menu bar

import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { HardBreak, ListItem, BulletList } from 'tiptap-extensions';
import VEmojiPicker from 'v-emoji-picker';
import { insertHTML } from '@/shared/tiptap';

export default {
  name: 'RichTextInput',
  components: { EditorContent, EditorMenuBar, VEmojiPicker },
  props: ['value', 'language'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [new HardBreak(), new ListItem(), new BulletList()],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        var content = getHTML();
        this.$emit('input', content);
      },
    });
  },
  computed: {
    placeholders() {
      return {
        userFirstName: () => {
          insertHTML(this.editor, '{userFirstName}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidFirstName: () => {
          insertHTML(this.editor, '{kidFirstName}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderPronoun: () => {
          insertHTML(this.editor, '{kidGenderPronoun}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderAgreement: () => {
          insertHTML(this.editor, '{kidGenderAgreement}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderArticle: () => {
          insertHTML(this.editor, '{kidGenderArticle}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        emoji: emoji => {
          insertHTML(this.editor, emoji.data);
          this.$refs.emojiDropdown.closeDropdown();
        },
      };
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
