import { getDB } from '@/shared/db';
import { track as trackAgent } from './agent-tracking';

export const findAll = () => {
  trackAgent('VIEW', 'USERS');
  return getDB()
    .collection('users')
    .orderBy('lastName', 'asc');
};

export const find = id => {
  trackAgent('VIEW', 'USER', id);
  return getDB()
    .collection('users')
    .doc(id);
};

export const load = id => {
  return find(id)
    .get()
    .then(document => document.data());
};
