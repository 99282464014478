<template>
  <div class="py-6">
    <div class="container-fluid mb-6">
      <model-form
        :model="brandedDrug"
        i18nScope="admin.brandedDrugs.form"
        :validate="services.brandedDrug.isValid"
        :on-submit="persist"
        v-on="$listeners"
      >
        <text-input attributeName="name" :max-characters="50" />

        <div class="row">
          <div class="col">
            <text-input attributeName="shortLabel" />
          </div>
          <div class="col">
            <text-input attributeName="mobileLabel" />
          </div>
        </div>

        <molecules-input attributeName="molecules" />

        <hr class="mt-7" />

        <nested-model-form
          v-model="brandedDrug.filters"
          i18nScope="admin.brandedDrugs.form.filters"
        >
          <agent-group-input attributeName="agentGroupIds" class="mb-6" />

          <div class="row mt-3 mb-8">
            <div class="col-3">
              <gender-input attributeName="gender" class="mb-0" />
            </div>
            <div class="col-9">
              <age-range-input attributeName="age" class="mb-0 h-100" />
            </div>
          </div>
        </nested-model-form>
      </model-form>
    </div>
  </div>
</template>

<script>
import MoleculesInput from './molecules-input';

export default {
  name: 'BrandedDrugForm',
  components: { MoleculesInput },
  props: {
    brandedDrug: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
