<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.brandedDrugs.new.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <branded-drug-form
            :brandedDrug="brandedDrug"
            :persist="onPersist"
            @change="onChange"
            v-if="brandedDrug"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import BrandedDrugForm from './form';

export default {
  name: 'NewBrandedDrug',
  components: { BrandedDrugForm },
  data() {
    return { brandedDrug: null };
  },
  mounted() {
    this.brandedDrug = this.services.brandedDrug.build();
  },
  methods: {
    ...mapActions(['createBrandedDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.brandedDrug = merge(this.brandedDrug, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.createBrandedDrug(this.brandedDrug).then(brandedDrug => {
        this.$router.push({
          name: 'editBrandedDrug',
          params: { brandedDrugId: brandedDrug.id },
        });
      });
    },
  },
};
</script>
