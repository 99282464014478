<template>
  <div
    id="app"
    class="app"
    :class="{
      'app--warning':
        newVersionReady ||
        systemErrorDetected ||
        displayClosed ||
        displayDrowning,
    }"
  >
    <div
      v-if="appLoading"
      class="d-flex align-items-center justify-content-center vh-100 w-100"
    >
      <loader :isLoading="true" :size="75" />
    </div>

    <div v-else>
      <div
        class="d-flex d-lg-none align-items-center justify-content-center vh-100 w-100"
      >
        <div>
          <div class="text-center mb-10">
            <img
              :src="logoURL"
              class="mx-auto fill-primary"
              data-inject-svg
              alt
              style="height: 76px;"
            />
          </div>

          <div class="alert alert-secondary mx-4" role="alert">
            <h4 class="alert-heading">{{ $t('disabledOnMobile.title') }}</h4>
            <div>
              {{ $t('disabledOnMobile.body') }}
            </div>
          </div>
        </div>
      </div>

      <!-- NEW VERSION -->
      <div
        v-if="newVersionReady"
        class="w-100 alert alert-dark border-0 rounded-0 mb-0 d-none d-md-flex align-items-center justify-content-center"
      >
        <span class="mr-6" v-html="$t('newRelease.text')" />

        <button
          class="btn btn-sm btn-outline btn-outline-secondary"
          v-on:click="reloadPage"
        >
          {{ $t('newRelease.button') }}
        </button>
      </div>
      <div
        v-if="displayDrowning && !systemErrorDetected && authenticated"
        class="w-100 alert alert-danger bg-dark-yellow text-dark capitalize-first border-0 rounded-0 mb-0 d-none d-md-flex align-items-center justify-content-center"
      >
        <span
          class="text-center"
          v-html="$t('inbox.room.almostDrowningWarning')"
        />
      </div>
      <div
        v-if="displayClosed && !systemErrorDetected && authenticated"
        class="w-100 alert alert-danger bg-light-blue text-gray-700 capitalize-first border-0 rounded-0 mb-0 d-none d-md-flex align-items-center justify-content-center"
      >
        <span class="text-center" v-html="$t('inbox.room.closed')" />
      </div>

      <!-- SYSTEM ERROR -->
      <div
        v-if="systemErrorDetected"
        class="w-100 alert alert-danger border-0 rounded-0 mb-0 d-none d-md-flex align-items-center justify-content-center"
      >
        <span class="text-center" v-html="$t('systemErrorDetected.text')" />
      </div>
      <div class="d-none d-lg-block">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { setUiLocale } from '@/plugins/i18n';
import { displayDrowning, displayClosed } from '@/shared/status';

export default {
  name: 'App',
  data() {
    return {
      newVersionReady: undefined,
      displayDrowning: false,
      displayClosed: false,
    };
  },
  computed: {
    systemErrorDetected() {
      return this.$store.state.systemError;
    },
  },
  methods: {
    reloadPage() {
      window.location.reload(true);
    },
  },
  watch: {
    currentAppVersion: {
      immediate: true,
      handler(newVersion, oldVersion) {
        this.newVersionReady = oldVersion && newVersion > oldVersion;
      },
    },
    authenticated(newValue) {
      if (this.appLoading && !newValue) {
        // force the browser to display the signIn page
        this.reloadPage();
      }
    },
    uiLocale: {
      immediate: true,
      handler(newLocale) {
        setUiLocale(newLocale);
      },
    },
    currentAppDynConfig: {
      immediate: true,
      handler(newDyn) {
        if (newDyn) {
          this.displayDrowning = displayDrowning(newDyn);
          this.displayClosed = displayClosed(newDyn);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '@/design/application.scss';
</style>
