import agentPolicy from './agent-policy';
import roomPolicy from './room-policy';
import adminPolicy from './admin-policy';
import savedReplyPolicy from './saved-reply-policy';
import medicalReportLibraryPolicy from './medical-report-library-policy';
import medicalReportPolicy from './medical-report-policy';
import prescriptionPolicy from './prescription-policy';
import prescriptionBoardPolicy from './prescription-board-policy';
import agentGroupPolicy from './agent-group-policy';
import rdLetterPolicy from './rd-letter-policy';

export default {
  agentPolicy,
  roomPolicy,
  adminPolicy,
  savedReplyPolicy,
  agentGroupPolicy,
  medicalReportLibraryPolicy,
  medicalReportPolicy,
  prescriptionBoardPolicy,
  prescriptionPolicy,
  rdLetterPolicy,
};
