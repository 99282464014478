<template>
  <div class="d-flex align-items-center kid-info-title">
    <img
      class="kid-image"
      :src="kidImageSrc"
      :style="{ height: iconSize }"
      :alt="kidName"
    />
    <div class="ml-4 text-left">
      <h5
        class="mb-0 font-weight-bold d-flex align-items-center"
        v-if="withName"
      >
        <span>
          {{ kidName }}
          <span v-if="kidLastNameFirstLetter"
            >{{ kidLastNameFirstLetter }}.</span
          >
        </span>
        <span
          class="ml-3"
          style="font-size: 1.25rem"
          v-tooltip="kidBirthDate"
          v-if="isAroundKidBirthday"
          >🎂
        </span>
      </h5>
      <div class="mt-n2" v-if="!basic" v-tooltip="kidBirthDate">
        <small v-if="isInThePast">{{
          $t('birthday.past', { age: kidAge })
        }}</small>
        <small v-else>{{ kidAge }}</small>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import {
  kidAgeFromBirthday,
  isAroundBirthday,
  formatDate,
} from '@/shared/utils';

export default {
  name: 'KidInfoTitle',
  props: {
    kid: { type: Object, required: true },
    basic: Boolean,
    relativeDate: { required: false, default: undefined },
    withoutName: { type: Boolean, default: false },
    withLastNameFirstLetter: { type: Boolean, default: false },
    iconSize: { type: String, default: '2rem' },
    forceInThePast: { type: Boolean, default: false },
  },
  computed: {
    withName() {
      return !this.withoutName;
    },
    isGirl() {
      return this.kid.gender === 0;
    },
    kidName() {
      return this.kid.firstName;
    },
    kidBirthDate() {
      return formatDate(this.kid.birthDate.toDate());
    },
    kidLastNameFirstLetter() {
      const firstLetter = this.kid?.lastName?.charAt(0);
      return this.withLastNameFirstLetter && firstLetter ? firstLetter : null;
    },
    kidAge() {
      return kidAgeFromBirthday(
        this.kid.birthDate,
        this.kid.gender,
        this.relativeDate
      );
    },
    isInThePast() {
      if (this.forceInThePast) return true;
      const currentKidAge = kidAgeFromBirthday(
        this.kid.birthDate,
        this.kid.gender
      );
      return this.relativeDate && this.kidAge !== currentKidAge;
    },
    kidImageSrc() {
      return require(`@/assets/kids/black-${this.isGirl ? 'girl' : 'boy'}.png`);
    },
    isAroundKidBirthday() {
      return isAroundBirthday(this.kid.birthDate);
    },
  },
};
</script>
