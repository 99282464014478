<template>
  <div class="mx-5 my-3" style="width: 40rem;">
    <div class="d-flex align-items-center">
      <h4>{{ $t('inbox.room.savedReplies.search.title') }}</h4>

      <locale-picker v-model="locale" class="ml-auto" />
    </div>

    <div class="row py-6">
      <div class="col-12">
        <form @submit.stop.prevent="search">
          <div class="form-group form-group-clear mb-0">
            <input
              type="text"
              v-model="query"
              class="form-control bg-light border-0"
              :placeholder="$t('inbox.room.savedReplies.search.placeholder')"
              :aria-label="$t('inbox.room.savedReplies.search.placeholder')"
            />
            <span class="form-control-clear" v-if="query">
              <button
                class="btn btn-link btn-ico"
                @click.stop.prevent="reset"
                type="button"
              >
                <font-awesome-icon :icon="['fal', 'times']" :class="cssText" />
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>

    <div class="pt-4 pb-6 text-center" v-if="hasNoResults">
      {{ $t('inbox.room.savedReplies.search.none') }}
    </div>

    <div
      class="pt-4 pb-4 overflow-auto"
      style="max-height: 20rem"
      v-if="hasResults && !currentItem"
    >
      <list :list="results" @click="selectItem" />
    </div>

    <div class="pt-0 pb-6" v-if="currentItem">
      <div class="mb-2">
        <button
          class="btn btn-link btn-md d-flex align-items-center"
          :class="cssText"
          @click="selectItem(null)"
        >
          <font-awesome-icon :icon="['fal', 'long-arrow-left']" class="mr-3" />
          {{ $t('inbox.room.savedReplies.search.buttons.back') }}
        </button>
      </div>

      <list-item :item="currentItem" @edit="editContent" @send="sendContent" />
    </div>
  </div>
</template>

<script>
import SavedRepliesMixin from '@/mixins/saved-replies';

export default {
  name: 'SavedReplySearch',
  mixins: [SavedRepliesMixin],
  props: {
    user: { type: Object },
    kid: { type: Object },
  },
  data() {
    return {
      query: null,
      results: null,
    };
  },
  computed: {
    hasResults() {
      return this.results && this.results.length > 0;
    },
    hasNoResults() {
      return this.query && this.results !== null && this.results.length === 0;
    },
  },
  methods: {
    reset() {
      this.currentItem = this.query = this.results = null;
    },
    search() {
      if (this.query && this.query.trim().length > 0) {
        this.results = this.service.search(this.tree, this.query);
        this.currentItem = null;
      } else {
        this.reset();
      }
    },
    selectItem(item) {
      this.currentItem = item;
    },
  },
};
</script>
