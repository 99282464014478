<template>
  <div class="tab-pane fade h-100 show active">
    <div class="d-flex flex-column h-100">
      <infinite-scrolling
        class="hide-scrollbar h-100"
        :onScroll="loadMoreRooms"
      >
        <div
          class="alert alert-warning rounded-0"
          role="alert"
          v-if="agent && agent.isDisabled"
        >
          {{ $t('agents.isDisabledWargin') }}
        </div>

        <div class="sidebar-header container-fluid">
          <div class="d-flex align-items-center">
            <avatar :user="agent" :online="isOnline" v-if="agent" size="lg" />
            <div class="ml-5">
              <h2 class="font-bold mb-0" v-if="agent">
                {{ fullname }}
              </h2>
              <h2 class="font-bold mb-0" v-if="agentNotFound">
                {{ $t('agents.agentNotFound') }}
              </h2>
              <div class="text-muted" v-if="agent">
                {{ agent | agentTitle }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="agent" class="mt-6">
          <div class="container-fluid mb-6">
            <router-link
              :to="{
                name: 'agentProfile',
                params: { agentId: agentId },
              }"
              class="btn btn-primary btn-block"
              v-if="$p('agent', agent, 'edit')"
            >
              {{ $t('agents.editButton') }}
            </router-link>

            <p class="text-center">
              <router-link :to="{ name: 'agents' }" class="btn btn-link">
                <font-awesome-icon
                  :icon="['fal', 'long-arrow-left']"
                  class="icon-md"
                />
                {{ $t('agents.rooms.backButton') }}
              </router-link>
            </p>
          </div>

          <div class="container-fluid mt-8 mb-6">
            <h4>{{ $t('agents.rooms.title') }}</h4>
          </div>

          <PaginatedRooms
            :activeRoomId="id"
            :agentId="agentId"
            ref="paginatedRooms"
          />
        </div>
      </infinite-scrolling>
    </div>
  </div>
</template>

<script>
import PaginatedRooms from '@/components/agents/rooms/paginated-list';

export default {
  name: 'AgentRooms',
  components: { PaginatedRooms },
  props: ['agentId', 'id'],
  data() {
    return {
      agent: undefined,
    };
  },
  computed: {
    fullname() {
      return this.agent?.firstName && this.agent?.lastName
        ? `${this.agent.firstName} ${this.agent.lastName}`
        : null;
    },
    isOnline() {
      return this.agent.online ? true : undefined;
    },
    agentNotFound() {
      return this.agent === null;
    },
  },
  methods: {
    loadMoreRooms() {
      this.$refs.paginatedRooms.loadMoreRooms();
    },
  },
  watch: {
    agentId: {
      immediate: true,
      handler(agentId) {
        this.$bind('agent', this.services.agent.find(agentId));
      },
    },
  },
};
</script>
