// insert a text at the cursor current position inside
// a Textarea + give the focus back as well.
export default {
  methods: {
    insertText(el, text, applyFn) {
      let startPos = el.selectionStart;
      let endPos = el.selectionEnd;
      let value = el.value;

      const newValue =
        value.substring(0, startPos) +
        text +
        value.substring(endPos, value.length);

      // the component might want to update its internal state
      applyFn(newValue);

      this.$nextTick(() => {
        el.focus();
        el.selectionStart = startPos + text.length;
        el.selectionEnd = startPos + text.length;
      });
    },
  },
};
