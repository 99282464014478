<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-8 col-lg-8">
                <h2 class="font-bold mb-0" v-if="drug">
                  {{ $t(`${i18nScope}.title`, { name: brandedDrug.name }) }}
                </h2>
              </div>
              <div class="col-4 col-lg-4 text-right">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-md ml-auto text-dark"
                  :class="{ 'cursor-default': isModified }"
                  @click="openPreviewModal"
                  :disabled="isModified"
                >
                  {{ $t(`${i18nScope}.previewButton`) }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <drug-form
            :key="drug.id"
            :drug="drug"
            :persist="onPersist"
            @change="onChange"
            v-if="drug"
          />
        </div>
      </div>
    </div>

    <preview-modal
      :brandedDrug="brandedDrug"
      :drug="drug"
      v-on="$listeners"
      v-if="drug"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import DrugForm from './form';
import PreviewModal from './preview-modal';

export default {
  name: 'EditDrug',
  components: { DrugForm, PreviewModal },
  props: ['brandedDrugId', 'drugId'],
  data() {
    return {
      brandedDrug: null,
      drug: null,
      isModified: false,
    };
  },
  created() {
    this.$watch(
      vm => (vm.brandedDrugId, vm.cleanDrugId, Date.now()),
      () => this.fetchDrug(),
      { immediate: true }
    );
  },
  computed: {
    i18nScope() {
      return 'admin.drugs.edit';
    },
    cleanDrugId() {
      return this.drugId.replace('%2F', '/');
    },
  },
  methods: {
    ...mapActions(['updateDrug']),
    onChange(changes) {
      this.isModified = true;
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.drug = merge.all([this.drug, changes, { id: this.cleanDrugId }], {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.updateDrug({
        brandedDrugId: this.brandedDrugId,
        drug: this.drug,
      }).then(drug => {
        this.drug = drug;
        this.isModified = false;
      });
    },
    async fetchDrug() {
      this.brandedDrug = await this.services.brandedDrug.find(
        this.brandedDrugId
      );
      this.drug = this.services.drug.find(this.brandedDrug, this.cleanDrugId);

      // either the branded drug or the drug doesn't exist
      if (!this.brandedDrug || !this.drug)
        this.$router.push({ name: 'brandedDrugs' });
    },
    openPreviewModal() {
      this.$modal.show(`drug-preview-modal`);
    },
  },
};
</script>
