<template>
  <step-layout
    :title="$t(`${i18nScope}.title`)"
    :kid="kid"
    :prescriptionItem="prescriptionItem"
    v-on="$listeners"
  >
    <template v-slot:switch>
      <custom-switch v-model="isCustom" class="mt-1 ml-8" />
    </template>

    <transition name="chat-footer" mode="out-in">
      <div key="custom" class="pt-8 px-3" v-if="isCustom">
        <custom-dosage-form v-on="$listeners" />
      </div>

      <loader :isLoading="!drugs" style="min-height: 18rem" class="pt-4" v-else>
        <step-list
          :list="dosages"
          @select="({ drug, dosage }) => $emit('change', { drug, dosage })"
          v-slot="{ item: { drug, dosage } }"
          v-if="drugs"
        >
          <div class="d-flex flex-column align-items-start">
            <span>{{ drug.shortLabel }}</span>
            <small>{{ dosage.shortLabel }}</small>
          </div>
        </step-list>
      </loader>
    </transition>
  </step-layout>
</template>

<script>
import StepLayout from './step-layout';
import StepList from './list';
import CustomDosageForm from './custom-dosage-form';

export default {
  name: 'DosagePickerStep2',
  components: { StepLayout, StepList, CustomDosageForm },
  props: {
    kid: { type: Object, required: true, default: null },
    prescriptionItem: { type: Object, required: true },
  },
  data() {
    return { drugs: null, isCustom: false };
  },
  async mounted() {
    const fullBrandedDrug = await this.services.brandedDrug.find(
      this.prescriptionItem.brandedDrug.id
    );
    this.drugs = this.services.brandedDrug.sortedDrugs(fullBrandedDrug);
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.dosagePicker.step2';
    },
    dosages() {
      return this.drugs
        .map(drug =>
          this.services.drug
            .filteredDosages(drug)
            .map(dosage => ({ drug, dosage }))
            .concat([null])
        )
        .flat();
    },
  },
};
</script>
