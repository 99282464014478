export default class RoomValidator {
  /**
   * Check whether a given status is of specified type.
   *
   * @param {string} value
   *
   * @return {boolean}
   */
  static status(value) {
    return ['open', 'closed', 'snoozed'].includes(value);
  }

  /**
   * @param {Object} data
   * @param {string[]} data.causes
   * @param {string} data.diagnosis
   * @param {string} data.direction
   * @param {string[]} data.supervision
   * @param {string} [data.comments]
   */
  static report(data) {
    /** @type {string[]} */
    const requiredFields = ['causes', 'diagnosis', 'direction', 'supervision'];
    const dataFields = Object.keys(data);

    // Check whether all required fields are present and have a value
    return requiredFields.every(
      field => dataFields.includes(field) && data[field]
    );
  }
}
