<template>
  <div class="d-flex align-items-center">
    <div class="pr-3">
      <font-awesome-icon :icon="['fad', 'baby']" size="2x" />
    </div>
    <div class="mx-3 w-100">
      <vue-slider
        v-model="range"
        :min="0"
        :max="216"
        :dotSize="12"
        tooltip="always"
        tooltipPlacement="bottom"
        :tooltipFormatter="formatAge"
      />
    </div>
    <div class="pl-3">
      <font-awesome-icon :icon="['fad', 'child']" size="3x" />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'AgeRangeSlider',
  components: { VueSlider },
  props: ['value'],
  computed: {
    range: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    formatAge(value) {
      return this.$tc('month', value, { count: value });
    },
  },
};
</script>
<style src="vue-slider-component/theme/antd.css"></style>
