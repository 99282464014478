<template>
  <div class="hide-scrollbar">
    <div class="sidebar-header container-fluid">
      <h2 class="font-bold mb-0">
        <font-awesome-icon :icon="['fad', 'user-md']" class="mr-5" />
        {{ $t('agents.title') }}
      </h2>
    </div>

    <div class="py-6">
      <div class="container-fluid mb-6" v-if="$p('agent', 'create')">
        <router-link
          :to="{ name: 'agentProfile', params: { agentId: 'new' } }"
          class="btn btn-primary btn-block"
        >
          {{ $t('agents.newAgentButton') }}
        </router-link>
      </div>

      <loader style="min-height: 50vh" :isLoading="isLoading">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item py-6"
            v-for="agent in agents"
            :key="agent.id"
          >
            <AgentItem :agent="agent" :active="agent.id === agentId" />
          </li>
        </ul>
      </loader>
    </div>
  </div>
</template>

<script>
import AgentItem from './list_item';

export default {
  name: 'AgentList',
  components: { AgentItem },
  props: ['agentId'],
  data() {
    return {
      allAgents: null,
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = true;
    this.$bind('allAgents', this.services.agent.findAll(true)).then(
      () => (this.isLoading = false)
    );
  },
  computed: {
    agents() {
      return this.services.agent.sortByDisabledLast(
        this.services.agent.filter(this.allAgents, {})
      );
    },
  },
};
</script>
