<template>
  <draggable
    tag="div"
    class="list-group"
    :list="list"
    handle=".handle"
    @change="onChange"
  >
    <div v-for="libraryItem in list" :key="libraryItem.id">
      <div
        class="list-group-item cursor-pointer"
        :class="{
          'border-left-0 border-right-0 primary-border-right':
            libraryItem.id === libraryItemId,
        }"
        :style="{ paddingLeft: `${(libraryItem.depth + 2) * 20}px` }"
      >
        <item :libraryItem="libraryItem" :topic="topic" />
      </div>

      <library-tree
        :topic="topic"
        :list="libraryItem.children"
        :libraryItemId="libraryItemId"
        v-if="!libraryItem.leaf"
        @change="onChange"
      />
    </div>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable';
import Item from './item';

export default {
  name: 'LibraryTree',
  components: { Draggable, Item },
  props: {
    topic: { type: Object },
    list: { type: Array, default: null },
    libraryItemId: { type: String, default: null },
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
  },
};
</script>
