<template>
  <div>
    <div
      class="list-group-item cursor-pointer"
      :class="{ 'border-left-0 border-right-0 primary-border-right': active }"
      @click="$emit('select', topic)"
    >
      <div class="media align-items-center py-3">
        <div class="media-body">
          <div class="d-flex align-items-center">
            <font-awesome-icon :icon="topic.icon" />
            <h6 class="mb-0 ml-3">
              {{ $t(`admin.medicalReportLibrary.list.topics.${topic.name}`) }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <collapse-transition>
      <library-tree
        :libraryItemId="libraryItemId"
        :topic="topic"
        :list="tree"
        v-if="active && list"
        @change="onChange"
      />
    </collapse-transition>
  </div>
</template>

<script>
import LibraryTree from './index';

export default {
  name: 'MedicalReportLibraryTopic',
  components: { LibraryTree },
  props: ['topic', 'active', 'libraryItemId'],
  data() {
    return {
      list: null,
      tree: null,
    };
  },
  mounted() {
    this.$bind(
      'list',
      this.services.medicalReportLibrary.findAll(this.topic.name)
    );
  },
  methods: {
    onChange() {
      this.services.medicalReportLibrary.updateTree(this.topic.name, this.tree);
    },
  },
  watch: {
    list() {
      this.tree = this.services.medicalReportLibrary.buildTree(this.list);
    },
  },
};
</script>
