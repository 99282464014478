<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.savedReplies.new.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <saved-reply-form
            :savedReply="savedReply"
            :persist="onPersist"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import SavedReplyForm from './form';

export default {
  name: 'NewSavedReply',
  components: { SavedReplyForm },
  data() {
    return { savedReply: null };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.savedReply = merge(this.savedReply, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.services.savedReply
        .create(this.savedReply)
        .then(savedReply => {
          this.$router.push({
            name: 'editSavedReply',
            params: { savedReplyId: savedReply.id },
          });
        });
    },
  },
  watch: {
    services: {
      immediate: true,
      handler() {
        this.savedReply = this.services.savedReply.build();
      },
    },
  },
};
</script>
