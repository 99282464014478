<template>
  <div class="main main-visible">
    <div class="chat">
      <div class="chat-body">
        <div class="d-flex flex-column h-100">
          <prescription-detail-header :prescription="prescription" />
          <div class="chat-content">
            <div class="container-fluid px-0 h-100">
              <iframe
                :src="prescription.previewUrl"
                v-if="prescription"
                class="w-100 h-100 border-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrescriptionDetailHeader from './header';

export default {
  name: 'PrescriptionDetail',
  components: { PrescriptionDetailHeader },
  props: {
    prescriptionId: { type: String, required: true },
  },
  data() {
    return { prescription: null };
  },
  watch: {
    prescriptionId: {
      immediate: true,
      async handler() {
        this.prescription = await this.services.prescription.find(
          this.prescriptionId
        );
      },
    },
  },
};
</script>
