import * as API from '@/shared/api';
import { authenticateDb, signOutFromDb } from '@/shared/db';
import honeybadger from '@/plugins/honeybadger';
import addSeconds from 'date-fns/add_seconds';

const SESSION_DURATION = 3600 * 24; // in seconds

// credentials: { email, password }
export const signIn = async function(credentials) {
  const consistentCredentials = {
    email: credentials.email?.trim(),
    password: credentials.password?.trim(),
  };
  const data = await API.signIn(consistentCredentials);
  return coreSignIn(data);
};

export const localSignIn = async () => {
  const expiresAt = localStorage.getItem('jwtExpiresAt');
  var { jwt, firebaseJwt, agent } = loadInformation();

  if (agent && jwt && firebaseJwt && expiresAt && expiresAt >= Date.now()) {
    // the further API calls will use the Authentication header
    API.setJwt(jwt);
    // verify that the Firebase token is still valid
    let isAuthenticatedInFirebase = await authenticateDb(firebaseJwt);
    // console.log('isAuthenticatedInFirebase?', isAuthenticatedInFirebase);
    if (isAuthenticatedInFirebase) {
      honeybadger.setContext({
        agent: agent,
        user_id: agent.id,
        user_email: agent.email,
      });
      return { jwt, agent, firebaseJwt };
    } else {
      // alright, let's see if the Biloba API can give us a brand new Firebase token
      let data = await API.refreshToken();
      console.log('Refresh JWT received from the API', data);
      if (data) return coreSignIn(data);
    }
  }

  // we coudn't manage to sign the agent in, so force a clean sign out
  clearJwt();
  return { jwt: undefined, agent: undefined, firebaseJwt: undefined };
};

export const signOut = async () => {
  try {
    await signOutFromDb();
    console.log('Signing out from Firebase');
  } catch (error) {
    /* useless */
  }
  clearJwt();
};

export const loadInformation = () => {
  return {
    jwt: localStorage.getItem('jwt'),
    firebaseJwt: localStorage.getItem('firebaseJwt'),
    agent: JSON.parse(localStorage.getItem('agent') || null),
  };
};

export const storeJWT = (jwt, agent, firebaseJwt) => {
  const expiredAt = addSeconds(Date.now(), SESSION_DURATION);
  // console.log('storeJWT', Date.now(), expiredAt);
  localStorage.setItem('jwt', jwt);
  localStorage.setItem('agent', JSON.stringify(agent));
  localStorage.setItem('jwtExpiresAt', Number(expiredAt));
  localStorage.setItem('firebaseJwt', firebaseJwt);
};

export const clearJwt = () => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('agent');
  localStorage.removeItem('jwtExpiresAt');
  localStorage.removeItem('firebaseJwt');
};

const coreSignIn = async data => {
  if (!data) {
    return null;
  }

  let authData = normalizeAuthData(data);

  storeJWT(authData.jwt, authData.agent, authData.firebaseJwt);
  await authenticateDb(authData.firebaseJwt);
  honeybadger.setContext({
    agent: authData.agent,
    user_id: authData.agent.id,
    user_email: authData.agent.email,
  });
  API.setJwt(authData.jwt);

  console.log('Agent signed in with success!', authData);

  return authData;
};

const normalizeAuthData = data => {
  const agent = (({
    id,
    lastName,
    firstName,
    email,
    profilePic,
    title,
    slackID,
    admin,
    dev,
    locale,
  }) => ({
    id,
    lastName,
    firstName,
    email,
    profilePic,
    title,
    slackID,
    admin,
    dev,
    locale,
  }))(data.user);
  return { jwt: data.token, agent, firebaseJwt: data.user.fbtoken };
};
