import Vue from 'vue';
import firebase from 'firebase/app';
import merge from 'deepmerge';
import { getDB } from '@/shared/db';
import { getFileExtension, uuidv4, isBlank } from '@/shared/utils';
import { registerAgent } from '@/shared/api';
import i18n from '@/plugins/i18n';
import { track as trackAgent } from './agent-tracking';

export const DEFAULT_PROFILE_PIC_URL =
  'https://firebasestorage.googleapis.com/v0/b/kiddoo-app.appspot.com/o/assets%2Fagent-placeholder.jpeg?alt=media&token=30695eaf-feeb-432f-b49a-573fc4843afb';

export const build = attributes => {
  return merge(
    {
      gender: 'female',
      bio: { en: '', fr: '' },
      bioRD: { en: '', fr: '' },
      profilePic: DEFAULT_PROFILE_PIC_URL,
    },
    attributes || {}
  );
};

export const enforceConsistentAttributes = attributes => {
  let newAttributes = { ...attributes };

  if (isBlank(newAttributes.profilePic)) {
    newAttributes.profilePic = DEFAULT_PROFILE_PIC_URL;
  }

  if (
    isBlank(newAttributes.bio) ||
    Object.keys(newAttributes.bio).length === 0
  ) {
    newAttributes.bio = { fr: '', en: '' };
  }

  if (
    isBlank(newAttributes.bioRD) ||
    Object.keys(newAttributes.bioRD).length === 0
  ) {
    newAttributes.bioRD = { fr: '', en: '' };
  }

  return newAttributes;
};

export const findAll = withTracking => {
  if (withTracking) trackAgent('VIEW', 'AGENTS');
  return getDB()
    .collection('agents')
    .orderBy('firstName', 'asc');
};

export const findAllByIds = async ids => {
  // Note 1: Firebase doesn't like comparing empty arrays...
  var nonEmptyIds = ids.length === 0 ? ['biloba'] : ids;

  // Note 2: Firebase doesn't let the IN operators work with more than 10 elements
  // so using another way to get our documents.
  const docs = nonEmptyIds.map(id =>
    getDB()
      .collection('agents')
      .doc(id)
  );
  const snapshots = await Promise.all(docs.map(doc => doc.get()));

  return snapshots
    .map(snapshot => {
      if (!snapshot.exists) return null;
      return { id: snapshot.id, ...snapshot.data() };
    })
    .filter(doc => doc);
};

export const find = (id, withTracking) => {
  if (withTracking) trackAgent('VIEW', 'AGENT', id);
  return getDB()
    .collection('agents')
    .doc(id);
};

export const load = id => {
  return find(id)
    .get()
    .then(document => document.data());
};

export const filter = (agents, options) => {
  if (agents === undefined || agents === null) return [];

  return agents.filter(agent => {
    const isPolicyApproved = options.withoutCurrentAgent
      ? Vue.policy('agent', agent, 'showWithoutCurrentAgent')
      : Vue.policy('agent', agent, 'show');

    return (
      isPolicyApproved &&
      (!options.skipDisabled || !agent.isDisabled) &&
      (!options.ids || options.ids.includes(agent.id)) &&
      (!options.excludeIds || !options.excludeIds.includes(agent.id))
    );
  });
};

// NOTE: we couldn't rely on the Firebase query to sort the list
// because not all the agents have the online field.
// This is why we sort the list of agents in Javascript instead.
export const sortByOnlineStatus = agents => {
  return agents.sort((agent1, agent2) => {
    if (!agent1.online && agent2.online) return 1;
    else if (agent1.online && !agent2.online) return -1;
    else
      return agent1.firstName
        .toLowerCase()
        .localeCompare(agent2.firstName.toLowerCase());
  });
};

export const sortByDisabledLast = agents => {
  return agents.sort((agent1, agent2) => {
    if (agent1.isDisabled && !agent2.isDisabled) return 1;
    else if (!agent1.isDisabled && agent2.isDisabled) return -1;
    else
      return agent1.firstName
        .toLowerCase()
        .localeCompare(agent2.firstName.toLowerCase());
  });
};

export const create = (attributes, profilePic) => {
  trackAgent('CREATE', 'AGENT', attributes.email);
  if (profilePic)
    return uploadProfilePic(profilePic).then(downloadURL => {
      attributes.profilePic = downloadURL;
      return createWithoutUploadingProfilePic(attributes);
    });
  else return createWithoutUploadingProfilePic(attributes);
};

export const createWithoutUploadingProfilePic = attributes => {
  return registerAgent(enforceConsistentAttributes(attributes));
};

export const update = (id, attributes, profilePic) => {
  trackAgent('UPDATE', 'AGENT', id);
  if (profilePic)
    return uploadProfilePic(profilePic).then(downloadURL => {
      attributes.profilePic = downloadURL;
      return updateWithoutUploadingProfilePic(id, attributes);
    });
  else return updateWithoutUploadingProfilePic(id, attributes);
};

const updateWithoutUploadingProfilePic = (id, attributes) => {
  const agentDoc = getDB()
    .collection('agents')
    .doc(id);
  return agentDoc.update(enforceConsistentAttributes(attributes));
};

const uploadProfilePic = profilePic => {
  const storageRef = firebase.storage().ref();
  const extension = getFileExtension(profilePic.name) || '.jpg';
  const filename = `profilePics/${uuidv4()}.${extension}`;

  var fileRef = storageRef.child(`attachments/${filename}`);

  return fileRef
    .put(profilePic)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL();
    })
    .catch(error => console.log('Upload', error.message));
};

export const getFullName = (agent, currentAgent, defaultValue, baseI18nKey) => {
  if (!agent || (!agent.firstName && !agent.lastName))
    return defaultValue || null;

  let name = `${agent.firstName || ''} ${agent.lastName || ''}`.trim();
  const i18nKey = `${baseI18nKey || 'agentFullNames'}.${agent.titleKey}`;

  // MD prefixed?
  if (agent.titleKey && i18n.te(i18nKey)) {
    name = i18n.t(i18nKey, { fullName: name });
  }

  if (!currentAgent) return name;

  return agent.id === currentAgent.id ? i18n.t('inbox.agents.you') : name;
};

export const getFullNameInSentence = agent => {
  return getFullName(agent, null, null, 'agentFullNamesInSentence');
};

export const getTitle = agent => {
  if (!agent) return null;
  if (agent.titleKey) {
    const key = `jobTitles.${agent.titleKey}.${agent.gender || 'male'}`;
    return i18n.t(key);
  } else {
    // NOTE: since there are old versions of the iOS app out there,
    // we've have to maintain the old way of handling the title of an agent.
    return agent.title;
  }
};

export const isValid = agent => {
  if (!agent) return false;

  return (
    (agent.title || agent.titleKey) &&
    agent.firstName?.length > 0 &&
    agent.lastName?.length > 0 &&
    agent.email?.length > 0 &&
    (!agent.bio || isBlank(agent.bio.fr) === isBlank(agent.bio.en))
  );
};

export const isDoctor = agent => {
  // a few business rules:
  // - nurses don't have any RPPS or ADELI numbers
  // - all doctors have a RPPS number
  // - only doctors with a ADELI number can generate prescriptions
  return !isBlank(agent.rpps);
};

export const isRegisteredDoctor = agent => {
  return isDoctor(agent) && !isBlank(agent.adeli);
};
