import Welcome from '@/components/welcome';
import UserRooms from '@/components/users/rooms';
import RoomDetail from '@/components/rooms/show';
import { setUser } from '@/router/before_actions';

export default [
  {
    path: '/person/:id',
    name: 'shortcutUser',
    beforeEnter: (to, from, next) => {
      setUser(to, from, next);
    },
  },
  {
    path: '/people',
    redirect: '/inbox',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace-new" */ '@/views/workspace.vue'
      ),
    children: [
      {
        path: ':userId/rooms',
        name: 'user',
        components: {
          default: Welcome,
          sidebar: UserRooms,
        },
        props: {
          sidebar: true,
        },
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
      {
        path: ':userId/rooms/:id',
        name: 'userRoomDetail',
        components: {
          default: RoomDetail,
          sidebar: UserRooms,
        },
        props: {
          default: true,
          sidebar: true,
        },
      },
    ],
  },
];
