import { isBlank } from '@/shared/utils';
import { isDoctor, isRegisteredDoctor } from '@/services/agent';
import {
  PRESCRIPTION_INITIATORS,
  PRESCRIPTION_REFERRERS,
} from '@/services/agent-group';

export default {
  open({ room }) {
    // the room has no kid, we can't let agents fill in the prescription
    if (!this.isRoomWithKid(room)) return false;

    // the room is closed: everybody can open it in READ-ONLY (because there are a prescription)
    if (this.isRoomClosed(room) && this.hasRoomPrescriptions(room)) return true;

    // the room is closed: only registered doctors can open it to fill it in
    if (this.isRoomClosed(room) && this.isMemberOf(PRESCRIPTION_REFERRERS))
      return true;

    // the room is open, any agent of the PrescriptionInitiators group can open it.
    if (!this.isRoomClosed(room) && this.isMemberOf(PRESCRIPTION_INITIATORS))
      return true;

    // in all the other cases, don't let agents open it.
    return false;
  },
  openReadOnly({ room }) {
    return this.isRoomClosed(room) && this.hasRoomPrescriptions(room);
  },
  cancel() {
    return this.isMemberOf(PRESCRIPTION_REFERRERS);
  },
  requireDoctorPicker() {
    return !isRegisteredDoctor(this.user) || !isDoctor(this.user);
  },
  // Helpers
  isRoomWithKid(room) {
    return !!room.kid;
  },
  hasRoomPrescriptions(room) {
    return !isBlank(room.prescriptions);
  },
  isRoomClosed(room) {
    return room.status.type === 'closed';
  },
};
