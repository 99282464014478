import firebase from 'firebase/app';
import { getDB } from '@/shared/db';
import * as medicalReportLibrary from './medical-report-library';
import { track as trackAgent } from './agent-tracking';
import { asyncSome } from '@/shared/utils';
import * as API from '@/shared/api';

export const findAllByKidId = kidId => {
  return getDB()
    .collection('medical-reports')
    .where('kid.id', '==', kidId)
    .orderBy('roomCreatedAt', 'desc');
};

export const findAll = () => {
  return getDB()
    .collection('medical-reports')
    .orderBy('roomCreatedAt', 'desc');
};

export const find = medicalReportId => {
  return getDB()
    .collection('medical-reports')
    .doc(medicalReportId);
};

export const createFromRoom = (room, agent) => {
  return create(room.id, {
    kid: room.kid,
    userId: room.user.id,
    agent: agent,
    roomCreatedAt: room.createdAt,
  });
};

export const create = (roomId, attributes) => {
  var finalAttributes = {
    ...attributes,
    roomId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return find(roomId).set(finalAttributes);
};

export const update = (medicalReportId, attributes) => {
  trackAgent('UPDATE', 'MEDICAL-REPORT', medicalReportId);
  return find(medicalReportId).set({
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    ...attributes,
  });
};

export const changeAgent = async (medicalReportId, agent) => {
  trackAgent('CHANGE_AGENT', 'MEDICAL-REPORT', medicalReportId);
  return find(medicalReportId).update({
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    agent,
  });
};

export const findRDLetter = async medicalReportId => {
  try {
    const response = await API.findMedicalReportRDLetter(medicalReportId);
    return response.letter;
  } catch (error) {
    console.log('[Desk] no letter has been found', error);
  }
};

export const addLibraryItemsFromSavedReply = async (
  room,
  savedReply,
  agent,
  agentGroupIds
) => {
  // no need to go further if the saveReply doesn't include any medical report items
  // or empty arrays of medical report items
  if (isEmpty(savedReply.medicalReport)) return false;

  // at first, make sure the medical report exists, if not, create it.
  if (!(await find(room.id).get()).exists) await createFromRoom(room, agent);

  const filters = { kid: room.kid, agentGroupIds };

  // load all the medical report items of the saved reply and keep only the
  // ones matching the filter and belonging to the agentGroupIds
  for (const [topicName, items] of Object.entries(savedReply.medicalReport)) {
    await Promise.all(
      items.map(({ id }) => {
        return medicalReportLibrary
          .find(topicName, id)
          .get()
          .then(snapshot => {
            const item = { ...snapshot.data() };

            if (medicalReportLibrary.acceptLibraryItem(item, filters))
              addLibraryItem(room.id, topicName, item);
          });
      })
    );
  }
};

export const addLibraryItem = (medicalReportId, topicName, libraryItem) => {
  trackAgent('UPDATE', 'MEDICAL-REPORT', medicalReportId);
  return find(medicalReportId).update({
    [topicName]: firebase.firestore.FieldValue.arrayUnion(libraryItem),
  });
};

export const removeLibraryItem = (medicalReportId, topicName, libraryItem) => {
  trackAgent('UPDATE', 'MEDICAL-REPORT', medicalReportId);
  return find(medicalReportId).update({
    [topicName]: firebase.firestore.FieldValue.arrayRemove(libraryItem),
  });
};

export const addLibraryItemToArray = (array, libraryItem) => {
  let uniqueItems = {};
  (array || []).forEach(item => (uniqueItems[item.id] = item));
  uniqueItems[libraryItem.id] = libraryItem;
  return Object.values(uniqueItems);
};

export const removeLibraryItemFromArray = (array, libraryItem) => {
  return array.filter(item => item.id !== libraryItem?.id);
};

export const canTriggerRDLetter = medicalReport => {
  return asyncSome(medicalReportLibrary.findAllTopics(), async topic => {
    if (!topic.triggerRDLetter) return false;
    return await asyncSome(medicalReport[topic.name], async item => {
      const definition = await medicalReportLibrary.load(topic.name, item.id);
      return definition?.triggerRDLetter;
    });
  });
};

export const isEmpty = medicalReport => {
  if (!medicalReport) return true;

  return medicalReportLibrary.findAllTopics().every(topic => {
    const value = medicalReport[topic.name];
    return !value || value.length === 0;
  });
};
