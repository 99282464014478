import Vue from 'vue';
import { mapState } from 'vuex';
import services from '@/services';
import {
  isBlank,
  hashCode,
  debounce,
  replaceURLs,
  replaceEmojis,
} from '@/shared/utils';
import { APP_REVISION, IS_PRODUCTION } from '@/shared/config';
import { AVAILABLE_LOCALES, getLastKnownUiLocale } from '@/plugins/i18n';
import { GROUP_NAMES } from '@/services/agent-group';

const scrollToLastRoomMessage = debounce(() => {
  setTimeout(() => {
    const event = new Event('room.scrollToLastMessage');
    window.dispatchEvent(event);
  }, 100);
}, 400);

const mixin = {
  computed: {
    ...mapState(['appLoading', 'authenticated', 'sidebarDisplayed']),
    uiLocale() {
      return (
        this.currentAgent?.locale ||
        getLastKnownUiLocale() ||
        AVAILABLE_LOCALES[0]
      );
    },
    userLocale() {
      return 'fr';
    },
    allLocales() {
      return AVAILABLE_LOCALES;
    },
    currentAgent() {
      return this.$store.state.agent;
    },
    isCurrentAgentAdmin() {
      return this.currentAgentGroups.some(
        group => group.name === GROUP_NAMES.SYSTEM_ADMINISTRATORS
      );
    },
    isCurrentAgentBetaTester() {
      return this.currentAgentGroups.some(
        group => group.name === GROUP_NAMES.BETA_TESTERS
      );
    },
    currentAgentGroups() {
      return (this.$store.state.groups || []).filter(group => {
        return group.agentIds?.indexOf(this.currentAgent?.id) >= 0;
      });
    },
    currentAgentWithGroups() {
      return this.$store.getters.getAgentWithGroups;
    },
    currentAgentGroupIds() {
      return this.currentAgentGroups.map(group => group.id);
    },
    currentAppVersion() {
      return this.$store.state.config?.version;
    },
    currentAppDynConfig() {
      return this.$store.state.dynConfig;
    },
    supervisorAgentId() {
      return this.$store.state.config?.supervisorAgent;
    },
    groupNames() {
      return GROUP_NAMES;
    },
    localAppRevision() {
      return APP_REVISION;
    },
    isProduction() {
      return IS_PRODUCTION;
    },
    cssText() {
      return 'text-dark';
    },
    cssText50() {
      return 'text-dark-50';
    },
    logoURL() {
      if (this.isProduction) return require('@/assets/logo-blue.svg');
      else return require('@/assets/logo-black.svg');
    },
    bilobaAvatarUrl() {
      if (this.isProduction) return require('@/assets/logo-blue.svg');
      else return require('@/assets/logo-black.svg');
    },
    services() {
      return services;
    },
  },
  methods: {
    isBlank(object) {
      return isBlank(object);
    },
    buildCompositeKey() {
      var args = Array.prototype.slice.call(arguments);
      return hashCode(args.flat().join('/'));
    },
    scrollToLastRoomMessage() {
      scrollToLastRoomMessage();
    },
    formatMobileMessageToHtml(content) {
      return this.$sanitize(
        replaceEmojis(replaceURLs(content.replace(/\n/g, '<br/>')).text)
      );
    },
  },
};

Vue.mixin(mixin);

export default mixin;
