<template>
  <dropdown :toggle="true" size="md" ref="dropdown">
    <template #button>
      <span class="d-flex align-items-center">
        <span :class="cssText">
          <font-awesome-icon :icon="currentStatus.icon" class="mr-3" />
          <b>{{ currentStatus.label }}</b>
          <span v-if="counter >= 0" class="ml-4" :class="cssText50">
            <b>{{ counter }}</b>
          </span>
        </span>
        <font-awesome-icon :icon="['fas', 'caret-down']" class="ml-5" />
      </span>
    </template>

    <router-link
      v-for="status in statuses"
      :key="status.id"
      :to="to(status.id)"
      class="dropdown-item d-flex align-items-center"
      :class="{ 'text-primary': isActive(status) }"
      @click.native="onClick(status)"
    >
      <span>
        <font-awesome-icon :icon="status.icon" class="mr-3" fixed-width />
        {{ status.label }}
      </span>
    </router-link>
  </dropdown>
</template>

<script>
export default {
  name: 'Statuses',

  props: {
    currentStatusId: String,
    counter: Number,
    inboxName: String,
  },

  data() {
    return {
      statuses: [
        {
          id: 'open',
          label: this.$i18n.t('inbox.statuses.open'),
          icon: 'inbox',
        },
        {
          id: 'closed',
          label: this.$i18n.t('inbox.statuses.closed'),
          icon: 'check',
        },
      ],

      selectedStatusId: 'open',
    };
  },

  computed: {
    /**
     * Determine the status based on current status ID.
     *
     * @return {Object}
     */
    currentStatus() {
      return this.statuses.find(
        status => status.id === (this.currentStatusId || this.selectedStatusId)
      );
    },
  },

  mounted() {
    // If page has an #open or #closed hash, use it to preselect the correct status
    let hash = this.$route.hash;

    if (hash.includes('open') || hash.includes('closed')) {
      hash = hash.replace('#', '');
    } else {
      hash = '';
    }

    this.selectStatusId(hash || 'open');
  },

  methods: {
    /**
     * Determine whether a status is selected.
     *
     * @param {Object} status
     *
     * @return {boolean}
     */
    isActive(status) {
      if (this.currentStatusId) return status.id === this.currentStatusId;

      return status.id === this.selectedStatusId;
    },

    /**
     * Select a status.
     *
     * @param {String} id
     *
     * @return {void}
     */
    selectStatusId(id) {
      this.selectedStatusId = id;
      this.$emit('selected', id);
    },

    /**
     * Listen for "click" event on dropdown list items.
     *
     * @param {Object} status
     *
     * @return {void}
     */
    onClick(status) {
      this.selectStatusId(status.id);

      // Close the dropdown list
      this.$refs.dropdown?.closeDropdown();
    },

    /**
     * Create a link to another page.
     *
     * @param {string} statusId
     *
     * @return {String|Object}
     */
    to(statusId) {
      // Redirect to "Inbox" pages
      if (this.inboxName) {
        const inboxName = this.inboxName;

        if (statusId === 'closed') {
          return {
            name: 'closedRooms',
            params: { inboxName },
          };
        }

        return {
          name: 'inbox',
          params: { inboxName, statusId },
        };
      }

      return {
        hash: '#' + statusId,
      };
    },
  },
};
</script>
