<template>
  <div class="d-flex justify-content-center">
    <div
      v-for="index in maxRating"
      :key="index"
      @click="setValue(index - 1)"
      class="mx-1 cursor-pointer"
    >
      <font-awesome-icon
        :icon="['fad', 'heartbeat']"
        :color="getColor(index - 1)"
        size="2x"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    value: { type: Number, default: 2 },
    maxRating: { type: Number, default: 5 },
    customGetColor: { type: Function, default: null },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  methods: {
    getColor(index) {
      if (this.customGetColor) return this.customGetColor(index, this.value);
      else {
        return index <= this.value ? 'yellow' : null;
      }
    },
    setValue(index) {
      this.$emit('input', index);
    },
  },
};
</script>
