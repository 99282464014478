<template>
  <div class="tab-pane fade h-100 show active">
    <div class="d-flex flex-column h-100">
      <infinite-scrolling
        class="hide-scrollbar h-100"
        :onScroll="loadMoreRooms"
      >
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold d-flex align-items-center">
            <font-awesome-icon :icon="['fas', 'clipboard-user']" class="mr-5" />
            <span v-if="user">{{ user.firstName }}</span>
            <span v-if="userNotFound">{{ $t('users.userNotFound') }}</span>
          </h2>
        </div>

        <div v-if="userExists" class="mt-6">
          <!-- Create a room -->
          <div class="container-fluid mb-6">
            <button
              class="btn btn-primary btn-block"
              @click="createRoom"
              v-if="$p('room', 'newConversation')"
            >
              <font-awesome-icon
                :icon="['far', 'comment-alt']"
                class="text-white h4 mb-0 mr-3"
              />
              {{ $t('users.rooms.newConversationButton') }}
            </button>
          </div>

          <div class="container-fluid mt-8 mb-6">
            <h4>{{ $t('users.rooms.title') }}</h4>
          </div>

          <div class="container-fluid px-0">
            <tabs
              navClass="rounded-0 px-5"
              :onSelect="(tab, index) => (this.activeTab = index)"
            >
              <!-- open rooms -->
              <tab :name="$t('users.rooms.tabs.open')" :selected="true">
                <PaginatedRooms
                  :activeRoomId="id"
                  :userId="userId"
                  statusId="open"
                  ref="openRooms"
                  key="openRooms"
                />
              </tab>

              <!-- closed rooms -->
              <tab :name="$t('users.rooms.tabs.closed')">
                <PaginatedRooms
                  :activeRoomId="id"
                  :userId="userId"
                  statusId="closed"
                  ref="closedRooms"
                  key="closedRooms"
                />
              </tab>

              <!-- snoozed rooms -->
              <tab :name="$t('users.rooms.tabs.snoozed')">
                <PaginatedRooms
                  :activeRoomId="id"
                  :userId="userId"
                  statusId="snoozed"
                  ref="snoozedRooms"
                  key="snoozedRooms"
                />
              </tab>
            </tabs>
          </div>
        </div>
      </infinite-scrolling>
    </div>
  </div>
</template>

<script>
import PaginatedRooms from '@/components/users/rooms/paginated-list';

export default {
  name: 'UserRooms',
  components: { PaginatedRooms },
  props: ['userId', 'id'],
  data() {
    return {
      user: undefined,
      activeTab: 0,
    };
  },
  computed: {
    userExists() {
      return this.user;
    },
    userNotFound() {
      return this.user === null;
    },
  },
  methods: {
    createRoom() {
      const userWithId = { id: this.userId, ...this.user };
      this.services.room.create(userWithId, this.currentAgent).then(docRef => {
        this.$router.push({
          name: 'userRoomDetail',
          params: { userId: this.userId, id: docRef.id },
        });
      });
    },
    loadMoreRooms() {
      let component;
      switch (this.activeTab) {
        case 0:
          component = this.$refs.openRooms;
          break;
        case 1:
          component = this.$refs.closedRooms;
          break;
        case 2:
          component = this.$refs.snoozedRooms;
          break;
      }
      if (component) {
        component.loadMoreRooms();
      }
    },
  },
  watch: {
    userId: {
      immediate: true,
      handler(userId) {
        this.$bind('user', this.services.user.find(userId));
      },
    },
  },
};
</script>
