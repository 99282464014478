import Welcome from '@/components/welcome';
import BrandedDrugsSidebar from '@/components/admin/branded-drugs/sidebar';
import NewBrandedDrug from '@/components/admin/branded-drugs/new';
import EditBrandedDrug from '@/components/admin/branded-drugs/edit';

import NewDrug from '@/components/admin/drugs/new';
import EditDrug from '@/components/admin/drugs/edit';

export default [
  {
    path: 'branded-drugs',
    name: 'brandedDrugs',
    components: {
      default: Welcome,
      sidebar: BrandedDrugsSidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'branded-drugs/new',
    name: 'newBrandedDrug',
    components: {
      default: NewBrandedDrug,
      sidebar: BrandedDrugsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'branded-drugs/:brandedDrugId/edit',
    name: 'editBrandedDrug',
    components: {
      default: EditBrandedDrug,
      sidebar: BrandedDrugsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'branded-drugs/:brandedDrugId/drugs/new',
    name: 'newDrug',
    components: {
      default: NewDrug,
      sidebar: BrandedDrugsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
  {
    path: 'branded-drugs/:brandedDrugId/drugs/:drugId/edit',
    name: 'editDrug',
    components: {
      default: EditDrug,
      sidebar: BrandedDrugsSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      transitionName: 'slide',
    },
  },
];
