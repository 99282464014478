<template>
  <div>
    <div class="container-fluid">
      <div class="mb-6 d-flex align-items-center justify-content-between">
        <Statuses @selected="selectStatus" />
        <DatePicker v-model="date" />
      </div>
    </div>

    <loader
      :isLoading="isLoading"
      style="min-height: 50vh"
      class="pb-12"
      key="main"
    >
      <div class="container-fluid min-height" v-if="isEmpty">
        <div
          class="d-flex flex-column align-items-center justify-content-center min-height"
        >
          <font-awesome-icon icon="inbox" class="text-light" size="5x" />
          <p class="h5 mt-3 text-gray-700">
            {{ $t('inbox.empty') }}
          </p>
        </div>
      </div>

      <nav class="nav d-block list-discussions-js">
        <router-link
          :to="{
            name: 'agentRoomDetail',
            params: { agentId: agentId, id: room.id },
          }"
          class="text-reset nav-link p-0 mb-6"
          v-for="(room, index) in rooms"
          :key="room.id"
        >
          <RoomItem
            :room="room"
            :active="activeRoomId === room.id"
            :isLast="index === rooms.length - 1"
          />
        </router-link>
      </nav>

      <loader
        :isLoading="isLoadingMoreRooms"
        :onComplete="addNextRooms"
        :size="25"
        key="pagination"
      />
    </loader>
  </div>
</template>

<script>
import DatePicker from '@/components/rooms/list/date-picker';
import Statuses from '@/components/rooms/shared/statuses';
import RoomItem from '@/components/rooms/list/list-item';
import PaginatedRoomsMixin from '@/mixins/paginated-rooms';

export default {
  name: 'PaginatedAgentRooms',
  components: { RoomItem, DatePicker, Statuses },
  mixins: [PaginatedRoomsMixin],
  props: {
    agentId: {
      type: String,
      default: undefined,
    },
    activeRoomId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      selectedStatus: undefined,
    };
  },
  methods: {
    loadRooms(from, excludeRoomId) {
      return this.services.room.loadAllByAgentByDate(
        this.agentId,
        this.selectedStatus === 'open',
        from,
        excludeRoomId ? [excludeRoomId] : undefined
      );
    },
    /**
     * Get selected status from the dropdown list.
     *
     * @param {string} status
     *
     * @return {void}
     */
    selectStatus(status) {
      this.selectedStatus = status;
    },
  },
  watch: {
    selectedStatus: {
      immediate: true,
      handler(value) {
        if (value) this.loadInitialRooms();
      },
    },
    date() {
      this.loadInitialRooms();
    },
  },
};
</script>
