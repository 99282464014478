import * as API from '@/shared/api';
import { nowInUTC, toAPITimestamp } from '@/shared/utils';

export const track = async (
  name,
  resourceType,
  resourceId,
  otherInformation
) => {
  return API.createLog({
    name: name.toUpperCase(),
    resourceType: resourceType?.toUpperCase(),
    resourceId,
    createdAt: toAPITimestamp(nowInUTC()),
    ...(otherInformation || {}),
  }).catch(error => console.log("[TRACKER] can't track, reason: ", error));
};
