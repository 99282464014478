<template>
  <div class="d-flex align-self-center h-100" :class="cssText">
    <!-- Mark as favorite on/off -->
    <a
      class="btn btn-outline-secondary btn-md mr-3 btn-ico-min-w"
      role="button"
      @click="toggleMarkAsFavorite"
      v-if="$policy('room', room, 'toggleMarkAsFavorite')"
      v-tooltip="
        $t(
          `inbox.statusHints.${markedAsFavorite ? 'unmark' : 'mark'}AsFavorite`
        )
      "
    >
      <div class="h-100 d-flex align-items-center justify-content-center">
        <font-awesome-icon
          :icon="[markedAsFavorite ? 'fas' : 'far', 'thumbtack']"
        />
      </div>
    </a>

    <!-- Re-open -->
    <a
      class="btn btn-outline-secondary btn-md mr-3 btn-ico-min-w"
      role="button"
      @click="reOpenRoom"
      v-if="$policy('room', room, 'reOpen')"
      v-tooltip="$t('inbox.statusHints.open')"
    >
      <div class="h-100 d-flex align-items-center justify-content-center">
        <font-awesome-icon :icon="['fas', 'inbox-out']" />
      </div>
    </a>

    <!-- Snooze / Re-Snooze -->
    <button
      class="btn btn-outline-secondary btn-md mr-3 btn-ico-min-w"
      :class="cssText"
      role="button"
      @click="openSnoozeModal"
      v-if="$policy('room', room, 'snooze')"
      v-tooltip="snoozeTooltip"
    >
      <div class="h-100 d-flex align-items-center justify-content-center">
        <font-awesome-icon :icon="['far', 'clock']" />
      </div>
    </button>

    <!-- Close -->
    <dropdown
      :label="compactDisplay ? null : $t('inbox.statusActions.close.label')"
      :tooltip="compactDisplay ? $t('inbox.statusActions.close.label') : null"
      :icon="['far', 'times-circle']"
      v-if="$policy('room', room, 'close')"
      menuAlign="right"
      :buttonCustomClass="compactDisplay ? 'btn-ico-min-w' : null"
    >
      <div class="mx-5 my-3">
        <p :class="cssText">
          {{ $t('inbox.statusActions.close.text') }}
        </p>
        <button @click="closeRoom" class="btn btn-outline-secondary btn-sm">
          <span :class="cssText">
            <font-awesome-icon :icon="['fal', 'check-square']" />
            <span class="ml-3">{{
              $t('inbox.statusActions.close.button')
            }}</span>
          </span>
        </button>
      </div>
    </dropdown>

    <snooze-room-modal :room="room" v-if="room" v-on="$listeners" />
  </div>
</template>

<script>
import SnoozeRoomModal from './close/snooze-modal.vue';

export default {
  name: 'RoomHeaderStatus',
  components: { SnoozeRoomModal },
  props: {
    room: { type: Object, default: null },
    compactDisplay: { type: Boolean, default: false },
  },
  computed: {
    snoozeTooltip() {
      let action = this.$policy('room', this.room, 'reSnooze')
        ? 'reSnooze'
        : 'snooze';
      return this.$t(`inbox.statusHints.${action}`);
    },
    markedAsFavorite() {
      return (
        (this.room.markedByAgentsAsFavorite || []).indexOf(
          this.currentAgent.id
        ) !== -1
      );
    },
  },
  methods: {
    reOpenRoom() {
      this.services.room.reOpen(this.room, this.currentAgent);
    },
    closeRoom() {
      this.services.room.close(this.room, this.currentAgent);
    },
    openSnoozeModal() {
      this.$modal.show(`snooze-room-modal`);
    },
    toggleMarkAsFavorite() {
      this.services.room.toggleMarkedAsFavorite(this.room, this.currentAgent);
    },
  },
};
</script>
