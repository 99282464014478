<template>
  <div class="w-100 bg-gray-100 mt-6" style="height: 38rem">
    <iframe
      :src="pdfBase64"
      class="w-100 h-100 border-0"
      v-if="boardPreview && isTabActive"
    />
  </div>
</template>

<script>
export default {
  name: 'PDFPreviewPrescriptionConfirmationModal',
  props: {
    boardPreview: { type: Object },
    isTabActive: { type: Boolean, default: false },
  },
  computed: {
    pdfBase64() {
      return `data:application/pdf;base64,${this.boardPreview.pdfFileInBase64}`;
    },
  },
};
</script>
