<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-6 col-lg-6">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.savedReplies.edit.title') }}
                </h2>
              </div>
              <div class="col-3 col-lg-6 text-right">
                <ul class="nav justify-content-end">
                  <li
                    class="nav-item list-inline-item"
                    v-if="
                      $p(
                        'savedReply',
                        { ...savedReply, hasChildren },
                        'destroy'
                      )
                    "
                  >
                    <destroy-button
                      :text="$t('admin.savedReplies.edit.actions.destroy.text')"
                      :buttonLabel="
                        $t('admin.savedReplies.edit.actions.destroy.button')
                      "
                      @destroy="onDestroy"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <saved-reply-form
            :savedReply="savedReply"
            :persist="onPersist"
            @change="onChange"
            v-if="savedReply"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import SavedReplyForm from './form';

export default {
  name: 'EditSavedReply',
  components: { SavedReplyForm },
  props: ['savedReplyId'],
  data() {
    return {
      savedReply: null,
      hasChildren: true,
    };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.savedReply = merge.all(
        [this.savedReply, changes, { id: this.savedReplyId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.services.savedReply.update(this.savedReply);
    },
    onDestroy() {
      this.services.savedReply
        .destroy(this.savedReply)
        .then(() => this.$router.push({ name: 'savedReplies' }));
    },
  },
  watch: {
    savedReplyId: {
      immediate: true,
      handler(savedReplyId) {
        this.$bind('savedReply', this.services.savedReply.find(savedReplyId));
        this.services.savedReply.hasChildren(savedReplyId).then(hasChildren => {
          this.hasChildren = hasChildren;
        });
      },
    },
  },
};
</script>
