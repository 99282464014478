import services from '@/services';

const defaultState = {
  agent: undefined,
  message: undefined,
  soundNotifications: localStorage.getItem('soundNotifications') !== 'false',
  config: undefined,
  dynConfig: undefined,
  groups: undefined,
  molecules: undefined,
  brandedDrugs: undefined,
  systemError: false,
};
const defaultAuthenticatedState = services.authentication.loadInformation();

export default {
  ...defaultState,
  ...defaultAuthenticatedState,
  authenticated: defaultAuthenticatedState.jwt !== null,
  appLoading: defaultAuthenticatedState.jwt !== null,
};
