<template>
  <div class="pt-2 pb-4 px-5">
    <div class="card drop-shadow">
      <div class="card-body py-4 px-5">
        <div class="cart-title d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ $t('inbox.room.medicalReport.title') }}
          </h4>

          <medical-report-agent-picker
            class="ml-4"
            :medicalReport="medicalReport"
            @assign-agent="assignAgent"
            v-if="$policy('medicalReport', medicalReport, 'assign')"
          />

          <div class="ml-auto">
            <button
              class="btn btn-ico btn-link btn-minimal btn-sm text-dark"
              @click="$emit('open-prescription-board')"
              v-if="$policy('prescriptionBoard', { room }, 'open')"
            >
              <font-awesome-icon
                :icon="['fal', 'clipboard-prescription']"
                size="lg"
              />
            </button>
            <button
              class="btn btn-xl btn-link text-muted pr-0"
              @click="$emit('close')"
            >
              <font-awesome-icon :icon="['fal', 'times']" size="lg" />
            </button>
          </div>
        </div>

        <loader
          :isLoading="!medicalReport"
          :waitForLoop="false"
          style="min-height: 16rem"
        >
          <div v-if="medicalReport">
            <div class="row">
              <div v-for="topic in topics" :key="topic.name" class="col-6">
                <library-item-input
                  :topicName="topic.name"
                  :list="medicalReport[topic.name] || []"
                  :kid="room.kid"
                  :letterBody="medicalReport.letterBody"
                  editable
                  @add="add"
                  @remove="remove"
                  class="mt-5"
                />
              </div>
            </div>

            <comments-input
              :medicalReport="medicalReport"
              editable
              @change="change"
              class="mt-4"
            />

            <medical-report-rd-letter-modal
              :medicalReport="medicalReport"
              @change="change"
            />
          </div>
        </loader>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalReportAgentPicker from './agent-picker';
import LibraryItemInput from '@/components/medical-report/library-item-input';
import MedicalReportRdLetterModal from '@/components/medical-report/rd-letter/modal';
import CommentsInput from './comments-input';

export default {
  name: 'MedicalReport',
  components: {
    MedicalReportAgentPicker,
    LibraryItemInput,
    CommentsInput,
    MedicalReportRdLetterModal,
  },
  props: {
    room: { type: Object },
  },
  data() {
    return { medicalReport: null };
  },
  computed: {
    topics() {
      return this.services.medicalReportLibrary
        .findAllTopics()
        .filter(topic => topic.medicalReport);
    },
  },
  methods: {
    change(changes) {
      this.services.medicalReport.update(this.medicalReport.id, {
        ...this.medicalReport,
        ...changes,
      });
    },
    assignAgent(agent) {
      this.change({ agent });
    },
    add(topicName, libraryItem) {
      this.services.medicalReport.addLibraryItem(
        this.medicalReport.id,
        topicName,
        libraryItem
      );
    },
    remove(topicName, libraryItem) {
      this.services.medicalReport.removeLibraryItem(
        this.medicalReport.id,
        topicName,
        libraryItem
      );
    },
  },
  watch: {
    room: {
      immediate: true,
      handler(newRoom, oldRoom) {
        if (!newRoom || (oldRoom && oldRoom.id === newRoom.id)) return;

        this.$bind(
          'medicalReport',
          this.services.medicalReport.find(newRoom.id)
        ).then(medicalReport => {
          if (medicalReport) return; // medical report has already been created
          this.services.medicalReport.createFromRoom(
            newRoom,
            this.currentAgent
          );
        });
      },
    },
  },
};
</script>
