<template>
  <div class="message" :class="{ 'message-right': !isPostedByUser }">
    <avatar :user="whoPostedIt" color="dark" :onlyFirstName="isPostedByUser" />

    <div
      class="message-body"
      :class="{
        'mr-4 mr-lg-5': !isPostedByUser,
        'ml-4 ml-lg-5': isPostedByUser,
      }"
    >
      <div
        class="message-content"
        :class="{
          'bg-primary text-white': !(
            isPostedByUser || isPostedBySystemOrBiloba
          ),
          'bg-light': isPostedByUser || isPostedBySystemOrBiloba,
        }"
      >
        <!-- Text -->
        <p v-if="isText" v-html="formattedText"></p>

        <!-- Image -->
        <div
          v-if="isImage"
          class="overflow-hidden"
          style="height: 24rem; max-width: 24rem"
        >
          <loader
            :isLoading="!isImageLoaded"
            class="h-100 d-flex align-items-center"
          >
            <img
              class="d-block h-100"
              :src="message.content"
              v-on:load="onImageZoomable"
            />
          </loader>
          <img
            class="d-none"
            :src="message.content"
            v-on:load="onImageLoaded"
          />
        </div>

        <!-- Video -->
        <div v-if="isVideo" style="height: 250px; min-width: 250px">
          <loader
            :isLoading="!isVideoLoaded"
            class="h-100 d-flex align-items-center"
          >
            <video
              controls
              controlslist="nodownload"
              disablepictureinpicture
              height="250px"
              width="250px"
            >
              <source :src="message.content" type="video/mp4" />
            </video>
          </loader>

          <video
            controls
            height="250"
            @loadeddata="onVideoLoaded"
            class="d-none"
          >
            <source :src="message.content" type="video/mp4" />
          </video>
        </div>
      </div>

      <div class="message-footer">
        <small class="text-muted">
          {{ message.createdAt | formatTimestamp }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import mediumZoom from 'medium-zoom';

export default {
  name: 'Message',
  props: {
    message: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isImageLoaded: false,
      isVideoLoaded: false,
    };
  },
  computed: {
    isPostedByUser() {
      return this.message.sender.type === 'user';
    },
    isPostedByMe() {
      return this.message.sender.id === this.currentAgent.id;
    },
    isPostedBySystemOrBiloba() {
      return (
        this.message.sender.type === 'system' ||
        this.message.sender.type === 'desk' ||
        this.message.sender.type === 'biloba'
      );
    },
    whoPostedIt() {
      return this.isPostedByMe ? this.currentAgent : this.message.sender;
    },
    isText() {
      return this.message.type === 'text';
    },
    isImage() {
      return this.message.type === 'image';
    },
    isVideo() {
      return this.message.type === 'video';
    },
    formattedText() {
      return this.formatMobileMessageToHtml(this.message.content);
    },
    date() {
      // NOTE: createdAt might be null when Firebase uses its special keyword
      // to set the createAt attribute to the current server time.
      return this.message.createdAt?.toDate();
    },
  },
  methods: {
    onImageLoaded() {
      this.isImageLoaded = true;
    },
    onImageZoomable(event) {
      mediumZoom(event.target);
    },
    onVideoLoaded() {
      this.isVideoLoaded = true;
    },
  },
};
</script>
