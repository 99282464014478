import { setMyNewPassword } from '@/shared/api';
import { getDB } from '@/shared/db';
import { track as trackAgent } from './agent-tracking';

export const updatePassword = newPassword => setMyNewPassword(newPassword);

export const changeLocale = (currentAgent, newLocale) => {
  trackAgent('UPDATE', 'PROFILE', null, { locale: newLocale });

  const newAgent = { ...currentAgent, locale: newLocale };

  // trick to avoid a flickering at startup caused by a different locale value
  localStorage.setItem('agent', JSON.stringify(newAgent));

  // store locally the locale for the sign in page for instance
  localStorage.setItem('locale', newLocale);

  const agentDoc = getDB()
    .collection('agents')
    .doc(currentAgent.id);

  return agentDoc.update(newAgent);
};
