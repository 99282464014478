<template>
  <modal
    name="snooze-room-modal"
    width="650"
    height="auto"
    scrollable
    @opened="reset"
    :clickToClose="false"
  >
    <div class="modal-content">
      <div class="modal-header border-0 pt-4 pb-4 px-6">
        <div class="modal-title w-100 d-flex align-items-center">
          <h4 class="font-weight-bold mb-0">
            {{ title }}
          </h4>

          <div class="ml-auto d-flex align-items-center">
            <button
              class="btn btn-xl btn-link text-muted pr-0"
              @click="closeModal()"
            >
              <font-awesome-icon :icon="['fal', 'times']" size="lg" />
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body px-6 pt-0 pb-7">
        <p class="text" :class="cssText" v-html="explanationText" />

        <div class="d-flex flex-wrap mt-4">
          <div
            v-for="(daysOption, index) in daysOptions"
            :key="index"
            :class="{ 'mb-4 mr-4': daysOption, 'flex-basis-100': !daysOption }"
          >
            <button
              class="btn btn-md d-flex align-items-center btn-outline-dark border-color-gray-500 font-weight-bold px-4 py-3"
              :class="{
                'btn-dark text-white': daysOptionInput === daysOption,
              }"
              type="button"
              @click="selectDaysOption(daysOption)"
              v-if="daysOption"
            >
              {{ $t(`${i18nScope}.days.${daysOption}`) }}
            </button>
          </div>
          <div class="flex-basis-100">
            <date-picker
              buttonClass="btn btn-md btn-outline-dark border-color-gray-500 font-weight-bold"
              :minDate="tomorrow"
              :maxDate="null"
              :placeholder="$t(`${i18nScope}.datePlaceholder`)"
              v-bind:value="dateInput"
              v-on:input="selectDate($event)"
            />
          </div>
        </div>

        <div class="mt-6 d-flex align-items-center">
          <button
            type="button"
            class="btn btn-primary btn-md ml-auto"
            :disabled="!isSnoozedUntilValid"
            @click="validate()"
          >
            {{ $t(`${i18nScope}.validateButton`) }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {
  addDaysToNow,
  daysBetweenDates,
  formatDate,
  startOfDayUTC,
} from '@/shared/utils';

export default {
  name: 'SnoozeRoomModal',
  props: {
    room: { type: Object, required: true },
  },
  data() {
    return { daysOptionInput: null, dateInput: null };
  },
  computed: {
    i18nScope() {
      return 'inbox.room.close.snoozeModal';
    },
    title() {
      let key = this.$policy('room', this.room, 'reSnooze')
        ? 'altTitle'
        : 'title';
      return this.$t(`${this.i18nScope}.${key}`);
    },
    explanationText() {
      let key = this.$policy('room', this.room, 'reSnooze')
        ? 'altText'
        : 'text';
      return this.$tc(`${this.i18nScope}.${key}`, this.snoozedUntilDays, {
        days: this.snoozedUntilDays,
        date: this.formattedDate,
      });
    },
    daysOptions() {
      return [1, 2, 3, 4, 5, null, 6, 7, 8, 9, 10, null, 14, 21, null, 31];
    },
    snoozedUntilDate() {
      return this.dateInput;
    },
    formattedDate() {
      if (this.isSnoozedUntilValid)
        return formatDate(this.snoozedUntilDate, 'long');
      else return null;
    },
    snoozedUntilDays() {
      return parseInt(this.daysOptionInput);
    },
    isSnoozedUntilValid() {
      return this.snoozedUntilDays > 0;
    },
    tomorrow() {
      return addDaysToNow(1);
    },
  },
  methods: {
    selectDaysOption(daysOption) {
      this.daysOptionInput = daysOption;
      this.dateInput = addDaysToNow(parseInt(daysOption));
    },
    selectDate(date) {
      this.daysOptionInput = daysBetweenDates(date);
      this.dateInput = startOfDayUTC(date);
    },
    validate() {
      this.services.room
        .snooze(this.room, this.currentAgent, this.snoozedUntilDate)
        .then(() => {
          this.closeModal();
        });
    },
    closeModal() {
      this.$modal.hide('snooze-room-modal');
    },
    reset() {
      // first, let's see if the room can be re-snoozed.
      // If so, fill in the form with the previous snoozedUntil date
      if (this.$p('room', this.room, 'reSnooze')) {
        this.selectDate(this.room.status.snoozedUntil.toDate());
      } else {
        // simple reset
        this.daysOptionInput = null;
        this.dateInput = null;
      }
    },
  },
};
</script>

<style scoped>
.text >>> strong {
  font-weight: normal;
  background: #fcf3da;
}
</style>
