<template>
  <li class="list-group-item py-6">
    <router-link
      :to="{ name: routeName }"
      tag="div"
      class="media align-items-center cursor-pointer"
    >
      <div class="media-body">
        <h6 class="mb-0">
          <font-awesome-icon :icon="icon" class="mr-3" />
          {{ label }}
        </h6>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'AdminNavbarItem',
  props: ['routeName', 'labelKey', 'icon'],
  computed: {
    label() {
      return this.$t(`admin.navbar.links.${this.labelKey}`);
    },
  },
};
</script>
