export default {
  show() {
    return this.isAdmin();
  },
  showLegacyUI() {
    return this.isAdmin() && !this.isBetaTester();
  },
  showNewUI() {
    return this.isAdmin() && this.isBetaTester();
  },
};
