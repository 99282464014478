<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-lg-12">
                <h2 class="font-bold mb-0" v-if="brandedDrug">
                  {{ $t('admin.drugs.new.title', { name: brandedDrug.name }) }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <drug-form
            :drug="drug"
            :persist="onPersist"
            @change="onChange"
            v-if="drug"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import DrugForm from './form';

export default {
  name: 'NewDrug',
  components: { DrugForm },
  props: {
    brandedDrugId: { type: String, default: null },
  },
  data() {
    return { drug: null, brandedDrug: null };
  },
  async mounted() {
    this.drug = this.services.drug.build();
  },
  methods: {
    ...mapActions(['createDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.drug = merge(this.drug, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.createDrug({
        brandedDrugId: this.brandedDrugId,
        drug: this.drug,
      }).then(drug => {
        this.$router.push({
          name: 'editDrug',
          params: { brandedDrugId: this.brandedDrug.id, drugId: drug.id },
        });
      });
    },
  },
  watch: {
    brandedDrugId: {
      immediate: true,
      async handler() {
        this.brandedDrug = await this.services.brandedDrug.find(
          this.brandedDrugId
        );
      },
    },
  },
};
</script>
