<template>
  <div class="px-3">
    <a
      @click="$emit('reset')"
      href="#"
      class="text-gray-700 font-weight-normal text-underline d-inline-block"
    >
      {{ $t('inbox.room.prescriptionBoard.dosagePicker.rootBreadCrumb') }}
    </a>
    <span class="mx-3">&gt;</span>
    <a
      @click="$emit('reset', { brandedDrug })"
      href="#"
      class="text-gray-700 font-weight-normal text-underline d-inline-block"
      v-if="dosage"
    >
      {{ brandedDrug.name }}
    </a>
    <span v-else>
      {{ brandedDrug.name }}
    </span>
    <span v-if="dosage" class="mx-3">&gt;</span>
    <span v-if="dosage && drug">{{
      `${drug.shortLabel} ${dosage.shortLabel}` | truncate(40)
    }}</span>
    <span v-if="dosage && !drug">{{ dosage.name | truncate(40) }}</span>
  </div>
</template>

<script>
export default {
  name: 'DosagePickerBreadcrumbs',
  props: {
    prescriptionItem: { type: Object, required: true },
  },
  computed: {
    brandedDrug() {
      return this.prescriptionItem.brandedDrug;
    },
    drug() {
      return this.prescriptionItem.drug;
    },
    dosage() {
      return this.prescriptionItem.dosage;
    },
  },
};
</script>
