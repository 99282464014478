import { getDB, nowOnServer } from '@/shared/db';
import { track as trackAgent } from './agent-tracking';

export const SYSTEM_ADMINISTRATORS = 'SystemAdministrators';
export const BETA_TESTERS = 'BetaTesters';
export const CONVERSATIONS_ASSIGNEES = 'ConversationsAssignees';
export const MEDICAL_REPORT_ASSIGNERS = 'MedicalReportAssigners';
export const MEDICAL_REPORT_ASSIGNEES = 'MedicalReportAssignees';
export const PRESCRIPTION_INITIATORS = 'PrescriptionInitiators';
export const PRESCRIPTION_REFERRERS = 'PrescriptionReferrers';
export const RD_LETTER_ADVANCED_MODE_AGENTS = 'RDLetterAdvancedModeAgents';

export const GROUP_NAMES = {
  SYSTEM_ADMINISTRATORS,
  BETA_TESTERS,
  CONVERSATIONS_ASSIGNEES,
  MEDICAL_REPORT_ASSIGNERS,
  MEDICAL_REPORT_ASSIGNEES,
  PRESCRIPTION_INITIATORS,
  PRESCRIPTION_REFERRERS,
  RD_LETTER_ADVANCED_MODE_AGENTS,
};

export const findAll = withTracking => {
  if (withTracking) trackAgent('VIEW', 'AGENT-GROUPS');
  return getDB()
    .collection('agent-groups')
    .orderBy('position', 'asc');
};

export const findAllByAgentId = agentId => {
  return getDB()
    .collection('agent-groups')
    .where('agentIds', 'array-contains', agentId)
    .get()
    .then(snapshots => {
      var docs = [];
      snapshots.forEach(doc => docs.push({ id: doc.id, ...doc.data() }));
      return docs;
    });
};

export const find = id => {
  return getDB()
    .collection('agent-groups')
    .doc(id);
};

export const findByName = name => {
  return getDB()
    .collection('agent-groups')
    .where('name', '==', name);
};

export const create = group => {
  trackAgent('CREATE', 'AGENT-GROUP', group.name);
  return getDB()
    .collection('agent-groups')
    .add({ ...group, createdAt: nowOnServer() });
};

export const update = group => {
  trackAgent('UPDATE', 'AGENT-GROUP', group.id);
  const agentGroupDoc = getDB()
    .collection('agent-groups')
    .doc(group.id);
  return agentGroupDoc.update({ ...group, updatedAt: nowOnServer() });
};

export const updatePositions = groups => {
  trackAgent('SORT', 'AGENT-GROUPS');
  var batch = getDB().batch();

  groups.forEach((group, index) => {
    const doc = getDB()
      .collection('agent-groups')
      .doc(group.id);
    batch.update(doc, { position: index });
  });

  // update all the groups at once with a single call
  return batch.commit();
};

export const destroy = group => {
  trackAgent('DESTROY', 'AGENT-GROUP', group.id);
  return getDB()
    .collection('agent-groups')
    .doc(group.id)
    .delete();
};
