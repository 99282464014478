<template>
  <div
    class="tab-pane fade h-100 show active"
    id="tab-content-dialogs"
    role="tabpanel"
    v-if="room"
  >
    <div class="d-flex flex-column h-100">
      <div class="hide-scrollbar h-100">
        <div class="sidebar-header container-fluid">
          <h2 class="font-bold">
            <font-awesome-icon :icon="inboxIcon" class="mr-5" />
            {{ inbox.label }}
          </h2>
        </div>

        <div class="container-fluid px-0 pb-12">
          <nav class="nav d-block list-discussions-js">
            <div class="text-reset nav-link p-0 room router-link-active">
              <RoomItem :room="room" :active="true" :isLast="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomItem from './list/list-item';
import { findInbox } from '@/shared/inboxes';
import { setRoom } from '@/router/before_actions';

export default {
  name: 'SingleRoomList',
  components: { RoomItem },
  props: {
    inboxName: {
      type: String,
      default: undefined,
    },
    room: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    inboxIcon() {
      return this.inbox.icon;
    },
    inbox() {
      return findInbox(this.inboxName);
    },
  },
  beforeRouteUpdate(to, from, next) {
    setRoom(to, from, next);
  },
};
</script>
