<template>
  <dropdown :icon="['far', 'ellipsis-v']" size="md" menuAlign="right">
    <ul class="list-group list-group-flush">
      <!-- Prescription ID -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">
              {{ $t('inbox.room.info.prescriptionId') }}
            </h6>
            <strong :class="cssText">{{ prescription.id }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="prescription.id"
              v-clipboard:success="onPrescriptionIdCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: prescriptionIdCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>

      <!-- Room URL -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.roomURL') }}</h6>
            <strong :class="cssText">{{ roomURL }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="roomURL"
              v-clipboard:success="onRoomURLCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: roomURLCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>

      <!-- Room ID -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.roomId') }}</h6>
            <strong :class="cssText">{{ prescription.roomId }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="prescription.roomId"
              v-clipboard:success="onRoomIdCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: roomIdCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>

      <!-- User ID -->
      <li class="list-group-item py-3">
        <div class="media align-items-center">
          <div class="media-body">
            <h6 class="text-muted">{{ $t('inbox.room.info.userId') }}</h6>
            <strong :class="cssText">{{ prescription.userId }}</strong>
          </div>
          <div class="align-self-center ml-5">
            <button
              type="button"
              class="btn btn-link p-0"
              :class="cssText"
              v-clipboard:copy="prescription.userId"
              v-clipboard:success="onUserIdCopy"
              v-clipboard:error="onError"
              v-tooltip="{
                content: $t('inbox.room.info.copied'),
                trigger: 'manual',
                show: userIdCopied,
              }"
            >
              <font-awesome-icon :icon="['fal', 'copy']" />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </dropdown>
</template>

<script>
export default {
  name: 'PrescriptionDetailInfo',
  props: ['prescription'],
  data() {
    return {
      prescriptionIdCopied: false,
      roomIdCopied: false,
      roomURLCopied: false,
      userIdCopied: false,
    };
  },
  computed: {
    roomURL() {
      const resolvedPath = this.$router.resolve({
        name: 'shortcutRoom',
        params: { id: this.prescription.roomId },
      });
      return `${window.location.origin}${resolvedPath.route.path}`;
    },
  },
  methods: {
    onPrescriptionIdCopy() {
      this.prescriptionIdCopied = true;
      setTimeout(() => {
        this.prescriptionIdCopied = false;
      }, 1000);
    },
    onRoomURLCopy() {
      this.roomURLCopied = true;
      setTimeout(() => {
        this.roomURLCopied = false;
      }, 1000);
    },
    onRoomIdCopy() {
      this.roomIdCopied = true;
      setTimeout(() => {
        this.roomIdCopied = false;
      }, 1000);
    },
    onUserIdCopy() {
      this.userIdCopied = true;
      setTimeout(() => {
        this.userIdCopied = false;
      }, 1000);
    },
    onError() {
      alert('Error copying id to the clipboard');
    },
  },
};
</script>
