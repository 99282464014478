import Vue from 'vue';
import firebase from 'firebase/app';
import { buildAgent, getDB, nowOnServer } from '@/shared/db';
import { startOfDay, nowInUTC, isBlank } from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';
import { changeAgent } from './medical-report';

export const PER_PAGE = 20;

const paginate = (query, excludeIds) => {
  return query.get().then(querySnapshot => {
    var rooms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    rooms = filter(rooms, excludeIds);
    return [rooms.slice(0, PER_PAGE), rooms.length > PER_PAGE];
  });
};

export const create = (user, agent) => {
  trackAgent('CREATE', 'ROOM', user.id);
  const agentWithId = buildAgent(agent);
  const agentId = agentWithId ? agentWithId.id : null;
  return getDB()
    .collection('rooms')
    .add({
      user,
      agent: agent ? { ...agentWithId, assignmentAccepted: true } : null,
      kid: null,
      lastMessage: null,
      isDeleted: false,
      status: {
        type: 'open',
        agent: agentWithId,
        updatedAt: nowOnServer(),
      },
      assignedBy: agentWithId,
      createdBy: agentId,
      createdAt: nowOnServer(),
    });
};

export const destroy = room => {
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  return roomDoc.update({
    isDeleted: true,
    deletedAt: nowOnServer(),
  });
};

export const findAll = (inboxName, status, agent) => {
  var collection = getDB().collection('rooms');
  const orderByField =
    status === 'closed' ? 'status.updatedAt' : 'lastMessage.createdAt';
  switch (inboxName) {
    case 'you':
      return collection
        .where('status.type', '==', status)
        .where('agent.id', '==', agent.id)
        .orderBy(orderByField, 'desc');
    case 'unassigned':
      return collection
        .where('status.type', '==', status)
        .where('agent', '==', null)
        .orderBy(orderByField, 'asc');
    case 'assigned': {
      let query = collection
        .where('status.type', '==', status)
        .orderBy(orderByField, 'desc');
      // if requesting closed rooms, also return the unassigned rooms (meaning: don't order by the agent.id)
      return status === 'open' ? query.orderBy('agent.id', 'asc') : query;
    }
    case 'all':
      return collection
        .where('status.type', '==', status)
        .orderBy(orderByField, 'desc');
    case 'snoozed':
      return collection
        .where('status.type', '==', 'snoozed')
        .where(
          'status.snoozedUntil',
          status === 'awake' ? '<' : '>',
          nowInUTC()
        )
        .orderBy('status.snoozedUntil', 'asc');
    case 'favorites':
      return collection
        .where('markedByAgentsAsFavorite', 'array-contains', agent.id)
        .orderBy(orderByField, 'desc');
    default:
      return collection
        .where('status.type', '==', status)
        .orderBy(orderByField, 'desc')
        .orderBy('agent.id', 'asc');
  }
};

export const findAllByDate = (inboxName, status, agent, date) => {
  var query = findAll(inboxName, status, agent);

  if (date) {
    return query
      .startAt(date)
      .endAt(startOfDay(date))
      .limit((PER_PAGE + 1) * 2);
  } else {
    return query;
  }
};

// TODO: too many arguments, use an Object instead?
export const loadAllByDate = (inboxName, status, agent, date, excludeIds) => {
  return findAllByDate(inboxName, status, agent, date)
    .get()
    .then(querySnapshot => {
      var rooms = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      rooms = filter(rooms, excludeIds);
      return [rooms.slice(0, PER_PAGE), rooms.length > PER_PAGE];
    });
};

export const findAllByUser = (userId, status) => {
  return getDB()
    .collection('rooms')
    .where('user.id', '==', userId)
    .where('status.type', '==', status)
    .orderBy('lastMessage.createdAt', 'desc');
};

export const findAllByUserByDate = (userId, status, date) => {
  var query = findAllByUser(userId, status);
  return query.startAt(date).limit((PER_PAGE + 1) * 2);
};

export const loadAllByUserByDate = (userId, status, date, excludeIds) => {
  return paginate(findAllByUserByDate(userId, status, date), excludeIds);
};

export const findAllByAgent = (agentId, onlyOpen) => {
  const types = onlyOpen ? ['open'] : ['closed'];
  const orderByField = onlyOpen ? 'lastMessage.createdAt' : 'status.updatedAt';
  return getDB()
    .collection('rooms')
    .where('agent.id', '==', agentId)
    .where('status.type', 'in', types)
    .orderBy(orderByField, 'desc');
};

export const findAllByAgentByDate = (agentId, onlyOpen, date) => {
  var query = findAllByAgent(agentId, onlyOpen);
  return query
    .startAt(date)
    .endAt(startOfDay(date))
    .limit((PER_PAGE + 1) * 2);
};

export const findAllFavorites = agent => {
  return getDB()
    .collection('rooms')
    .where('markedByAgentsAsFavorite', 'array-contains', agent.id)
    .orderBy('lastMessage.createdAt', 'desc');
};

export const loadAllByAgentByDate = (agentId, onlyOpen, date, excludeIds) => {
  trackAgent('VIEW_ROOMS', 'AGENT', agentId);
  return paginate(findAllByAgentByDate(agentId, onlyOpen, date), excludeIds);
};

export const find = (id, withTracking) => {
  if (withTracking) trackAgent('VIEW', 'ROOM', id);
  return getDB()
    .collection('rooms')
    .doc(id);
};

export const filter = (rooms, excludeIds) => {
  if (rooms === undefined || rooms === null) return [];

  excludeIds = excludeIds || [];

  return rooms.filter(room => {
    return (
      excludeIds.indexOf(room.id) === -1 && Vue.policy('room', room, 'show')
    );
  });
};

export const load = id => {
  return find(id)
    .get()
    .then(document => {
      const room = document.data();
      return room === undefined || !Vue.policy('room', room, 'show')
        ? null
        : Object.assign({ id }, room);
    });
};

export const acceptAssignment = room => {
  trackAgent('ACCEPT-ASSIGNMENT', 'ROOM', room.id);
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  const agent = { ...room.agent, assignmentAccepted: true };
  return roomDoc.update({ agent });
};

export const refuseAssignment = room => {
  trackAgent('REFUSE-ASSIGNMENT', 'ROOM', room.id);
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  return roomDoc.update({ agent: null });
};

export const reOpen = (room, agent) => {
  trackAgent('RE-OPEN', 'ROOM', room.id);
  const agentWithId = buildAgent(agent);
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  return roomDoc.update({
    status: {
      type: 'open',
      agent: agentWithId,
      updatedAt: nowOnServer(),
    },
    assignedBy: agentWithId,
    agent: { assignmentAccepted: true, ...agentWithId },
  });
};

export const close = (room, agent) => {
  trackAgent('CLOSE', 'ROOM', room.id);
  return updateStatus(room, 'closed', agent);
};

export const snooze = (room, agent, snoozedUntil) => {
  trackAgent('SNOOZE', 'ROOM', room.id, { snoozedUntil });
  return find(room.id, false).update({
    status: {
      type: 'snoozed',
      agent: buildAgent(agent),
      updatedAt: nowOnServer(),
      snoozedUntil,
    },
    agent: null,
  });
};

export const updateStatus = (room, newStatus, agent) => {
  return find(room.id, false).update({
    status: {
      ...room.status,
      type: newStatus,
      agent: buildAgent(agent),
      updatedAt: nowOnServer(),
    },
  });
};

export const markAsRead = (room, agent) => {
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  roomDoc
    .update({
      haveAgentsRead: firebase.firestore.FieldValue.arrayUnion(agent.id),
    })
    .then(() => {});
};

export const updateRdLetterPresence = (room, hasLetter) => {
  const actionName = hasLetter ? 'ADD_RD_LETTER' : 'REMOVE_RD_LETTER';
  console.log(room, hasLetter, actionName);
  trackAgent(actionName, 'ROOM', room.id);
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);
  roomDoc.update({ hasLetter });
};

export const assignAgent = (room, agent, doneBy) => {
  trackAgent('ASSIGN-AGENT', 'ROOM', room.id);
  const roomDoc = getDB()
    .collection('rooms')
    .doc(room.id);

  if (agent) {
    const _agent = buildAgent(agent);

    // if I assign the room to myself, I won't need to accept the assignment
    _agent.assignmentAccepted = agent?.id === doneBy.id;

    let newAttributes = { agent: _agent, assignedBy: buildAgent(doneBy) };

    // special case: if the room was snoozed, then we need to un-snooze it by opening it.
    if (room.status.type === 'snoozed') newAttributes['status.type'] = 'open';

    roomDoc.update(newAttributes).then(() => {
      changeAgent(room.id, agent).catch(() => {
        // the reason is that the medical report doesn't exist, so do not raise anything
      });
    });
  } else {
    roomDoc.update({ agent: null, assignedBy: buildAgent(doneBy) });
  }
};

/**
 * Update room's status to "agent is typing".
 *
 * @param {string} roomId
 * @return {Promise<void>}
 */
export const agentStartsTyping = roomId => {
  return getDB()
    .collection('rooms')
    .doc(roomId)
    .update({
      isAgentTyping: true,
    });
};

/**
 * Remove room's status "agent is typing".
 *
 * @param {string} roomId
 * @return {Promise<void>}
 */
export const agentEndsTyping = roomId => {
  return getDB()
    .collection('rooms')
    .doc(roomId)
    .update({
      isAgentTyping: false,
    });
};

export const toggleMarkedAsFavorite = (room, agent) => {
  let operation;
  if (
    isBlank(room.markedByAgentsAsFavorite) ||
    room.markedByAgentsAsFavorite.indexOf(agent.id) === -1
  ) {
    trackAgent('MARK-AS-FAVORITE', 'ROOM', room.id);
    operation = firebase.firestore.FieldValue.arrayUnion;
  } else {
    trackAgent('UNMARK-AS-FAVORITE', 'ROOM', room.id);
    operation = firebase.firestore.FieldValue.arrayRemove;
  }
  return find(room.id, false).update({
    markedByAgentsAsFavorite: operation(agent.id),
  });
};
