<template>
  <div>
    <v-date-picker
      v-model="date"
      :locale="uiLocale"
      :popover="{ placement: 'bottom', visibility: 'click' }"
      :max-date="new Date()"
    >
      <button
        class="btn btn-outline-secondary btn-md font-medium"
        :class="cssText"
      >
        {{ formattedDate }}
      </button>
    </v-date-picker>
  </div>
</template>

<script>
import { formatDate, endOfDay } from '@/shared/utils';

export default {
  name: 'Datepicker',
  props: {
    value: {
      type: Date,
      default: null,
    },
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(date) {
        this.$emit('input', endOfDay(date));
      },
    },
    formattedDate() {
      return formatDate(this.date);
    },
  },
};
</script>
