<template>
  <div class="card mb-6">
    <div class="card-header">
      <h5>{{ $t('myProfile.security.title') }}</h5>
    </div>
    <div class="card-body">
      <div class="alert alert-warning mb-6" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div>

      <form autocomplete="off" @submit="submitForm">
        <div class="form-group">
          <label for="newPassword">{{
            $t('myProfile.security.labels.newPassword')
          }}</label>
          <input
            class="form-control"
            type="password"
            v-model="newPassword"
            id="newPassword"
            autocomplete="new-password"
          />
        </div>

        <div class="form-group">
          <label for="newPasswordConfirmation">{{
            $t('myProfile.security.labels.newPasswordConfirmation')
          }}</label>
          <input
            class="form-control"
            type="password"
            v-model="newPasswordConfirmation"
            id="newPasswordConfirmation"
            autocomplete="new-password"
          />
        </div>

        <div class="mt-8">
          <submit-button
            :labels="buttonLabels"
            :buttonState="buttonState"
            miscButtonClass="btn-block"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentNewPasswordForm',
  data() {
    return {
      newPassword: undefined,
      newPasswordConfirmation: undefined,
      buttonState: undefined,
      errorMessage: undefined,
    };
  },
  computed: {
    buttonLabels() {
      return this.$i18n.t('myProfile.security.submit');
    },
  },
  methods: {
    submitForm(event) {
      event.preventDefault() & event.stopPropagation();

      const minCharacters = 5;

      if (
        this.newPassword?.length > 0 ||
        this.newPasswordConfirmation?.length > 0
      ) {
        if (this.newPassword.length < minCharacters) {
          this.errorMessage = this.$i18n.t(
            'myProfile.security.errors.tooShort',
            { n: minCharacters }
          );
        } else if (this.newPassword !== this.newPasswordConfirmation) {
          this.errorMessage = this.$i18n.t(
            'myProfile.security.errors.notMatching'
          );
        } else {
          this.errorMessage = undefined;
          this.buttonState = 'inProgress';
          this.services.myProfile
            .updatePassword(this.newPassword)
            .then(() => this.onSuccess())
            .catch(() => (this.buttonState = 'error'));
        }
      } else {
        this.errorMessage = this.$i18n.t('myProfile.security.errors.invalid');
      }
    },
    onSuccess() {
      this.newPassword = this.newPasswordConfirmation = undefined;
      this.buttonState = 'success';
    },
  },
};
</script>
