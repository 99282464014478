<template>
  <div
    :class="{
      avatar: true,
      'text-white':
        color !== 'secondary' && color !== 'light' && color !== 'dark',
      ['bg-' + backgroundColor]: true,
      ['avatar-' + size]: true,
      'avatar-offline': online === false,
      'avatar-online': online === true,
    }"
  >
    <span
      :class="{ 'text-white': color === 'dark' }"
      v-if="!isBiloba && !isSystem && !hasPicture"
    >
      {{ getInitials }}
    </span>

    <img class="avatar-img" :src="picture" v-if="!isBiloba && hasPicture" />

    <img class="avatar-img" :src="bilobaAvatarUrl" v-if="isBiloba" />

    <div
      class="w-100 h-100 d-flex align-items-center justify-content-center text-white"
      v-if="isSystem"
    >
      <font-awesome-icon
        :icon="['fal', 'info-circle']"
        class="text-dark"
        style="font-size: 1.3rem"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    user: { ype: Object, default: undefined },
    color: { type: String, default: 'primary' },
    size: { type: String, default: 'md' },
    online: { type: Boolean, default: undefined },
    onlyFirstName: { type: Boolean, default: false },
  },
  computed: {
    getInitials() {
      if (
        this.user?.firstName === undefined ||
        this.user.firstName.length === 0
      )
        return '??';

      const firstNameLetter = this.user.firstName.charAt(0).toUpperCase();
      const lastNameLetter = (
        this.user.lastName?.charAt(0) || ''
      ).toUpperCase();

      return this.onlyFirstName
        ? this.user.firstName.charAt(0)
        : `${firstNameLetter}${lastNameLetter}`;
    },
    hasPicture() {
      return (
        this.picture !== undefined &&
        this.picture !== null &&
        this.picture !== ''
      );
    },
    picture() {
      return this.user?.profilePic;
    },
    isBiloba() {
      return this.user?.type === 'biloba' || this.user?.type === 'desk';
    },
    isSystem() {
      return this.user?.type === 'system';
    },
    backgroundColor() {
      if (this.isSystem) return 'secondary';
      else if (this.isBiloba) return null;
      else return this.color;
    },
  },
};
</script>
