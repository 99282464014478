<template>
  <div>
    <div class="tabs">
      <ul class="nav nav-tabs" :class="navClass">
        <li
          v-for="(tab, index) in tabs"
          class="nav-item"
          :class="{ active: tab.isActive }"
          v-bind:key="tab.name"
        >
          <a
            :href="tab.href"
            @click.prevent="selectTab(tab, index)"
            class="nav-link text-capitalize"
            :class="{ active: tab.isActive }"
          >
            {{ tab.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    navClass: {
      type: String,
      default: null,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    nolink: Boolean,
  },
  data() {
    return {
      tabs: [],
      activeIndex: 0,
    };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab, index) {
      if (!this.nolink) {
        this.$router
          .push({ hash: selectedTab.href.replace('#', '') })
          .catch(() => {});
      }

      this.activeIndex = index;
      this.onSelect(selectedTab, index);
      this.tabs.forEach(tab => {
        tab.isActive = tab.name === selectedTab.name;
      });
    },
  },
  watch: {
    tabs: {
      immediate: false,
      handler(newTabs) {
        if (newTabs && this.activeIndex >= newTabs.length - 1) {
          this.selectTab(this.tabs[0], 0);
        }
      },
    },
  },
};
</script>
