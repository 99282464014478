import i18n from '@/plugins/i18n.js';

const list = () => [
  {
    label: i18n.t('inbox.labels.you'),
    name: 'you',
    icon: ['fad', 'user-cowboy'],
    hasClosedPicker: true,
  },
  {
    label: i18n.t('inbox.labels.unassigned'),
    name: 'unassigned',
    icon: ['fad', 'user-cog'],
    hasClosedPicker: false,
  },
  {
    label: i18n.t('inbox.labels.assigned'),
    name: 'assigned',
    icon: ['fad', 'user-tag'],
    hasClosedPicker: true,
  },
  {
    label: i18n.t('inbox.labels.snoozed'),
    name: 'snoozed',
    path: {
      name: 'inbox',
      params: { inboxName: 'snoozed', statusId: 'awake' },
    },
    icon: ['fad', 'user-clock'],
    hasClosedPicker: false,
    betaTesterOnly: false,
  },
  {
    label: i18n.t('inbox.labels.favorites'),
    name: 'favorites',
    path: {
      name: 'inbox',
      params: { inboxName: 'favorites', statusId: 'all' },
    },
    icon: ['fad', 'thumbtack'],
    hasClosedPicker: false,
    betaTesterOnly: false,
  },
];

export const listInboxes = () => {
  return list();
};

export const findInbox = name => {
  return list().find(inbox => inbox.name === name);
};
