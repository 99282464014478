<template>
  <div class="py-6">
    <div class="container-fluid mb-6">
      <model-form
        :model="molecule"
        i18nScope="admin.molecules.form"
        :validate="services.molecule.isValid"
        :on-submit="persist"
        v-on="$listeners"
      >
        <text-input attributeName="name" />

        <div class="row">
          <div class="col">
            <text-input attributeName="shortLabel" />
          </div>
          <div class="col">
            <text-input attributeName="mobileLabel" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <textarea-input attributeName="details" />
          </div>
        </div>
      </model-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoleculeForm',
  props: {
    molecule: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
