<template>
  <div>
    <div class="alert alert-warning mt-6 mx-6" role="alert" v-if="invalidForm">
      {{ $t('admin.agentGroups.form.invalid') }}
    </div>

    <div class="py-6">
      <div class="container-fluid mb-6">
        <form v-on:submit.prevent="onSubmit">
          <div class="form-group">
            <label for="agent-group-name">{{
              $t('admin.agentGroups.form.name')
            }}</label>
            <input
              type="text"
              class="form-control"
              id="agent-group-name"
              v-model="nameInput"
            />
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="isSortable">{{
                $t('admin.agentGroups.form.sortable')
              }}</label>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isSortable"
                  v-model="sortableInput"
                />
                <label class="custom-control-label" for="isSortable"></label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="agent-group-agents">{{
              $t('admin.agentGroups.form.agents')
            }}</label>
            <agents-input
              :agentIds="agentGroup.agentIds"
              :sortable="agentGroup.sortable"
              @add="addAgent"
              @remove="removeAgent"
              @sort="changeAgentIds"
            />
          </div>

          <div class="text-right pt-6">
            <submit-button
              :labels="
                $t(
                  isNew
                    ? 'admin.agentGroups.form.submit.create'
                    : 'admin.agentGroups.form.submit.update'
                )
              "
              :buttonState="buttonState"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AgentsInput from './agents-input';

export default {
  name: 'AgentGroupForm',
  components: { AgentsInput },
  props: {
    agentGroup: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  data() {
    return {
      invalidForm: false,
      buttonState: 'default',
      agents: [],
    };
  },
  computed: {
    isNew() {
      return !this.agentGroup.id;
    },
    nameInput: {
      get() {
        return this.agentGroup.name;
      },
      set(value) {
        this.$emit('change', { name: value });
      },
    },
    sortableInput: {
      get() {
        return this.agentGroup.sortable;
      },
      set(value) {
        this.$emit('change', { sortable: value });
      },
    },
  },
  methods: {
    addAgent(agentId) {
      const newAgentIds = [...this.agentGroup.agentIds, agentId];
      this.$emit('change', { agentIds: newAgentIds });
    },
    removeAgent(agentId) {
      const newAgentIds = this.agentGroup.agentIds.filter(id => id !== agentId);
      this.$emit('change', { agentIds: newAgentIds });
    },
    changeAgentIds(agentIds) {
      this.$emit('change', { agentIds });
    },
    onSubmit() {
      if (this.agentGroup.name) {
        this.buttonState = 'inProgress';
        this.invalidForm = false;
        this.persist()
          .then(() => (this.buttonState = 'success'))
          .catch(() => (this.buttonState = 'fail'));
      } else {
        this.invalidForm = true;
      }
    },
  },
};
</script>
