import i18n from '@/plugins/i18n';
import {
  isBlank,
  pick,
  parseAPITimestamp,
  toAPIDate,
  parseURLDate,
} from '@/shared/utils';
import * as agentService from './agent';
import * as API from '@/shared/api';
import { track as trackAgent } from './agent-tracking';

export const getDurations = locale => {
  const b = (scope, i) => {
    const label = i18n.tc(`durations.${scope}`, i + 1, locale);
    return { label, value: i18n.t('duration', locale, { duration: label }) };
  };

  const days = [...Array(14).keys()].map(i => b('days', i));
  const weeks = [...Array(5).keys()].map(i => b('weeks', i));
  const months = [...Array(6).keys()].map(i => b('months', i));

  return { days, weeks, months };
};

export const getPackaging = locale => {
  const b = (scope, i) => {
    const label = i18n.tc(`packaging.${scope}`, i + 1, locale);
    return { label, value: label };
  };

  const shots = [...Array(1).keys()].map(i => b('shots', i));
  const boxes = [...Array(3).keys()].map(i => b('boxes', i));
  const vials = [...Array(3).keys()].map(i => b('vials', i));
  const bottles = [...Array(4).keys()].map(i => b('bottles', i));
  const tubes = [...Array(4).keys()].map(i => b('tubes', i));

  return { shots, boxes, vials, bottles, tubes };
};

export const parseFilterParams = async query => {
  const { startDate, endDate, doctorId, agentId } = query;
  let filter = {
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
    endDate: new Date(),
    doctor: null,
    agent: null,
  };

  if (startDate && endDate) {
    filter.startDate = parseURLDate(startDate);
    filter.endDate = parseURLDate(endDate);
  }
  if (!isBlank(doctorId)) {
    filter.doctor = await agentService.load(doctorId);
  }
  if (!isBlank(agentId)) {
    filter.agent = await agentService.load(agentId);
  }
  return filter;
};

export const toFilterParams = filter => {
  let query = {
    startDate: toAPIDate(filter.startDate),
    endDate: toAPIDate(filter.endDate),
  };

  if (filter.doctor) query.doctorId = filter.doctor.id;
  if (filter.agent) query.agentId = filter.agent.id;

  return query;
};

export const buildPrescriptedDrugFromBoard = ({
  duration,
  replaceable,
  ...item
}) => {
  let prescriptedDrug = { duration, replaceable };
  if (item.dosage.id) {
    return { dosageId: item.dosage.id, ...prescriptedDrug };
  } else {
    return {
      brandedDrugId: item.brandedDrug.id,
      drug: item.drug.name,
      dosage: item.dosage.name,
      ...prescriptedDrug,
    };
  }
};

export const buildFromBoard = (sourceBoard, type) => {
  let board = { ...sourceBoard };
  const items = board[`${type}Items`];

  // an empty kidLastName means that the room.kid.lastName isn't empty
  if (isBlank(board.kidLastName)) {
    delete board.kidLastName;
  }

  // the API expects an array of lines
  if (isBlank(board.instructions)) {
    delete board.instructions;
  } else {
    board.instructions = board.instructions.split('\n');
  }

  return {
    type: type.toUpperCase(),
    ...pick(
      board,
      'agentId',
      'doctorId',
      'userId',
      'kidId',
      'kidLastName',
      'instructions'
    ),
    kidWeightInKg: parseFloat(board.kidWeight.replace(',', '.')),
    prescriptedDrugs: items.map(item => buildPrescriptedDrugFromBoard(item)),
  };
};

export const buildDefaultBoard = (agent, room) => {
  return {
    agentId: agent.id,
    doctorId: null,
    roomId: room.id,
    kidId: room.kid?.id,
    userId: room.user?.id,
    kidWeight: null,
    kidLastName: '',
    message: '',
    treatmentItems: [],
    prescriptions: [],
  };
};

export const buildBoard = async (agent, room, supervisorAgentId) => {
  let board = null;

  // is there a draft going on?
  try {
    const response = await API.findPrescriptionDraft(room.id);
    board = response.prescriptionDraft[0]; // a draft contains an array of boards
    board.prescriptions = []; // compatibility with the Desk v2
  } catch (error) {
    console.log('[Desk] no prescription board has been found', error);
  }

  if (!board) board = buildDefaultBoard(agent, room);

  // functional rules if the board has no assigned doctor and the current agent is a doctor:
  // - with a ADELI and RPPS numbers, she doesn't need to pick another referring doctor
  // - without an ADELI/RPPS numbers, she's got the supervisor agent as the referring doctor by default
  if (isBlank(board.doctorId) && agentService.isDoctor(agent)) {
    board.doctorId = agentService.isRegisteredDoctor(agent)
      ? agent.id
      : supervisorAgentId;
  }

  return board;
};

export const buildExistingBoard = prescriptions => {
  const {
    kidWeightInKg,
    doctorId,
    status,
    instructions,
    createdAt,
    pdfFileInBase64,
  } = prescriptions[0];

  const treatmentItems = prescriptions
    .filter(item => item.type === 'TREATMENT')
    .map(item => item.prescriptedDrugs)
    .flat();

  return {
    status,
    kidWeight: kidWeightInKg,
    doctorId,
    sentAt: parseAPITimestamp(createdAt),
    treatmentItems,
    prescriptions,
    instructions: (instructions || []).join('\n'),
    previewUrl: `data:application/pdf;base64,${pdfFileInBase64}`,
  };
};

export const isBoardValid = (board, room) => {
  return (
    !isBlank(board.kidWeight) &&
    parseFloat(board.kidWeight.replace(',', '.')) > 0 &&
    (!isBlank(room.kid?.lastName) || !isBlank(board.kidLastName)) &&
    board.treatmentItems.length > 0
  );
};

export const find = id => {
  trackAgent('VIEW', 'PRESCRIPTION', id);
  return API.findPrescription(id).then(({ prescription }) => ({
    previewUrl: `data:application/pdf;base64,${prescription.pdfFileInBase64}`,
    ...prescription,
  }));
};

export const findAll = filter => {
  trackAgent('VIEW', 'PRESCRIPTIONS');
  const query = {
    startDate: toAPIDate(filter.startDate),
    endDate: toAPIDate(filter.endDate),
    doctorId: filter.doctor?.id || null,
    agentId: filter.agent?.id || null,
  };

  return API.findAllPrescriptions(query).then(({ prescriptions }) =>
    prescriptions.map(prescription => ({
      ...prescription,
      previewUrl: `data:application/pdf;base64,${prescription.pdfFileInBase64}`,
      kidWeightInKg: prescription.kidWeightInKg.toString().replace('.', ','),
    }))
  );
};

export const findAllByRoomId = async roomId => {
  return API.findPrescriptions(roomId).then(
    ({ prescriptions }) => prescriptions
  );
};

export const bulkCreate = async (roomId, list) => {
  return API.createBulkPrescriptions(roomId, list);
};

export const bulkCancel = async roomId => {
  console.log('API Prescriptions: cancel prescriptions for ', roomId);
  return true;
};

// NOTE: board is an object grouping all the prescription items (treatments and others).
export const persistBoard = board => {
  trackAgent('CREATE', 'PRESCRIPTION', board.roomId);
  // we only support treatments
  return bulkCreate(board.roomId, [buildFromBoard(board, 'treatment')]);
};

export const persistBoardDraft = async board => {
  return API.persistPrescriptionDraft(board.roomId, [board]);
};

export const getBoardPreview = async board => {
  // we only support treatments
  return API.getPrescriptionPreviewUrl(board.roomId, [
    buildFromBoard(board, 'treatment'),
  ]).then(({ previews }) => previews[0]);
};
