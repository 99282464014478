<template>
  <step-layout :title="$t(`${i18nScope}.title`)" :kid="kid" v-on="$listeners">
    <search-input @search="search" class="mt-0 mb-2 px-3" />

    <loader
      :isLoading="!allBrandedDrugs"
      style="min-height: 18rem"
      class="mt-6"
    >
      <step-list
        :title="$t(`${i18nScope}.brandedDrugs`)"
        :list="brandedDrugs"
        class="px-3"
        @select="brandedDrug => $emit('change', { brandedDrug })"
        v-slot="{ item: brandedDrug }"
        v-if="allBrandedDrugs"
      >
        {{ brandedDrug.shortLabel }}
      </step-list>
    </loader>
  </step-layout>
</template>

<script>
import StepLayout from './step-layout';
import StepList from './list';

export default {
  name: 'DosagePickerStep1',
  components: { StepLayout, StepList },
  props: {
    kid: { type: Object, required: true, default: null },
  },
  data() {
    return { query: null, allBrandedDrugs: null };
  },
  async mounted() {
    this.allBrandedDrugs = await this.services.brandedDrug.findAll();
  },
  computed: {
    i18nScope() {
      return 'inbox.room.prescriptionBoard.dosagePicker.step1';
    },
    filteredBrandedDrugs() {
      return this.services.brandedDrug.filter(this.allBrandedDrugs, {
        kid: this.kid,
        agentGroupIds: this.currentAgentGroupIds,
      });
    },
    brandedDrugs() {
      return this.services.brandedDrug.search(
        this.filteredBrandedDrugs,
        this.query
      );
    },
    noBrandedDrugsFound() {
      return this.brandedDrugs.length === 0;
    },
  },
  methods: {
    search(query) {
      this.query = query;
    },
  },
};
</script>
