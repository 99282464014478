<template>
  <div class="main main-visible">
    <div class="d-flex h-100 w-100">
      <div class="d-flex flex-column flex-grow-1">
        <div class="sidebar-header chat-header border-bottom">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12 col-lg-12">
                <h2 class="font-bold mb-0">
                  {{ $t('admin.molecules.edit.title') }}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-2 overflow-auto">
          <molecule-form
            :key="molecule.id"
            :molecule="molecule"
            :persist="onPersist"
            @change="onChange"
            v-if="molecule"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import merge from 'deepmerge';
import MoleculeForm from './form';

export default {
  name: 'EditMolecule',
  components: { MoleculeForm },
  props: ['moleculeId'],
  data() {
    return {
      molecule: null,
    };
  },
  created() {
    this.$watch(
      vm => (vm.molecules, vm.moleculeId, Date.now()),
      () => this.fetchMolecule(),
      { immediate: true }
    );
  },
  computed: {
    ...mapState(['molecules']),
  },
  methods: {
    ...mapActions(['updateMolecule']),
    onChange(changes) {
      this.molecule = merge.all([
        this.molecule,
        changes,
        { id: this.moleculeId },
      ]);
    },
    onPersist() {
      return this.updateMolecule(this.molecule);
    },
    fetchMolecule() {
      this.molecule = this.services.molecule.find(
        this.molecules,
        this.moleculeId
      );
    },
  },
};
</script>
